import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CultureService } from '../_services/culture.service';

@Component({
  selector: 'app-culture-picker',
  templateUrl: './culture-picker.component.html',
  styleUrl: './culture-picker.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CulturePickerComponent {
  public picking: boolean = false;
  constructor(public cultureService: CultureService) { }

  public UpdateCulture(newCulture: string): void {
    this.picking = false;
    this.cultureService.SetPreferredCulture(newCulture);
  }
}
