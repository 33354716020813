/* src/app/footer-prompt/footer-prompt.component.scss */
div#footer-prompt {
  position: fixed;
  bottom: 43px;
  width: 100%;
  height: 5em;
  height: 7em;
  padding: 0 5px;
  margin: -1em 0 0 0;
  background-color: #4b95cd;
  border: 1px solid #CCC;
  z-index: 99;
  display: block;
}
div#footer-prompt.HideFooterPrompt,
.testData,
.varbtns {
  display: none;
}
div#footer-prompt p {
  margin: 0;
  padding: 0.5em 1em;
  text-align: center;
  line-height: 3;
  color: #ffffff;
}
img.icon_reference {
  width: 36px;
  height: 36px;
  margin-right: 2px;
}
.changevar svg {
  max-width: 24px;
  margin-right: 4px;
  background: #fff;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
div#footer-prompt #close {
  position: absolute;
  top: 0;
  color: #323940;
  border-radius: 7px;
  right: 2px;
  font-weight: bold;
  display: block;
  padding: 0 8px 8px 8px;
  margin-right: 4px;
  margin-top: 4px;
}
.test.varbtns {
  background: red;
}
.testData {
  color: #ffff33;
}
div#footer-prompt #close svg {
  fill: #ffffff;
}
div#footer-prompt #close:hover {
  background: #000;
  cursor: pointer;
}
div#footer-prompt p .btn-info {
  background: #f5b101;
  border: 1px solid #fff;
  color: #000000;
  margin-left: 4px;
  margin-right: 4px;
}
div#footer-prompt p .btn-info:hover {
  background: #dbdbdb;
  border: 1px solid #000;
}
@media only screen and (min-width: 600px) {
  div#footer-prompt {
    height: 76px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 600px) {
  div#footer-prompt {
    height: 76px;
  }
}
@media only screen and (max-width: 600px) {
  div#footer-prompt {
    bottom: 0px;
  }
}
@media only screen and (max-width: 370px) {
  div#footer-prompt p {
    line-height: 2.8;
    padding: 6px 12px;
  }
  div#footer-prompt {
    padding: 0 12px;
  }
}
/*# sourceMappingURL=/footer-prompt.component.css.map */
