export class ExamTimeEvent {
    public get ObjectName(): string { return 'ExamTimeEvent'; }
    private _eventType: ExamTimeEvent.EventType;
    private _time: Date;
    private _extraData: any;

    private constructor() {
    }

    public static New(eventType: ExamTimeEvent.EventType, extraData?: any): ExamTimeEvent {
        let obj = new ExamTimeEvent();
        obj._eventType = eventType;
        obj._time = new Date();
        if(extraData) {
            obj._extraData = extraData;
        }
        return obj;
    }

    public static FromFirestore(fsSource: any): ExamTimeEvent {
        let obj = new ExamTimeEvent();
        obj._eventType = fsSource.Type;
        obj._time = fsSource.Time.toDate()
        if(fsSource.ExtraData) {
            obj._extraData = fsSource.ExtraData;
        }
        return obj;
    }


    public get EventType(): ExamTimeEvent.EventType {
        return this._eventType;
    }

    public get EventTime(): Date {
        return this._time;
    }

    public get ExtraData(): any {
        return this._extraData;
    }

    public get Ticks(): number {
        return this.EventTime.getTime() / 1000;
    }

    public get FirestoreTimestamp(): any {
        throw new Error('Method not implemented.');
    }
}

export namespace ExamTimeEvent {
    export enum EventType {
        Start = "START",
        End = "END",
        Pause = "PAUSE",
        Resume = "RESUME",
        Timeout = "TIMEOUT",
        Hidden = "HIDDEN",
        Shown = "SHOWN",
        NavigateAway = "NAVIGATEAWAY",
        NavigateBack = "NAVIGATEBACK",
        Answered = "ANSWERED"
    }
}