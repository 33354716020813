/* src/app/breadcrumb/breadcrumb.component.scss */
#breadcrumb-holder {
  position: absolute;
  left: calc(232px + 0.7rem);
  top: 2.5rem;
  width: 100%;
  height: 1.75rem;
  line-height: 1.75rem;
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(255, 255, 255)), to(rgba(255, 255, 255, 0)));
}
.breadcrumb-img {
  height: 100%;
  width: auto;
  margin-left: 0.5rem;
  vertical-align: top;
}
.breadcrumb {
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0;
  font-size: 0.9em;
  margin: 0;
}
a,
span {
  display: inline-block;
  color: white;
}
a:hover,
span:hover {
  color: dimgrey;
  text-decoration: none;
}
.disable {
  color: white !important;
  text-decoration: none;
  cursor: default;
}
a .disable:hover {
  color: white;
}
li {
  height: 1.75rem;
  line-height: 1.75rem;
  list-style: none;
  float: left;
  background: #ff8b00;
  padding: 0 1.4rem 0 0.7rem;
  background-image: url("/media/crumb-li.png");
  background-repeat: no-repeat;
  background-position: 100%;
}
li:last-of-type {
  background-image: url("/media/crumb-li-last.png");
}
@media (min-width: 768px) and (max-width: 992px) {
  #breadcrumb-holder {
    width: 75%;
  }
}
@media (max-width: 767px) {
  #breadcrumb-holder {
    left: 0.9rem;
    margin-bottom: 0.5rem;
  }
  li {
    display: none;
  }
  li:nth-last-child(2) {
    display: block;
    background-image: url("/media/crumb-li-left.png");
    background-position: 0%;
    padding: 0 0.7rem 0 1.4rem;
  }
  li:nth-last-child(2) a {
    text-decoration: underline;
  }
}
/*# sourceMappingURL=/breadcrumb.component.css.map */
