import { Injectable, inject } from "@angular/core";
import { CultureString } from "./culture-string";
import { ECTopic } from "./ec-topic";

@Injectable({providedIn: 'root'})
export class ECSubject {

  private id: number;
  private alias: string;
  private name: CultureString;
  private order: number;
  private topics: Map<number, ECTopic> = new Map<number, ECTopic>();

  constructor(source: any) {

    this.id = source.id;
    this.alias = source.alias;
    this.name = new CultureString(source.name);
    this.order = source.order;
  }

  public AddTopic(topic: ECTopic): void {
    this.topics.set(topic.ID, topic);
  }

  public get ID(): number { return this.id; }
  public get Alias(): string { return this.alias; }
  public get Name(): CultureString { return this.name; }
  public get Order(): number { return this.order; }
  public get Topics(): Map<number, ECTopic> { return this.topics; }
  public get OrderedTopics(): ECTopic[] { return [...this.topics.values()].sort((a, b) => {
    if (a.Order > b.Order) return 1;
    if (a.Order < b.Order) return -1;
    return 0;
  }); }
}
