import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Exam } from '../_classes/exam';
import { UserDataService } from '../_services/user-data.service';

@Component({
  selector: 'app-exam-button',
  templateUrl: './exam-button.component.html',
  styleUrls: ['./exam-button.component.scss']
})
export class ExamButtonComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  @Input() start: boolean;
  @Input() exam: Exam;
  @Input() number: number;
  public ExamStates = Exam.ExamState;
  public StartingExam: boolean = false;

  constructor(public userData: UserDataService, private router: Router) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    setTimeout(() => {
      for (let e = 0; e < this._subscriptions.length; e++) {
        this._subscriptions[e].unsubscribe();
      }
    }, 2000);
  }

  StartExam() {
    if (this.StartingExam) { return; }
    this.StartingExam = true;
    this._subscriptions.push(this.userData.StartExam().pipe(take(1)).subscribe(exam => {
      this.router.navigateByUrl('exams/' + this.number);
    }));
  }

  ResumeExam() {
    this.userData.ResumeExam();
    this.router.navigateByUrl('exams/' + this.number);
  }

}
