/* src/app/faq/faq.component.scss */
a {
  color: #00a6b7;
}
.bold {
  font-weight: 600;
}
.active {
  display: flex;
}
.none {
  display: none;
}
#topic1 {
  margin-bottom: 2rem;
}
li {
  margin-left: 3rem;
  cursor: pointer;
  color: #00a6b7;
}
.slider {
  background: ghostwhite;
  border: 1px solid grey;
  margin-bottom: 0.5rem;
  width: 100%;
  margin-left: 5rem;
}
.col-1 {
  background: #8accbf;
}
@media screen and (max-width: 800px) {
  #faq-container {
    margin-top: 4rem;
    margin-left: 1px;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 5px;
  }
  li {
    margin-left: 5px;
  }
  .slider {
    margin-left: 0.5px;
  }
  .d-flex {
    width: 100%;
  }
  h3 {
    font-weight: 600;
  }
}
/*# sourceMappingURL=/faq.component.css.map */
