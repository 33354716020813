<app-subject-topic-header head="Getting Started"></app-subject-topic-header>

<app-video-player
  [options]="{ controls: true, fill: true, fluid: false, sources: [{ src: '//d1nird3e6iyxf9.cloudfront.net/exam-coach-how-to.mp4', type: 'video/mp4' }]}"
  analyticsid="gettingstarted"></app-video-player>

<h4 class="text-center mt-2">Congratulations on making ABMP Exam Coach your study resource!</h4>
<br>
<p>Here you will find the tools you need to prepare for your licensing exam. You can also use this program as a study
  guide when preparing for quizzes and exams during school. Don't forget to<a [routerLink]="['/info/homescreen']"> pin
    this
    app to your device's homescreen</a>. Let's help you get started</p>
<ol>
  <li>First, pick a Subject you would like to study from the green "Study Subjects" link on the ABMP Exam Coach home
    page.
  </li>
  <li>Choose a Topic within your chosen subject of study. There are 10 study areas covering the core body of knowledge
    you are learning in your massage or bodywork training program. These subjects also comprise the content covered on
    the MBLEx and other licensing exams for massage therapists and bodyworkers. </li>
  <li>Next, choose from among three study methods: terminology, flash cards, or practice quizzes. </li>
  <li>Terminology: In the terminology section, you can click on a term for a definition and also click on the "hear
    pronunciation" links to listen to proper pronunciation. You can also look at the entire Index of terminology. A
    search function at the top right of each page lets you find a term anywhere it's found in ABMP Exam Coach. </li>
  <li>Flash Cards: Read a question or term on the front of a flash card and then click the card to have the answer or
    term definition revealed. Click the “next card” button to move on to the next card when you are ready. When you
    master a card, you can remove it from the deck by clicking on the green check mark. Return cards to your deck in the
    "My Progress" section. ABMP Exam Coach shuffles the flash card deck each time you return to a topic to ensure you
    stay challenged and engaged.
  </li>
  <li>Practice Quiz: Each practice quiz is 10 questions in length. If you choose an incorrect answer, a red X will
    appear on your selection and you’ll have a chance to try again. A green check indicates you’ve chosen the correct
    answer and you can move on. Each time you start a quiz for a particular topic, it randomly selects a combination of
    quiz questions to ensure you stay challenged and engaged. Pass 10 quizzes (a 70% or higher score), and four
    additional practice exams are added to your queue. </li>
  <li>When you earn a passing score for three quizzes in a row for each topic in a subject area, you will know you
    understand the knowledge components for that particular subject. Ideally, you’ll want to complete quizzes (and
    receive a passing score) in all 74 topic areas included in ABMP Exam Coach before you take the practice exams. After
    you complete each quiz, you will see a summary report showing your score along with the correct answers for any
    questions you answered incorrectly. </li>
  <li>Practice Licensing Exams: When you near graduation and are ready to prepare for the MBLEx, use the unlimited
    practice exams to get ready. Once you start a practice exam, you have 110 minutes to complete 100 questions. By
    clicking the “pause” button at the top of the screen, you can pause the exam at any time and come back to it later.
    After completing your practice exam, you’ll see a summary report showing your scores, the answers you chose, and the
    correct answer for any you may have missed, organized by subject/topic. To continue study in weaker subject areas,
    click the related topic link. You want to achieve a passing score on several practice exams before you schedule your
    MBLEx examination. If you start to get low on practice exams, add four more at any time by passing 10 quizzes in any
    topic! </li>
  <li>Click the "My Progress" bubble on the home page to see your recent activity and for an ongoing log of your study
    sessions in ABMP Exam Coach. </li>
</ol>
<p>We hope you enjoy using ABMP Exam Coach. When you pass the MBLEx, contact ABMP to become a Professional member.
  Because you have the ABMP Exam Coach subscription, you pay only $75 for your first year of professional membership and
  liability insurance. (Did you know you can add the<a
    href="https://www.abmp.com/members/students/upgrade-exam-coach-plus">ABMP Five-Minute Muscles</a> subscription and
  your first year of professional membership becomes free?)
  Contact ABMP at 1-800-458-2267 or <a href="https://www.abmp.com"></a>


</p>
