<div class="questionrow mb-3 p-2">
  <p>
    {{question.Question}}
  </p>

  <div class="d-flex">
    <ng-container *ngIf="question.Answer == question.SelectedAnswer">
      <span class="sr-only">Answered Correctly</span>
      <fa-icon icon="check-circle" class="green me-2"></fa-icon>
    </ng-container>
    <ng-container *ngIf="question.Answer != question.SelectedAnswer">
      <span class="sr-only">Answered Incorrectly</span>
      <fa-icon icon="times-circle" class="maroon mt-2 me-2"></fa-icon>
    </ng-container>

    <div>
      <strong class="font-1rem me-2 {{question.Answer == question.SelectedAnswer ? 'mt-2' : ''}}">Your
        First Answer:</strong>{{question.SelectedAnswer}}
      <sup *ngIf="question.SelectedAnswer=='Incorrect'">
        <fa-icon icon="question" class="maroon"
          title="Questions from the legacy ExamCoach product didn't save your selected answer"></fa-icon>
      </sup>
      <ng-container *ngIf="question.Answer != question.SelectedAnswer">
        <br />
        <strong class="me-2 font-1rem">Correct Answer:</strong><em>{{question.Answer}}</em>
      </ng-container>
    </div>
  </div>
  <a *ngIf="showSource" [routerLink]="['/subjects', question.Subject,question.Topic]" class="d-block text-center mb-2">
    Found in {{question.SubjectTopicName}}
  </a>
</div>
