<ng-container *transloco="let t">
<div class="d-flex">
    <div>
        <h1 *ngIf="subject">{{ t('Header.subject') }} <span>{{subject.Name}}</span></h1>
        <h1 *ngIf="head">{{head}}</h1>
        <h2 *ngIf="topic">{{ t('Header.topic') }} <span>{{topic.Name}}</span></h2>
        <h2 *ngIf="subhead">{{subhead}}</h2>
    </div>
    <h3 *ngIf="contentarea">{{contentarea}}</h3>
</div>
<strong *ngIf="text">{{text}}</strong>
</ng-container>