import { CultureString } from "./culture-string";
import { ECSubject } from "./ec-subject";
import { ECTopic } from "./ec-topic";

export class FlashCard {
    public get ObjectName(): string { return 'FlashCard'; }
    public ID: number;
    public FirestoreID: string;
    public Front: CultureString;
    public Back: CultureString;
    public Image: string;
    public Thumbnail: string;
    public Subject: string;
    public Topic: string;
    public SubjectTopicName: string;

    public constructor(fsSource: any, topic: ECTopic) {
        this.ID = fsSource.id;
        this.FirestoreID = fsSource.firestoreId;
        this.Front = new CultureString(fsSource.front);
        this.Back = new CultureString(fsSource.back);

        this.Subject = topic.Subject.Alias;
        this.Topic = topic.Alias;

        this.Image = fsSource.Image;
        this.Thumbnail = this.Image?.replace('.png', '-sm.png');

        if (fsSource.SubjectTopicName) {
            this.SubjectTopicName = fsSource.SubjectTopicName;
        }
    }
}
