/* src/app/quiz-review/quiz-review.component.scss */
:host {
  display: block;
  margin-bottom: 3rem;
}
.show {
  display: inline-flex;
}
.hide {
  display: none;
}
.top-width {
  margin-top: 2%;
  width: 20% !important;
}
#loading {
  width: 50%;
}
#interstitial-modal {
  position: fixed;
  top: 47%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 150;
}
#interstitial-modal .card {
  position: absolute;
  top: 5rem;
  left: 35%;
  width: 29%;
  -ms-flex-direction: column;
  flex-direction: column;
}
#waiting {
  margin: auto;
  margin-bottom: 10px;
  width: 10%;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  display: block;
  align-content: center;
  font-family: "Arvo", serif;
  text-align: center;
}
.card-header {
  line-height: 3.5em;
  text-align: center;
  color: white;
  font-family: "Arvo", serif;
  font-size: 1em;
  background: #3c83bf;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  text-emphasis: bolder;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header-2 {
  text-align: center;
  font-family: "Arvo", serif;
}
.card-footer {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Arvo", serif;
  padding: 0.75rem 1.25rem;
  background: #3c83bf;
}
#bottom-message {
  text-align: center;
  font-weight: 700;
  margin-top: 5%;
  padding-top: 4%;
  padding-left: 10%;
  padding-right: 10%;
}
@media only screen and (max-width: 700px) {
  #interstitial-modal {
    position: fixed;
    width: 100%;
    min-width: 100%;
    min-height: 111%;
  }
  .card-header-2 {
    margin-top: none !important;
  }
  #bottom-message {
    margin-top: none;
  }
  .card {
    position: absolute;
    left: 0.5rem !important;
    vertical-align: center;
    width: 95% !important;
    height: 71%;
    padding: none;
  }
  #waiting {
    height: 10% !important;
    width: 15%;
    margin-top: none;
  }
}
@media only screen and (min-width: 700px) and (max-width: 800px) {
  #interstitial-modal {
    top: 57%;
  }
  .card {
    width: 33%;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .card-header {
    font-size: 1em;
  }
  .card {
    width: 33%;
  }
}
.ecsharefb {
  visibility: hidden;
}
/*# sourceMappingURL=/quiz-review.component.css.map */
