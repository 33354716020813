/* src/app/flashcards/flashcards.component.scss */
#card-wrapper {
  max-width: 45rem;
  text-align: center;
}
#flashcard {
  height: 20rem;
  border: solid 1px #aa0c00;
  perspective: 10000px;
}
#remove {
  position: absolute;
  top: 0;
  right: 1rem;
  z-index: 100;
  opacity: 0.3;
  color: #78c700;
  font-size: 2rem;
}
#remove:hover {
  opacity: 1;
}
#fullimage {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 15.5rem;
  margin-left: 0.5rem;
  z-index: 100;
  color: #00a6b7;
}
#flashcard-inner {
  position: relative;
  width: calc(100% - 1px);
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}
#flashcard h2 {
  color: #aa0c00;
}
#card-front,
#card-back {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
#flashcard.flipped #flashcard-inner {
  transform: rotateY(180deg);
}
#card-back {
  transform: rotateY(180deg);
}
.card-content {
  height: calc(100% - 3rem);
  overflow-y: auto;
}
#card-reminder {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: solid 1px #aa0c00;
  font-size: 0.9rem;
}
#card-reminder strong {
  color: #aa0c00;
}
div.card-image {
  max-width: 36%;
  float: left;
  padding-right: 0.5rem;
  cursor: pointer;
}
div.card-image.card-image-sm {
  display: none;
}
div.card-image img {
  width: 100%;
}
div.card-image a {
  display: block;
  text-align: center;
  color: #00a6b7;
}
br.img-break {
  display: none;
}
div#full-image {
  position: absolute;
  top: 1vh;
  bottom: 1vh;
  left: 1vw;
  right: 1vw;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  z-index: 1000;
}
div#full-image img {
  max-height: 90%;
  max-width: 95%;
  margin-top: 1vh;
  margin-left: 1vh;
}
div#full-image a {
  display: block;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  color: #FFF;
}
a#answer {
  display: none;
}
@media screen and (max-width: 768px) {
  div#card-wrapper header {
    font-size: 0.8rem;
  }
  #flashcard {
    height: 25rem;
  }
  div.card-image.card-image-sm {
    display: inline;
  }
  div.card-image.card-image-lg {
    display: none;
  }
  div.card-image {
    float: none;
    max-width: 90%;
    margin: 0 auto;
  }
  div#full-image img {
    padding-top: 25vh;
  }
  br.img-break {
    display: block;
    clear: both;
  }
}
@media screen and (max-height: 768px) {
  #flashcard {
    height: 20rem;
  }
}
@media screen and (max-height: 665px) {
  #flashcard {
    height: 16rem;
  }
}
/*# sourceMappingURL=/flashcards.component.css.map */
