<ng-container *ngIf="(ActiveExam$ | async) as ActiveExam">
    <div *ngIf="ActiveExam">
        <a [routerLink]="['exams', ActiveNumber]" class="me-2">Active Exam</a>
        <ng-container *ngIf="RemainingTime">
            <br class="d-md-none" />
            <span class="d-none d-lg-inline">- {{RemainingTime}} remaining. </span>
            <span class="d-lg-none">({{RemainingTime}}) </span>
        </ng-container>
        <br class="d-md-none" />
        <a [routerLink]="null" (click)="PauseExam()" class="d-none d-lg-inline">Pause this exam</a>
        <a [routerLink]="null" (click)="PauseExam()" class="d-lg-none">Pause</a>
    </div>
</ng-container>