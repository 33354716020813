<ng-container *ngIf="(ProgressData$ | async) as usage; else loadingUsage">
  <ng-container *ngIf="(userData.QuizStats$ | async) as QuizStats; else loadingUsage">
    <div class="container">
      <div *ngIf="missingUsage" class="row">
        <div class="col text-center mt-5">
          <h5 class="maroon">We ran into a problem loading your usage data. Please reload the page.</h5>
          <a class="btn btn-info" (click)="Reload();">Reload now</a>
        </div>
      </div>
      <ng-container *ngIf="!missingUsage">
      <ng-container *ngFor="let subject of usage.Subjects">
        <div class="row pt-2">
          <div class="col">
            <h4 class="maroon"><a [routerLink]="['/subjects', subject.Alias]">{{subject.Name}}</a></h4>
          </div>
        </div>
        <ng-container *ngFor="let topic of subject.AnnotatedTopics; let i=index">
          <div class="topic row pt-2 {{i%2==0 ? 'shaded' : ''}}">
            <div class="col-12 col-md-6 mt-md-2">
              <h5 class="teal ms-md-2"><a [routerLink]="['/subjects', subject.Alias, topic.Alias]">{{topic.Name}}</a>
              </h5>
            </div>
            <div class="col-12 col-md-3 mt-md-2">
              <ng-container *ngIf="topic.LastUsage; else notReviewed">
                Reviewed {{topic.LastUsage | date:'mediumDate'}}
              </ng-container>
            </div>
            <div class="col-12 col-md-3">
              {{QuizStats.TopicCounts(subject.Alias, topic.Alias).Taken}} quiz{{QuizStats.TopicCounts(subject.Alias, topic.Alias).Taken == 1 ? '' : 'zes'}} taken<br />
              {{QuizStats.TopicCounts(subject.Alias, topic.Alias).Passed}} quiz{{QuizStats.TopicCounts(subject.Alias, topic.Alias).Passed == 1 ? '' : 'zes'}} passed
            </div>
          </div>
          <div *ngIf="topic.HiddenCardCount + topic.ExhaustedQuestionCount > 0" class="restore row ps-1 ps-md-4 py-1 {{i%2==0 ? 'shaded' : ''}}">
            <div class="col-12 col-md">
              <a *ngIf="topic.HiddenCardCount > 0" [routerLink]="null" class="d-block d-md-inline ms-md-2"
                (click)="RestoreCards(subject.Alias, topic.Alias)">Flash cards: Bring back {{topic.HiddenCardCount}}</a>
            </div>
            <div class="col-12 col-md">
              <a *ngIf="topic.ExhaustedQuestionCount > 0" [routerLink]="null" class="d-block d-md-inline ms-md-2"
                (click)="RestoreQuestions(subject.Alias, topic.Alias)">Questions: Bring back {{topic.ExhaustedQuestionCount}}</a>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingUsage>
  <app-loading description="load your activity"></app-loading>
</ng-template>
<ng-template #notReviewed><span class="subtle">Not yet reviewed</span></ng-template>