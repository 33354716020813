/* src/app/question-review/question-review.component.scss */
div.questionrow {
  border-top-left-radius: 0.5rem;
  border-left: solid 2px #c8c8c8;
  border-top: solid 2px #c8c8c8;
}
div.questionrow h5 {
  text-indent: -1rem;
  padding-left: 1rem;
  line-height: 1.5rem;
}
.font-1rem {
  font-size: 1rem;
}
/*# sourceMappingURL=/question-review.component.css.map */
