import {  Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { FireStoreStaticService } from '../_services/fire-store-static.service';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-subject-two',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.scss']
})
export class TopicListComponent implements OnInit {
  public MySubject$ = this.dataService.GetSubject$(this.route.snapshot.params.subject);

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  ngOnInit() {
  }
}
