export class CultureString {
  private fallbackCulture: string = 'en';
  private variants: Map<string, string> = new Map<string, string>();

  constructor(data: {culture, text}[]) {
    data.forEach(item => {
      this.variants.set(item.culture, item.text);
    });
  }

  public get Cultures(): string[] {
    return Array.from(this.variants.keys());
  }

  public Value(culture: string): string {
    if(this.variants.has(culture)) {
      return this.variants.get(culture);
    } else {
      return this.variants.get(this.fallbackCulture);
    }
  }

}
