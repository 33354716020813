/* src/app/exam-review/exam-review.component.scss */
section.subject {
  margin-bottom: 1.5rem;
  padding: 1rem 2rem 0 1rem;
  border-top: solid 1px #CCC;
  border-left: solid 1px #CCC;
  border-top-left-radius: 0.5rem;
}
section.subject header h4 fa-icon {
  display: inline-block;
  width: 1.5rem;
}
section.subject aside {
  margin-left: 2.35rem;
}
section.topic {
  margin: 0.5rem 0 1rem 2.5rem;
}
div.questioncontainer {
  margin-left: 1rem;
}
@media (min-width: 768px) {
  div#review-table {
    width: 700px;
  }
  section.subject {
    margin-left: 1px;
  }
  section.topic {
    margin: 0 0 0.8rem 1rem;
  }
  .topic-question h5 {
    font-size: 0.9rem !important;
  }
}
/*# sourceMappingURL=/exam-review.component.css.map */
