import { CultureString } from "./culture-string";
import { ECSubject } from "./ec-subject";

export class ECTopic {
  private id: number;
  private alias: string;
  private name: CultureString;
  private order: number;
  private subject: ECSubject;
  private nonMblex: boolean = false;

  constructor(source: any, subject: ECSubject) {
    this.id = source.id;
    this.alias = source.alias;
    this.name = new CultureString(source.name);
    this.order = source.order;
    this.subject = subject;
    this.nonMblex = !source.includedInMblex;
  }

  public get ID(): number { return this.id; }
  public get Alias(): string { return this.alias; }
  public get Name(): CultureString { return this.name; }  
  public get Order(): number { return this.order; }
  public get Subject(): ECSubject { return this.subject; }
  public get SubjectID(): number { return this.subject.ID; }
  public get NonMBLEX(): boolean { return this.nonMblex; }
}
