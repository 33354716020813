<app-subject-topic-header contentarea="practice quiz"></app-subject-topic-header>

<div>
  <p class="text-center">Number of questions: 10</p>
  <p>
    Once you've answered a question correctly 3 times, it will be removed
    from the rotation so you can focus on new content. To add questions
    back, visit the My Progress page.
  </p>
  <div>
    <p *ngIf="allQuestionsExhausted">You've already seen all questions in this topic 3 times. If you want to take more quizzes, <a [routerLink]="null" (click)="RestoreQuestions()">restore all questions</a>.</p>
    <a *ngIf="!allQuestionsExhausted && !generatingQuiz" [routerLink]="null" title="Start Quiz" class="nav-link" (click)="StartQuiz();"> <img
        id="start-button" src="assets/img/btn_start.png" /></a>
    <app-loading *ngIf="generatingQuiz" description="generate your quiz..."></app-loading>
  </div>
  <ng-container *ngIf="(ExamCount$ | async) as examCount">
    <p>Pass {{examCount.quizzesNeeded}} more quizzes to earn 4 new exams!</p>
  </ng-container>

</div>
