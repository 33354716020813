//Modules:
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, ErrorHandler, isDevMode } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppComponent } from './app.component';
import { AppSettings } from './_classes/app-settings';
import { HeaderComponent } from './header/header.component';
import { HomepageComponent } from './homepage/homepage.component';
import { InfoComponent } from './info/info.component';
import { MyProgressComponent } from './my-progress/my-progress.component';
import { PracticeExamsComponent } from './practice-exams/practice-exams.component';
import { SubjectListComponent } from './subject-list/subject-list.component';
import { FooterComponent } from './footer/footer.component';
import { FlashcardsComponent } from './flashcards/flashcards.component';
import { SearchComponent } from './search/search.component';
import { ProgressSubjectsComponent } from './progress-subjects/progress-subjects.component';
import { ProgressExamsComponent } from './progress-exams/progress-exams.component';
import { TopicDetailComponent } from './topic-detail/topic-detail.component';
import { TerminologyComponent } from './terminology/terminology.component';
import { QuizLandingComponent } from './quiz-landing/quiz-landing.component';
import { QuizQuestionsComponent } from './quiz-questions/quiz-questions.component';
import { TopicListComponent } from './topic-list/topic-list.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { SubjectTopicCardComponent } from './subject-topic-card/subject-topic-card.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RandomArrayElement } from './_pipes/random.pipe';
import { AuthInterceptor, ErrorInterceptor } from './_classes/interceptor';
import { GetStartedComponent } from './get-started/get-started.component';
import { FaqComponent } from './faq/faq.component';
import { SourcesComponent } from './sources/sources.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { InterstitialComponent } from './interstitial/interstitial.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSync, faHome, faVolumeUp, faChevronCircleRight, faChevronRight, faStepBackward, faCheck, faPause, faCaretDown, faCaretRight, faTimes, faSearch, faBars, faSpinner, faQuestionCircle, faCheckCircle, faTimesCircle, faCaretLeft, faQuestion, faPlay, faForward, faClock, faStop, faEye, faEyeSlash, faArrowCircleDown, faArrowCircleUp, faInfoCircle, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { ShufflePipe } from './_pipes/shuffle.pipe';
import { QuizReviewComponent } from './quiz-review/quiz-review.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { LoadingComponent } from './loading/loading.component';
import { SubjectTopicHeaderComponent } from './subject-topic-header/subject-topic-header.component';
import { ExamButtonComponent } from './exam-button/exam-button.component';
import { ExamDisplayComponent } from './exam-display/exam-display.component';
import { ExamQuestionComponent } from './exam-question/exam-question.component';
import { ExamAlertComponent } from './exam-alert/exam-alert.component';
import { QuestionReviewComponent } from './question-review/question-review.component';
import { ExamReviewComponent } from './exam-review/exam-review.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ExamTimelineComponent } from './exam-timeline/exam-timeline.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { FooterPromptComponent } from './footer-prompt/footer-prompt.component';
import { AddToHomeComponent } from './add-to-home/add-to-home.component';
import { TimeCheckComponent } from './time-check/time-check.component';
import { CulturePipe } from "./_pipes/culture.pipe";
import { CulturePickerComponent } from "./culture-picker/culture-picker.component";
import { CultureStringComponent } from './culture-string/culture-string.component';
import { TranslocoRootModule } from './transloco-root.module';

const appSettings = new AppSettings();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CulturePickerComponent,
    HomepageComponent,
    InfoComponent,
    MyProgressComponent,
    PracticeExamsComponent,
    SubjectListComponent,
    FooterComponent,
    FlashcardsComponent,
    SearchComponent,
    ProgressSubjectsComponent,
    ProgressExamsComponent,
    TopicDetailComponent,
    TerminologyComponent,
    QuizLandingComponent,
    QuizQuestionsComponent,
    TopicListComponent,
    SubjectTopicCardComponent,
    BreadcrumbComponent,
    RandomArrayElement,
    GetStartedComponent,
    FaqComponent,
    SourcesComponent,
    ContactComponent,
    LoginComponent,
    InterstitialComponent,
    ShufflePipe,
    QuizReviewComponent,
    PurchaseComponent,
    LoadingComponent,
    SubjectTopicHeaderComponent,
    ExamButtonComponent,
    ExamDisplayComponent,
    ExamQuestionComponent,
    ExamAlertComponent,
    QuestionReviewComponent,
    ExamReviewComponent,
    SearchResultsComponent,
    ExamTimelineComponent,
    VideoPlayerComponent,
    FooterPromptComponent,
    AddToHomeComponent,
    TimeCheckComponent,
  ],
  bootstrap: [AppComponent], 
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CultureStringComponent,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production && location.host == 'www.abmp.com' }),
    CulturePipe,
    TranslocoRootModule
  ],
  providers: [
    { provide: AppSettings, useValue: appSettings },
    provideHttpClient(withInterceptors([ErrorInterceptor, AuthInterceptor]))
  ]
})
export class AppModule {
  constructor(public library: FaIconLibrary) {
    let win = window as any;

    library.addIcons(faCaretLeft, faSpinner, faSync, faHome, faVolumeUp, faChevronRight, faStepBackward, faCheck, faPause, faCaretRight, faCaretDown, faTimes, faSearch, faBars, faQuestionCircle, faCheckCircle, faTimesCircle, faQuestion, faPlay, faForward, faClock, faStop, faEye, faEyeSlash, faArrowCircleDown, faArrowCircleUp, faInfoCircle, faLanguage);
  }
}
