<ng-container *transloco="let t">
<app-subject-topic-header [head]="t('Header.ContactUs')"></app-subject-topic-header>

  <ul>
    <li>Have an ABMP Exam Coach technical question? <a href="mailto:ec.tech@abmp.com">Click here</a></li>
    <li>Have a question or comment on ABMP Exam Coach content?<a href="mailto:ec.content@abmp.com"> Click here</a></li>
    <br>
    <p><strong>Want to know more about ABMP?</strong></p>
    <li>Visit us at <a href="https://www.abmp.com"> www.abmp.com </a>or call 800-458-2267.
      Ready to take advantage of the first-year ABMP membership discount that comes with your ABMP Exam Coach
      subscription?
      If you purchased ABMP Exam Coach and are now ready to practice massage for the first time as a professional, you
      pay
      only $75 (Professional) or $105 (Certified) for your first year of ABMP membership, which includes professional
      liability insurance and an array of benefits (Massage & Bodywork magazine, a free website and email, marketing and
      consumer education tools, great discounts, and much more)! It's the best price in the profession and it's ABMP's
      way
      of
      supporting you as a therapist while you take your first steps in your career. You can join online at <a
        href="https://www.abmp.com">www.abmp.com</a>,
      or
      simply call us at 800-458-2267.</li>
  </ul>
</ng-container>