import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-to-home',
  templateUrl: './add-to-home.component.html',
  styleUrls: ['./add-to-home.component.scss']
})

export class AddToHomeComponent implements OnInit {
  deviceInfo = null;

  public OsCookieValue = 'UNKNOWN';
  public deviceOS: string;

  public IOSandMobi: boolean;
  public IosMobiSafari: boolean;
  public devicebrowser: string;
  public deviceMobi: boolean;
  public isAndroid: boolean;
  public isIOS: boolean;

  public browserVersion: string;
  public browserMainVersion: string;

  public isMobi: boolean;
  public isTablet: boolean;
  public MacTablet: boolean;
  public appImgFolder = 'https://cdn.abmp.com/public/image/5mm/';

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    this.epicDeviceFunction();
   }

  ngOnInit() {


  }
  epicDeviceFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceMobi = this.deviceService.isMobile();
    this.deviceOS = this.deviceInfo.os;
    this.devicebrowser = this.deviceInfo.browser;

    this.isMobi = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.deviceOS = this.deviceInfo.os;
    this.devicebrowser = this.deviceInfo.browser;

    this.browserVersion = this.deviceInfo.browser_version;
    this.browserMainVersion = this.browserVersion.split('.')[0];

    if ( (this.deviceOS === 'Mac') && (this.isTablet === true) ) {
      this.MacTablet = true;
    }
    if ( (this.deviceOS === 'iOS') ) {
      this.isIOS = true;
      this.isAndroid = false;
    } else if ( (this.deviceOS === 'Android') ) {
      this.isAndroid = true;
      this.isIOS = false;
    } else {
      this.isAndroid = false;
      this.isIOS = false;
    }

  }


}
