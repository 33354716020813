<app-subject-topic-header contentarea="quiz review"></app-subject-topic-header>

<ng-container *ngIf="(interstitialService.Pending$ | async) === false">
  <ng-container *ngIf="(Quiz$ | async) as quiz; else loadingQuiz">
    <ng-container *ngIf="!quiz.NoQuizResultFound; else nothingToReview">
      <div class="d-flex">
        <h3><strong>Score:</strong><span class="ms-2 {{quiz.Score >= 70 ? 'green' : 'maroon'}}">{{quiz.Score}}%</span>
        </h3>
        <a routerLink=".." class="ms-5 pt-2">Take another quiz in this topic</a>
      </div>
      <ng-container *ngIf="(ExamCount$ | async) as examCount">
        <p *ngIf="examCount.quizzesNeeded == 10 && quiz.Score >= 70">You’ve unlocked 4 more practice exams!</p>
        <p *ngIf="examCount.quizzesNeeded < 10">Pass {{examCount.quizzesNeeded}} more quiz{{examCount.quizzesNeeded == 1
          ? '' : 'zes'}} to earn 4 new exams!</p>

      </ng-container>
      <p class="font-italic">Use the search feature to research topic areas for missed questions below.</p>

      <ng-container *ngFor="let question of quiz.Questions ; let i = index">
        <app-question-review [question]="question" [questionNumber]="i" [showSource]="false"></app-question-review>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<div *ngIf="activateInterstitial">
  <app-interstitial></app-interstitial>
</div>

<ng-template #loadingQuiz>
  <app-loading description="load your quiz data..."></app-loading>
</ng-template>

<ng-template #nothingToReview>
  <p>You have no completed quiz to review for this topic.</p>
</ng-template>