import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseAuthService } from '../_services/firebase-auth.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  constructor(private userService: UserService, private router: Router) {
    this.userService.User$.subscribe(user => {
      if (user.IsLoggedIn && user.HasActiveEC) {
        this.router.navigateByUrl('/');
      }
    })
  }

  ngOnInit(): void {
  }

}
