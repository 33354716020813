/* src/app/search-results/search-results.component.scss */
#display-toggle {
  width: 40%;
}
#display-toggle p {
  cursor: pointer;
}
@media screen and (max-width: 850px) {
  #display-toggle {
    flex-direction: column;
    width: 100%;
  }
}
/*# sourceMappingURL=/search-results.component.css.map */
