/* src/app/subject-topic-card/subject-topic-card.component.scss */
:host > div {
  width: 13rem;
  height: 17rem;
  margin: 0.75rem;
  padding: 0.5rem;
  border: solid 1px #dcdcdc;
  border-radius: 0.5rem;
  text-align: center;
}
:host > div:hover {
  background: #dbdbdb;
}
div.nonmblex {
  height: 20.5rem;
}
a {
  color: #000;
  text-decoration: none;
}
h6 {
  font-size: 1.15rem;
  line-height: 1.4rem;
  height: 4rem;
}
.mblexalert {
  background: red;
  border-radius: 5px;
  color: #FFF;
  font-style: italic;
  margin-top: 0.5rem;
  height: 3rem;
  text-decoration: none;
}
/*# sourceMappingURL=/subject-topic-card.component.css.map */
