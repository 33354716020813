import { Component, Input, OnInit } from '@angular/core';
import { ExamQuestion } from '../_classes/exam-question';

@Component({
  selector: 'app-question-review',
  templateUrl: './question-review.component.html',
  styleUrls: ['./question-review.component.scss']
})
export class QuestionReviewComponent implements OnInit {
  @Input() question: ExamQuestion;
  @Input() questionNumber: number;
  @Input() showSource: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
