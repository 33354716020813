import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlashCard } from '../_classes/flash-card';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ECSubject } from '../_classes/ec-subject';
import { ECTopic } from '../_classes/ec-topic';
import { UmbracoResponse } from '../_classes/umbraco-response';
import { CultureService } from './culture.service';
import { TerminologyTerm } from '../_classes/terminology-term';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public Subjects$: BehaviorSubject<Map<number, ECSubject>> = new BehaviorSubject<Map<number, ECSubject>>(new Map<number, ECSubject>());
  public OrderedSubjects$: BehaviorSubject<ECSubject[]> = new BehaviorSubject<ECSubject[]>([]);
  private _flashcards$: BehaviorSubject<FlashCard[]> = new BehaviorSubject<FlashCard[]>([]);
  private _flashcardsLoaded: boolean = false;
  private _terms$: BehaviorSubject<TerminologyTerm[]> = new BehaviorSubject<TerminologyTerm[]>([]);
  private _termsLoaded: boolean = false;
  private aliasIdMap: Map<string, number> = new Map<string, number>();
  private subTopsByID:Map<number, ECSubject | ECTopic> = new Map<number, ECSubject | ECTopic>();

  constructor(private http: HttpClient, private cultureService: CultureService) { 
    this.GetSubjects();
  }

  private GetSubjects() {
    let subjects: Map<number, ECSubject> = new Map<number, ECSubject>();
    this.http.get<UmbracoResponse>('https://umbraco-api.azurewebsites.net/api/examcoach/topics').subscribe(topics => {
      topics.value.forEach(_topic => {
        if(!subjects.has(_topic.subject.id)) {
          let subject = new ECSubject(_topic.subject);
          subjects.set(subject.ID, subject);
          this.aliasIdMap.set(subject.Alias, subject.ID);
          this.subTopsByID.set(subject.ID, subject);
        }
        let topic = new ECTopic(_topic, subjects.get(_topic.subject.id));
        this.aliasIdMap.set(`${topic.Subject.Alias}:${topic.Alias}`, topic.ID);
        this.subTopsByID.set(topic.ID, topic);
        subjects.get(topic.Subject.ID).AddTopic(topic);        
      });

      let sortedSubjects: ECSubject[] = [...subjects.values()].sort((a, b) => {
        if (a.Order > b.Order) return 1;
        if (a.Order < b.Order) return -1;
        return 0;
      });
      
      this.OrderedSubjects$.next(sortedSubjects);
      this.Subjects$.next(subjects);
    });
  }

  public GetSubject$(subjectAlias: string): Observable<ECSubject> {
    return this.Subjects$.pipe(
      map(subjectMap => {
        return subjectMap.get(this.aliasIdMap.get(subjectAlias));
      })
    );
  }

  public GetTopic$(subjectAlias: string, topicAlias: string): Observable<ECTopic> {
    return this.Subjects$.pipe(
      map(subjectMap => {
        return [...subjectMap.get(this.aliasIdMap.get(subjectAlias)).Topics.values()].find(t => t.Alias === topicAlias);
      })
    );
  }


  public GetFlashcards$(): Observable<FlashCard[]> {
    if(this._flashcardsLoaded) {
      return this._flashcards$;
    } else {
      return this.http.get<UmbracoResponse>('https://umbraco-api.azurewebsites.net/api/examcoach/flashcards').pipe(
        map((response:UmbracoResponse) => {
          let cards = response.value.map(f => {
            let topic: ECTopic = this.subTopsByID.get(f.topicId) as ECTopic;
            return new FlashCard(f, topic);
          });
          this._flashcards$.next(cards);
          this._flashcardsLoaded = true;
          return cards;
        })
    );
    }
  }

  public GetTerminology$(): Observable<TerminologyTerm[]> {
    if(this._termsLoaded) {
      return this._terms$;
    } else {
      return this.http.get<UmbracoResponse>('https://umbraco-api.azurewebsites.net/api/examcoach/terms').pipe(
        map((response:UmbracoResponse) => {
          let terms = response.value.map(f => {
            let topic: ECTopic = this.subTopsByID.get(f.topicId) as ECTopic;
            return new TerminologyTerm(f, topic);
          });
          this._terms$.next(terms);
          this._termsLoaded = true;
          return terms;
        })
    );
    }
  }

  
}
