<app-subject-topic-header head="Frequently Asked Questions"></app-subject-topic-header>

<h3>Using ABMP Exam Coach</h3>
<ul>
  <li (click)="toggleShowDiv('a')">How do I get started?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='a' ? 'in': 'out'">
    <div class="{{this.activeFaq=='a' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        From the ABMP Exam Coach home page, click the Getting Started bubble. There, you can read step-by-step
        directions
        on how to get started.

        Or, go exploring on your own. From the ABMP Exam Coach home page you can click the Study Subjects bubble to
        see
        the depth and breadth of information this test prep program offers. Each Subject is broken into various
        Topics,
        and each Topic has its relevant Terms (with definitions and audio pronunciations), Flash Cards, and unlimited
        Quizzes.

        As you near your licensing test date, you will want to ready yourself by taking ABMP Exam Coach practice
        exams.
        These tests mimic the MBLEx test with their 100 questions, comparably weighted content, and 110-minute time
        limit.
        We've created a real-world testing experience for you, so you're prepared for the real thing. Along the way,
        check
        how you're doing in the "My Progress" section.
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('b')">How many quizzes can I take in each
    subject area?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='b' ? 'in': 'out'">
    <div class="{{this.activeFaq=='b' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">

        You can view the material and take as many quizzes as you like until your ABMP Exam Coach subscription expires
        (view this information under "My Progress" at the top of your Exam Coach page). Each time you take a quiz, the
        program shuffles the test bank so that you see questions in different combinations to help you master the
        topic
        area.
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('c')">How many practice exams can I take?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='c' ? 'in': 'out'">
    <div class="{{this.activeFaq=='c' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        Your ABMP Exam Coach subscription provides unlimited practice exams that simulate the format and content you
        will see on the MBLEx. One exam is available to use at a time; however, additional exams will accrue in your
        account every time you pass ten, 10-question quizzes, at 70% or higher. As you pass quizzes, you will see your
        available practice exam count increase over time within the Practice Exam section. In addition, your
        previously
        taken exams and their scores will be visible as well. Although you’ll have plenty of practice exams to use,
        successful MBLEx test takers spend most of their time in the quizzing section in order to practice with a
        larger
        pool of test questions. You have unlimited access to terms, definitions, flash cards, and quizzes until your
        ABMP Exam Coach subscription expires (view this information under My Progress at the top of your Exam Coach
        landing page).
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('d')">How can I make changes to my registration (e.g., change my email or password)?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='d' ? 'in': 'out'">
    <div class="{{this.activeFaq=='d' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        You can update your personal details in your general ABMP.com account when you initially log in. For
        assistance,
        please email <a href="mailto:expectmore@abmp.com">expectmore&#64;abmp.com</a>.
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('e')">What are the system requirements for using ABMP Exam Coach?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='e' ? 'in': 'out'">
    <div class="{{this.activeFaq=='e' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        ABMP Exam Coach is supported by mainstream web browsers including Chrome, Safari, Firefox, and Edge. If you are having trouble viewing ABMP Exam Coach in one of these browsers, make sure you have a
        recent version of your browser installed.
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('f')">Can I use my tablet or smartphone to access ABMP Exam Coach, and can I access ABMP
    Exam Coach on more than one
    device?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='f' ? 'in': 'out'">
    <div class="{{this.activeFaq=='f' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        Yes! You can use ABMP Exam Coach on all of your mobile devices and on more than one device as long as you have
        an internet connection. Simply log in to ABMP Exam Coach with the device you are using and go. Have fun!
      </div>
    </div>
  </div>
</ul>

<h3>Exams and Licensing</h3>
<ul>
  <li (click)="toggleShowDiv('g')">My school hasn't taught me some of the content I see in ABMP Exam Coach. Do I need
    to know this content for the test?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='g' ? 'in': 'out'">
    <div class="{{this.activeFaq=='g' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        With the exception of the section on Research Literacy, all of the content you encounter in ABMP Exam Coach
        could potentially be included on the MBLEx. Research literacy is being taught with greater frequency in
        schools
        across the country, and it may show up on the MBLEx in the future. ABMP Exam Coach addresses subjects and
        topics
        comprehensively. We give you a wide range of questions and come at content from a variety of different
        directions to help you prepare.
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('h')">Why don't the subjects and topics on ABMP Exam Coach directly match the content
    outline of the MBLEx?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='h' ? 'in': 'out'">
    <div class="{{this.activeFaq=='h' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        When we developed ABMP Exam Coach, we wanted it to be useful in a variety of settings. The content outline for
        the MBLEx groups topics into very big buckets. This is useful for final testing, but it doesn't match the way
        schools tend to teach subjects and topics. ABMP Exam Coach breaks content down into bite-size chunks so you
        can
        use the program to study for subjects while you are in school. The full-length practice examinations simulate
        the format, content, and content weighting used on the MBLEx. They will help you identify areas where you need
        additional study to be ready for the MBLEx.
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('i')">What if my state doesn't use the MBLEx for licensing? Will ABMP Exam Coach prepare
    me for the exam used by my state?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='i' ? 'in': 'out'">
    <div class="{{this.activeFaq=='i' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        While there are a few states that require a different test than the MBLEx, the test content is generally
        similar
        in nature. ABMP Exam Coach covers fundamental massage and bodywork content in a comprehensive manner to
        effectively prepare you to take any licensing exam.
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('j')">What resources were used in the development of ABMP Exam Coach?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='j' ? 'in': 'out'">
    <div class="{{this.activeFaq=='j' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        ABMP Exam Coach was written by massage and bodywork educators and content experts using the same resources
        used
        to write test questions for the MBLEx and for the Entry-Level Analysis Project (ELAP). You can print a list of
        the resources here and obtain a <a href="https://www.fsmtb.org/media/1681/2018-mblex-content-outline.pdf" target="_blank">content outline for the MBLEx here</a>. The
        subjects and topics in ABMP Exam Coach
        were structured to also make the program useful to students in massage and bodywork training programs. The
        four
        practice examinations simulate the format, content, and content weighting used on the MBLEx.
      </div>
    </div>
  </div>

</ul>


<h3>ABMP Membership and Exam Coach Subscription Info</h3>
<ul>
  <li (click)="toggleShowDiv('k')">How do I get my first-year membership discount included with Exam Coach?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='k' ? 'in': 'out'">
    <div class="{{this.activeFaq=='k' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        ABMP Exam Coach subscribers have the exclusive opportunity to join ABMP as a professional at the Certified
        membership level, our best membership option! You have two options to choose from. The best option is to get
        your Certified membership for <strong>FREE </strong> by adding ABMP Five-Minute Muscles to your
        account
        while you're still in
        school, or while you're waiting to meet state requirements. The addition of ABMP Five-Minute Muscles is just
        $7/month for 12 months. Or, you can wait until you've met state requirements to work, and then activate your
        Certified Membership and insurance for just $75, or $7/month. Certified Membership is a $229 value, so your
        savings are huge, regardless of the option you choose! You can activate your Certified membership online at
        <a href="https://www.abmp.com">www.abmp.com</a>, or simply call us at 800-458-2267 and the membership
        department will answer your questions and
        activate your membership. It's that easy!
      </div>
    </div>
  </div>

  <li (click)="toggleShowDiv('l')">What is ABMP?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='l' ? 'in': 'out'">
    <div class="{{this.activeFaq=='l' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        Associated Bodywork & Massage Professionals (ABMP) is the nation's leading massage association dedicated to
        protecting you and your business. ABMP membership offers outstanding professional liability insurance coverage
        for massage therapists and bodyworkers, unparalleled customer service, an award-winning magazine <a
          href="https://www.massageandbodyworkdigital.com">(Massage &
          Bodywork)</a>, strong legislative advocacy, a continuing education library of 600 free CE hours, amazing
        member
        discounts on everything you need in your business, and an array of client education and marketing tools
        designed
        just for you!
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('m')">I want to cancel my registration. Can I get a refund?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='m' ? 'in': 'out'">
    <div class="{{this.activeFaq=='m' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        Once you purchase ABMP Exam Coach you have access to one of the largest and most comprehensive test banks in
        the
        massage and bodywork profession. To protect this exclusive test bank, we do not allow the transfer of
        registrations or refunds. If you have additional questions, please contact ABMP at 800-458-2267.
      </div>
    </div>
  </div>
</ul>


<h3>Providing Feedback</h3>
<ul>
  <li (click)="toggleShowDiv('n')">What should I do if a quiz is telling me I answered a question wrong, and I believe
    it is right?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='n' ? 'in': 'out'">
    <div class="{{this.activeFaq=='n' ? 'active' : 'none'}}">
      <div class="col-1"></div>
      <div class="col-11">
        ABMP Exam Coach was written by massage and bodywork educators and content experts using the same resources
        used
        to write test questions for the MBLEx and for the Entry-Level Analysis Project (ELAP). Every effort has been
        made to provide rigorous fact checking on ABMP Exam Coach content. However, discrepancies in textbooks do
        exist
        and there is still debate in the fields of anatomy, physiology, kinesiology, and pathology related to body
        structures, functions, and disorders. Review your textbook related to the content and <a
          href="mailto:ec.content@abmp.com">drop us an email here</a>. We
        will double-check your findings with our team of massage and bodywork experts and share what we find. We
        appreciate your inquisitive mind!
      </div>
    </div>
  </div>
  <li (click)="toggleShowDiv('o')">How can I give feedback, make a suggestion, or comment on ABMP Exam Coach?</li>
  <div class="slider" [@slideInOut]="this.activeFaq=='o' ? 'in': 'out'">
    <div c class="{{this.activeFaq=='o' ? 'active' : 'none'}}">
      <div class="col-1">
      </div>
      <div class="col-11">
        If you want to give feedback, make a suggestion, or comment on the technology used to create ABMP Exam Coach
        (things like logging in, moving around the program, the way the program works, etc.), <a
          href=" mailto:expectmore@abmp.com">please share your thoughts
          here</a>. If you want to give feedback, make a suggestion, or comment on the content included in ABMP Exam
        Coach,
        <a href="mailto:ec.content@abmp.com">please share your thoughts here</a>. We appreciate your willingness to take
        the time to let us know what you think!
      </div>
    </div>
  </div>
</ul>