import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CultureService } from '../_services/culture.service';
import { CultureString } from '../_classes/culture-string';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-culture-string',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './culture-string.component.html',
  styleUrl: './culture-string.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CultureStringComponent implements OnInit, OnChanges {
  @Input() content: CultureString;
  public Translated$: BehaviorSubject<string>;

  constructor(private cultureService: CultureService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.Translated$) return;
    this.Translated$.next((changes.content.currentValue as CultureString).Value(this.cultureService.PreferredCulture));
  }

  ngOnInit(): void {
    this.Translated$ = new BehaviorSubject<string>(this.content.Value(this.cultureService.PreferredCulture));

    this.cultureService.PreferredCulture$.subscribe(culture => {
      this.Translated$.next(this.content.Value(culture));
    });
  }
 }
