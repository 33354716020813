/* src/app/progress-subjects/progress-subjects.component.scss */
div.shaded {
  background-color: #fff1e2;
}
div.restore {
  border-top: solid 1px #fcc68b;
}
div.topic,
div.restore {
  margin-left: 1rem;
}
span.subtle {
  color: #757575;
  font-style: italic;
}
/*# sourceMappingURL=/progress-subjects.component.css.map */
