import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../_services/user-data.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FireStoreStaticService } from '../_services/fire-store-static.service';

@Component({
  selector: 'app-progress-subjects',
  templateUrl: './progress-subjects.component.html',
  styleUrls: ['./progress-subjects.component.scss']
})
export class ProgressSubjectsComponent implements OnInit {
  public ProgressData$: Observable<any>;
  public missingUsage: boolean = false;

  constructor(public fssService: FireStoreStaticService, public userData: UserDataService) {
    this.ProgressData$ = combineLatest([fssService.OrderedSubjects, this.userData.GetUsage(), userData.GetHiddenFlashCardIds('all'), userData.GetUserQuestionCount('all')]).pipe(
      map(([subjects, usage, hiddenCards, questionCounts]) => {
        console.log("ProgressSubjectsComponent:Constructor", usage);
        this.missingUsage = usage["_missing"] === true;
        
        let annotatedSubjects = [];
        subjects.forEach(subject => {
          let annotatedSubject = { ...subject } as any;
          annotatedSubject.AnnotatedTopics = [];
          subject.OrderedTopics.forEach(topic => {
            let annotatedTopic = { ...topic } as any;
            annotatedTopic.LastUsage = usage?.[subject.Alias]?.[topic.Alias]?.max || null;
            annotatedTopic.HiddenCardCount = hiddenCards?.[subject.Alias]?.[topic.Alias]?.length || 0;
            annotatedTopic.ExhaustedQuestionCount = Object.values(questionCounts?.[subject.Alias]?.[topic.Alias] || {}).filter(v => (v as number) >= 3).length || 0;
            annotatedSubject.AnnotatedTopics.push(annotatedTopic);
          });
          annotatedSubjects.push(annotatedSubject);
        })
        return { Subjects: annotatedSubjects };
      })
    );
  }

  ngOnInit() {
  }

  RestoreCards(subject: string, topic: string) {
    this.userData.UnhideFlashCards(subject, topic);
  }

  RestoreQuestions(subject: string, topic: string) {
    this.userData.RestoreQuestions(subject, topic);
  }

  Reload() {
    location.reload();
  }

}
