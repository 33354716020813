import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../_classes/app-settings';
import { catchError, map, take } from 'rxjs/operators';
import { UserDetail } from '../_classes/user-detail';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { UmbracoResponse } from '../_classes/umbraco-response';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _token: string;
  private _user: UserDetail;

  public User$: BehaviorSubject<UserDetail> = new BehaviorSubject<UserDetail>(UserDetail.Anonymous());
  public UserPreferences$: BehaviorSubject<Map<string, string>> = new BehaviorSubject<Map<string, string>>(new Map<string, string>());

  constructor(private appSettings: AppSettings, private http: HttpClient, private tokenService: TokenService) {
    if (window.localStorage) {
      let storedToken = window.localStorage.getItem('ecToken');
      let storedUserData = JSON.parse(window.localStorage.getItem('ecUser'));

      if (storedToken && storedUserData) {
        let payload = this.parseJwt(storedToken);
        let storedUser = UserDetail.Deserialize(storedUserData);
        if(payload.exp * 1000 > Date.now() && payload.pac?.cst_recno == storedUser.ID) {
          this._token = storedToken;
          this.tokenService.Token = this._token;
          this._user = storedUser;
          this.User$.next(this._user);
        }
      }
    }

    this.http.get(`${this.appSettings.nfBaseUrl}JWT?Site=ABMP`, { withCredentials: true, observe: 'response' }).pipe(
      map((response: HttpResponse<undefined>) => {
        if (response.headers.has("X-JWT")) {
          this._token = response.headers.get("X-JWT");
          this.tokenService.Token = this._token;
          this._user = UserDetail.FromNFLogin(response.body);
          this.User$.next(this._user);

          if (window.localStorage) {
            window.localStorage.setItem('ecToken', this._token);
            window.localStorage.setItem('ecUser', JSON.stringify(this._user));
          }

        } else {
          this._token = null;
          this.tokenService.Token = this._token;
          this._user = UserDetail.Anonymous();
          this.User$.next(this._user);

          if (window.localStorage) {
            window.localStorage.removeItem('ecToken');
          }
        }
      })
    ).subscribe();

    this.tokenService.Token$.subscribe(token => {
      this.FetchPreferences();
    });
  }

  private parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  public Login(username: string, password: string): Observable<UserDetail> {
    return this.http.get(`${this.appSettings.nfBaseUrl}JWT?Site=ABMP&Username=${username}&Password=${password}`, { observe: 'response' }).pipe(
      catchError(this.HandleNFLoginError),
      map((response: any) => {
        if (response.headers.has("X-JWT")) {
          this._token = response.headers.get("X-JWT");
          this._user = UserDetail.FromNFLogin(response.body);
          this.User$.next(this._user);

          if (window.localStorage) {
            window.localStorage.setItem('ecToken', this._token);
            window.localStorage.setItem('ecUser', JSON.stringify(this._user));
          }
        } else {
          this._token = null;
          this._user = UserDetail.Anonymous();
          this.User$.next(this._user);

          if (window.localStorage) {
            window.localStorage.removeItem('ecToken');
          }
        }
        return this._user;
      })
    );
  }

  public Logout(): Observable<UserDetail> {
    this._token = null;
    this._user = UserDetail.Anonymous();
    this.User$.next(this._user);

    if (window.localStorage) {
      window.localStorage.removeItem('ecToken');
    }
    return of(this._user);
  }

  public HandleNFLoginError(error: HttpErrorResponse) {
    switch (error.status) {
      case 401:
        return throwError(new NetforumLoginError("Invalid username or password"));
        break;
      case 403:
        if (error.error && error.error.message) {
          switch (error.error.message) {
            case 'LoginByEmail Failed':
              return throwError(new NetforumLoginError("Invalid username or password"));
              break;
          }
        } else {
          this.Logout().pipe(take(1)).subscribe();
        }
        return of({});
        break;
      default:
        return throwError(new NetforumLoginError("An error occurred while logging you in"));
        break;
    }
  }

  public SetPreference(name: string, value: string) {
    let data = { name: name, value: value };
    this.http.patch<UmbracoResponse>('https://umbraco-api.azurewebsites.net/api/examcoach/user/set-preferences', data).subscribe(result => {
      return value;
    });
  }

  public FetchPreferences() {
    this.http.get<UmbracoResponse>('https://umbraco-api.azurewebsites.net/api/examcoach/user/get-preferences').subscribe(response => {
      if (response.isSuccess) {
        let preferences = new Map<string, string>();
        response.value.forEach(preference => {
          preferences.set(preference.name, preference.value);
        });
        this.UserPreferences$.next(preferences);
      }
    });
  }

  public get Token(): string {
    return this._token;
  }

}

class NetforumLoginError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NetforumLoginError';
  }
}
