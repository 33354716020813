import { Component, Injectable, OnInit, OnDestroy } from '@angular/core'
import { UserAgentTrackingService } from './_services/user-agent-tracking.service';
import { UserDataService } from './_services/user-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public userData: UserDataService, private uaTracker: UserAgentTrackingService) { }
  ngOnInit() {


  }

}
