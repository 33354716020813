import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, first, last, map, take } from 'rxjs/operators';
import { Interstitial } from '../_classes/interstitial';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class FireStoreInterstitialsService {
  private _cooldown: number;
  private _areas: Map<string, boolean> = new Map();
  public Pending$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private firestore: any, private userData: UserDataService) {
    this._areas.set("quizcomplete", false);
    this._areas.set("examcomplete", true);

    this._cooldown = 20 * 60;
  }

  public GetRandomInterstitial(): Observable<Interstitial> {
    return this.firestore.collection("interstitials").get().pipe(
      map((fsInterstitials: any[]) => {
        let results: Interstitial[] = [];
        fsInterstitials.forEach(interstitialDoc => {
          results.push(new Interstitial(interstitialDoc.data()));
        });
        return results[Math.floor(Math.random() * results.length)];
      })
    )
  }

  public ShouldShow$(area: string): Observable<boolean> {
    this.Pending$.next(true);
    setTimeout(() => this.Pending$.next(false), 10000);
    if (!this._areas.has(area)) {
      this._areas.set(area, false);
    }
    return this.userData.LastInterstitialAge$().pipe(
      map(lastShownAge => {
        this.Pending$.next(false);
        return this._areas.get(area) && lastShownAge >= this._cooldown;
      })
    )
  }
}
