<div class="container muscle_display">
  <div class="row content_area">
    <div class="col-md-8">
      <div class="field-item content_area">

        <h3 class="pg_title">
          How to Install ABMP Exam Coach App
        </h3>
        <hr class="title_sub divider">

        <!-- SAFARI IOS USERS -->
        <ng-container class="ios" *ngIf="(this.isIOS===true) || (this.MacTablet===true)">

          <div class="ios Safari" *ngIf="(this.devicebrowser==='Safari')">
            <h5><strong>iOS Safari Users</strong></h5>

            <p>1. Tap the share action icon
              <img src="{{appImgFolder + 'share-ios-shortcut-btn36px.png'}}" class="icon_reference align-bottom"
                alt="share icon arrow pointing up">
              at the bottom of the screen.
            </p>
            <p>
              2. Scroll down and tap on
              <br /><strong>Add to Home Screen</strong>.
            </p>
            <p>
              <img class="screen_reference img-fluid" src="assets/img/AddToHomescreenArrow.png"
                alt="iphone menu - add site to home screen">
            </p>
            <p>
              3. Tap <strong>Add</strong>.
            <p>
              <img class="screen_reference img-fluid" src="assets/img/AddLast.png "
                alt="iphone menu - add icon to home screen">
            </p>


            <hr class="divider">

            <p>
              <img class="icon_reference_small float-left me-3 " src="assets/img/EC_icon.png">
            </p>
            <p>
              4. The icon will now be on your Home Screen, and you can open ABMP Exam Coach by clicking on the icon.
              <em>(You may be prompted to log in again, the first time you open it using the icon).</em>
            </p>

            <hr class="divider">

            <p><strong>Still Not Working?</strong></p>

            <p>
              1. Check the footer for a Safari Icon near the bottom right of your screen.
              This means you're viewing the page from inside another app, instead of the Safari browser.
            </p>
            <p>
              <img class=" img-fluid float-none clearfix"
                src="{{appImgFolder + 'footer-embedded-gmail-app-ios2.jpg'}} ">
            </p>
            <p>
              2. Tap that icon to open ABMP Exam Coach in Safari, then follow the instructions above.
            </p>
            <p class="bottom_spacer"></p>

          </div>
          <!-- end iOS Safari -->

          <!-- IOS NOT-SAFARI           -->
          <div class="ios NOTsafari" *ngIf="(this.devicebrowser!=='Safari')">
            <h5><strong>iOS Users</strong></h5>
            <!-- ios Not Safari should just load Safari -->
            <p><strong>
                Please use Safari to enable Home Screen App Feature on iOS.
              </strong></p>
          </div>
          <!-- END IF IOS but NOT Safari -->

        </ng-container>
        <!-- END IF is IOS -->

        <!-- CHROME ANDROID -->
        <ng-container class="MobileOrTablet" *ngIf="(this.isMobi===true) || (this.isTablet===true)">

          <ng-container class="androidUsers" *ngIf="(this.isAndroid===true)">
            <!-- <h1><strong class="text-warning">Android !!! </strong></h1> -->

            <div class="Chrome Android" *ngIf="(this.devicebrowser==='Chrome')">

              <h5><strong>Android Chrome Users</strong></h5>

              <div class="Chrome OLD" *ngIf="(this.browserMainVersion<'73')">
                <p><strong>
                    Please update Chrome to enable Home Screen App Feature.
                  </strong></p>
              </div> <!-- END (browserMainVersion<'73') -->

              <!-- ANDROID CHROME -->

              <div class="Chrome modern" *ngIf="(this.browserMainVersion>'72')">

                <p>
                  1. Tap the menu icon to open the menu
                  <img alt="android menu" class="img-fluid" width="32" height="32"
                    src="{{appImgFolder + 'android--menu-40px.png'}}" /> and tap <strong>Install App</strong>.
                </p>
                <p>
                  2. When prompted to install the app, tap <strong>Install</strong>.
                </p>
                <p>
                  <img alt="" class="img-fluid" alt="Install app" src="assets/img/homescreenandroidchromeStep2.jpg" />
                </p>
                <hr class="divider">
                <p>

                  <img class="icon_reference_small float-left me-3 " src="assets/img/EC_icon.png" alt="icon for EC">
                </p>
                <p>
                  3. The icon will now be on your Home Screen, and you can open ABMP Exam Coach by clicking on the
                  icon.

                  <em>
                    (You may be prompted to log in again, the first time you open it using the icon).
                  </em>
                </p>

                <!-- Embedded Android Browser view
                  from within GMail App -->
                <hr class="divider clearfix">

                <p class="bottom_spacer"></p>
              </div> <!-- END (browserMainVersion>'72') -->
            </div><!-- END Chrome Android Chrome -->

            <!--  ANDROID FireFox Condition -->
            <div class="Firefox Android" *ngIf="(devicebrowser==='Firefox')">

              <h5><strong>Android FireFox Users</strong></h5>
              <div class="Firefox OLD" *ngIf="(browserMainVersion<'58')">
                <p><strong>
                    Please update FireFox to enable Home Screen App Feature.
                  </strong></p>
              </div> <!-- END (browserMainVersion>'58') -->


              <div class="Firefox modern" *ngIf="(browserMainVersion>'57')">
                <p>
                  Tap on the three dots at the bottom right of your screen, then tap on "Install."
                </p>
                <p>
                  <img class="img-fluid" alt="FireFox menu add to home screen icon step 1"
                    src="assets/img/homescreenandroidfirefox1.jpg" />
                </p>
                <p>
                  Tap on "Add."
                </p>
                <p>
                  <img class="img-fluid" alt="FireFox menu add to home screen step 2"
                    src="assets/img/homescreenandroidfirefox2.jpg" />
                </p>
                <p>

                <p>This will add an icon for Exam Coach to your home screen.</p>
                <p>
                  <img class="icon_reference_small float-left me-3 " src="assets/img/EC_icon.png">
                  Once added, you can click on the icon to open Exam Coach directly without needing to open your browser
                  and navigate to it.
                </p>

              </div> <!-- END (browserMainVersion>'58') -->
            </div>
            <!-- END Firefox Android -->


            <!-- ANDROID Not FF & Not Chrome -->
            <div class="NotChrome NotFirefox " *ngIf="(devicebrowser!=='Chrome') && (devicebrowser!=='Firefox')">
              <h5><strong>We Recommend Using Chrome </strong></h5>
              <p><strong>
                  Please Install Chrome or FireFox to enable Home Screen App Feature.
                </strong></p>
            </div>
            <!-- END NotChrome & NotFirefox Android -->

          </ng-container>
          <!-- END androidUsers -->

        </ng-container>
        <!-- END IF IsMobi -->

        <!-- IF Desktop === not Mobi -->
        <ng-container class="NotMobi NotTablet" *ngIf="(isMobi===false) && (isTablet===false)">

          <!-- <h1 class="TESTcase"><strong class="text-warning">!!! NotMobi NotTablet .:. Desktop !!! </strong></h1> -->

          <div class="chromeUsers deskTop" *ngIf="(devicebrowser=='Chrome')">
            <h5><strong>Chrome Desktop Users </strong></h5>

            <p>1. Click the Plus Indicator at the top right of your browser window.

              <img alt="Chrome plus icon to install App" class="clearfix img-fluid d-block"
                src="assets/img/Step1.png" />

            </p>
            <p>
              2. Then click <strong>Install</strong>.
            </p>

            <p>
              <img alt="desktop alert message to install app" src="assets/img/Step2.png" />
            </p>
            <hr>

            <p>3. If you don't see that option, click the menu icon at the top right of your browser window to open the
              Chrome menu. Select <strong>Install ABMP Exam Coach</strong>.

              <img class="img-fluid d-inline" alt="chrome menu add to home screen icon" src="assets/img/Step3.png" />

            </p>


            <p>
              4. Then click <strong>Install</strong>.
              <br>
              <img class="img-fluid" alt="chrome menu add to home screen icon step to" src="assets/img/Step2.png" />
            </p>

            <hr class="divider">
            <p class="lastparagraph">
              <img class="icon_reference_small float-left me-3 " src="assets/img/EC_icon.png">
              6. The icon will save to your Desktop, and you can open ABMP Exam Coach by clicking on the icon.
              <em>(You may be prompted to log in again, the first time you open it using the icon).</em>
            </p>
            <hr class="divider clearfix mt-2">
            <p class="divider clearfix">&nbsp;</p>
          </div>
          <!-- End Chrome Desktop -->

          <!-- Not Chrome -->
          <div class="NotChrome " *ngIf="(devicebrowser!=='Chrome')">
            <h5><strong>We Recommend Using Chrome </strong></h5>
            <p><strong>
                Please Install Chrome to enable Home Screen App Feature.
              </strong></p>
          </div>
          <!-- END NotChrome Desktop -->

        </ng-container>
        <!-- END Desktop i.e. Not Mobile -->
      </div>
      <!-- END class="field-item content_area"" -->
    </div>
    <!-- END class="col-md-8" -->

    <div class="col-md-4">
    </div>
  </div> <!-- END .row.content_area -->
</div>