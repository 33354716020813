import { Component, OnInit } from '@angular/core'
import { SlideInOutAnimation } from '../animations'

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [SlideInOutAnimation]
})
export class FaqComponent implements OnInit {
  active: boolean
  activeFaq: string

  constructor () {}

  toggleShowDiv (id: string) {
    this.activeFaq = id
    // this.active === true
  }
  ngOnInit () {}
}
