import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { FirebaseAuthService } from './firebase-auth.service';
import { FirebaseFunctionService } from './firebase-function.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserAgentTrackingService {

  constructor(private fbFunctions: FirebaseFunctionService, private userService: UserService) {
    return;
    this.userService.User$.subscribe(user => {
      if (!user.IsLoggedIn) return;

      this.fbFunctions.TrackUserAgent().pipe(take(1)).subscribe(response => {
      });
    })
  }
}
