/* src/app/exam-timeline/exam-timeline.component.scss */
div.timeentry:nth-of-type(odd) {
  background-color: #CCC;
}
div.icon {
  width: 2rem;
}
div.time {
  min-width: 10rem;
}
@media (min-width: 768px) {
  div.timeentry {
    width: 700px;
  }
}
/*# sourceMappingURL=/exam-timeline.component.css.map */
