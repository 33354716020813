<app-subject-topic-header contentarea="Search Content"></app-subject-topic-header>

<input #searchstring type="text" placeholder="Search..." [value]="Search.SearchString" (keyup)="searchKey($event)" />
<button (click)="RunSearch()">Search</button>
<dl>
  <!-- Toggle view after search is initiated -->
  <div *ngIf="searchActive" id="display-toggle" class="d-flex row mt-3 ms-1">
    <a class="me-2 {{this.viewAll ? 'orange' : ''}}" [routerLink]="null" (click)="seeAll()">All</a> | &nbsp;
    <a class="me-2 {{this.cardsOnly ? 'orange' : ''}}" [routerLink]="null" (click)="seeCards()"> Flash cards</a> |&nbsp;
    <a class="{{this.termsOnly ? 'orange' : ''}}" [routerLink]="null" (click)="seeTerms()"> Terminology</a>
  </div>
  <!-- End toggle-view -->
  <div *ngIf="this.viewAll || this.cardsOnly">
    <ng-container *ngIf="(Flashcards$ | async) as cards">
      <h3 class="mt-3">Flashcard results for <span class="orange">{{Search.SearchString}}</span>:</h3>
      <p *ngIf="(cards.length == 0)" class="teal">No Flashcard references to show</p>
      <ng-container *ngFor="let card of cards">
        <dt>{{card.Front}}</dt>
        <dd class="ms-4">{{card.Back}}<br>
          <fa-icon icon="info-circle" class="mb-2 teal"></fa-icon> <a
            [routerLink]="['/subjects', card.Subject, card.Topic]"> {{card.SubjectTopicName}}</a>
        </dd>

      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="this.viewAll || this.termsOnly">
    <ng-container *ngIf="(Terms$ | async) as terms">
      <h3 class="mt-4">Terminology results for <span class="orange">{{Search.SearchString}}</span>:</h3>
      <p *ngIf="(terms.length == 0)" class="teal">No Terminology references to show</p>
      <ng-container *ngFor="let term of terms">
        <dt>{{term.Term}}</dt>
        <dd class="ms-4">{{term.Definition}}</dd>
        <button class="d-flex ms-4 mb-2" (click)="playAudio($event, term.Pronunciation)"
          aria-label="Hear pronunciation for term">
          Pronunciation<fa-icon icon="volume-up" class="ms-2"></fa-icon>
        </button>
        <fa-icon icon="info-circle" class="ms-4 mb-2 teal"></fa-icon> <a
          [routerLink]="['/subjects', term.Subject, term.Topic]"> {{term.SubjectTopicName}}</a>
      </ng-container>
    </ng-container>
  </div>
</dl>
