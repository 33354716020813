import { ShufflePipe } from '../_pipes/shuffle.pipe';

export class ExamQuestion {
    public get ObjectName(): string { return 'ExamQuestion'; }
    public FirestoreID: string;
    public Exclude: boolean;
    public Question: string;
    public Answers: string[];
    public Answer: string;
    public SelectedAnswer: string;
    public Subject: string;
    public Topic: string;
    public SubjectTopicName: string;

    constructor(fsSource: any) {
        this.FirestoreID = fsSource.FirestoreID;
        this.Exclude = fsSource.Exclude;
        this.Question = fsSource.Question;
        this.Answers = fsSource.Answers;
        this.Answer = fsSource.Answer ? fsSource.Answer : this.Answers[0];
        this.SelectedAnswer = fsSource.SelectedAnswer || null;

        this.Subject = fsSource.Subject;
        this.Topic = fsSource.Topic;

        if (fsSource.SubjectTopicName) {
            this.SubjectTopicName = fsSource.SubjectTopicName;
        }
    }

    public IsCorrect(answerToCheck?: string): boolean {
        return this.Answer == (answerToCheck ? answerToCheck : this.SelectedAnswer);
    }

    public ShuffleAnswers() {
        this.Answers = new ShufflePipe().transform(this.Answers);
    }
}
