<div *ngIf="showInterstitial == true" id="interstitial-modal" aria-live="polite">
  <div class="card">
    <div class="card-header" id="top-message">
      <h1>You're Doing Great!</h1>
    </div>
    <div class="card-body">
      <ng-container *ngIf="showInterstitial">
        <ng-container *ngIf="(Interstitial$ | async) as interstitial">
          {{interstitial.Message}}
          <hr>
          <div>
            <img id="waiting"
              src="{{!this.showClose ? 'assets/img/loading-placeholder.gif' : 'assets/img/flashcheck.png'}}" />
          </div>
          <div class="footer">
            <button *ngIf="this.showClose" class="btn btn-sm btn-info" (click)="closeInterstitial()">close</button>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>
