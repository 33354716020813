<header class="d-md-none mb-4 row justify-content-center">
  <img src="assets/img/examcoach.png" />
</header>

<a id="study-subjects" class="mainlink green" routerLink="/subjects">
  <img src="assets/img/icon_subjects.png" />
  Study Subjects
</a>
<a id="my-progress" class="mainlink maroon" routerLink="/progress">
  <img src="assets/img/icon_progress.png" />
  My Progress
</a>
<a id="practice-exams" class="mainlink teal" routerLink="/exams">
  <img src="assets/img/icon_exam.png" />
  Practice Exams
</a>
<a id="study-info" class="mainlink orange" routerLink="/info">
  <img src="assets/img/icon_info.png" />
  Information &amp; Support
</a>
<a id="get-started" class="mainlink orange" routerLink="/getstarted">
  <img src="assets/img/icon_getstarted.png" />
  Getting Started
</a>
<ng-container *ngIf="(User$ | async) as user">
  <a id="fivemm" class="mainlink green"
    href="{{user.HasActive5MM ? 'https://www.abmp.com/5mm' : 'https://www.abmp.com/members/students/upgrade-exam-coach-plus'}}"
    target="_blank">
    <img src="assets/img/icon_upgrade.png" />
    Access ABMP Five-Minute Muscles
  </a>
</ng-container>
<a id="member-home" class="mainlink green" href="https://www.abmp.com/members/home" target="_blank">
</a>
<app-footer-prompt></app-footer-prompt>
