import { Component, OnInit, Directive, OnChanges, SimpleChanges } from '@angular/core'
import { SlideInOutAnimation } from '../animations'
import { ActivatedRoute, Router } from '@angular/router'
import { TerminologyTerm } from '../_classes/terminology-term'
import { Observable, of } from 'rxjs'
import { UserDataService } from '../_services/user-data.service'
import { map, tap } from 'rxjs/operators'
import { FireStoreStaticService } from '../_services/fire-store-static.service'
import { AppSettings } from '../_classes/app-settings'
import { CultureService } from '../_services/culture.service'
import { DataService } from '../_services/data.service'

@Component({
  selector: 'app-terminology',
  templateUrl: './terminology.component.html',
  styleUrls: ['./terminology.component.scss'],
  animations: [SlideInOutAnimation]
})
export class TerminologyComponent implements OnInit, OnChanges {
  public allTermsDisplay: boolean = false;
  public abcArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  public filterLetter = this.abcArray[0];
  public terms$: Observable<{ left: TerminologyTerm[], right: TerminologyTerm[] }>;
  public activeDefinition: { side: 'left' | 'right', i: number };
  audio = new Audio;
  public errorTerms: string[] = [];

  constructor(private appSettings: AppSettings, public dataService: DataService, private route: ActivatedRoute, private router: Router, private userDataService: UserDataService, private cultureService: CultureService) {
    if (this.router.url == '/terms/all') {
      this.allTermsDisplay = true;
      this.terms$ = dataService.GetTerminology$().pipe(
        map(terms => {
          return terms.filter(term => this.filterLetter == undefined || term.FirstLetter(this.cultureService.PreferredCulture) == this.filterLetter);
        }),
        map(terms => this.SplitTermList(terms))
      );
    } else {
      this.terms$ = dataService.GetTerminology$().pipe(
        map(terms => {
          return terms.filter(term => term.Subject == this.route.snapshot.params.subject && term.Topic == this.route.snapshot.params.topic);
        }),
        map(terms => this.SplitTermList(terms))
      );
    }
  }

  private SplitTermList(terms: TerminologyTerm[]) {
    let split = Math.ceil(terms.length / 2);
    return {
      left: terms.slice(0, split),
      right: terms.slice(split),
    }
  }

  showDefinition(event: any, side: 'left' | 'right', i: number) {
    this.activeDefinition = { side: side, i: i };
    //(event.target.parentElement.nextElementSibling as HTMLElement).focus();
  }

  alphaSort(letter?: string) {
    this.activeDefinition = null;
    this.filterLetter = letter;
    this.terms$ = this.dataService.GetTerminology$().pipe(
      map(terms => {
        return terms.filter(term => this.filterLetter == undefined || term.FirstLetter(this.cultureService.PreferredCulture) == this.filterLetter);
      }),
      map(terms => this.SplitTermList(terms))
    );
  }

  playAudio(evt: Event, url: string, term: string) {
    if (!url.endsWith('.mp3')) url += '.mp3';
    if(this.cultureService.PreferredCulture != 'en') {
      url = this.cultureService.PreferredCulture + '/' + url;
    }
    this.audio.src = this.appSettings.s3BaseUrl + 'pronunciations/' + url;
    this.audio.load();
    this.audio.play();

    this.audio.addEventListener('error', () => {
      if (url.includes('(')) {
        let newUrl = url.substring(0, url.indexOf('('));
        if(newUrl.endsWith('_')) newUrl = newUrl.substring(0, newUrl.length - 1);
        this.playAudio(evt, newUrl, term);
      } else {
        this.errorTerms.push(term);
        (evt.target as Element).remove();
      }
    })
  }

  ngOnInit() {
    this.userDataService.TrackUsage(this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'terms');
  }

  ngOnChanges(changes: SimpleChanges) {
  }
}
