/* src/app/exam-question/exam-question.component.scss */
ul {
  padding-left: 0;
  list-style-type: none;
}
li button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 0.75rem;
  margin: 0.75rem 0;
  background: #cce5e0;
  border: solid 2px;
  font-size: 1rem;
  border-top-color: #e1f7f2;
  border-right-color: #8accbf;
  border-bottom-color: #8accbf;
  border-left-color: #e1f7f2;
  cursor: pointer;
}
li button:enabled:hover {
  border-color: #8accbf;
}
li button:disabled {
  cursor: default;
  color: #000;
  background-color: #cad3d1;
  border-color: #cad3d1;
}
li button div {
  text-indent: -2rem;
  padding-left: 2rem;
}
/*# sourceMappingURL=/exam-question.component.css.map */
