import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from '../_services/firebase-auth.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-my-progress',
  templateUrl: './my-progress.component.html',
  styleUrls: ['./my-progress.component.scss'],

})
export class MyProgressComponent implements OnInit {

  constructor(public userService: UserService) {
  }

  ngOnInit() {
  }

}
