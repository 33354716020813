<app-subject-topic-header text="Select a topic to review"></app-subject-topic-header>

<div class="d-flex flex-wrap justify-content-center justify-content-sm-start">
  <ng-container *ngIf="(MySubject$ | async) as subject; else loadingMessage">
    <div *ngFor="let topic of subject.OrderedTopics">
      <app-subject-topic-card [topic]="topic"></app-subject-topic-card>
    </div>
  </ng-container>
</div>
<ng-template #loadingMessage>
  <app-loading description="load your topics..."></app-loading>
</ng-template>