export class UserDetail {
    public get ObjectName(): string { return 'UserDetail'; }
    public UserRecordExists: boolean = false;
    public ID: number;
    public Name: { first: string, possessive: string };
    public HasActiveEC: boolean;
    public HasActive5MM: boolean;
    private _ecExpirationDate: number;
    private _cohortGroups: Map<number, string[]>;

    private Init() {
        this.ID = 0;
        this.Name = { first: "", possessive: "" };
        this.HasActiveEC = false;
        this.HasActive5MM = false;
        this._cohortGroups = new Map<number, string[]>();
    }

    public static FromNFLogin(data: any) {
        return new UserDetail("nfdata", data);
    }

    public static Deserialize(data: any) {
        return new UserDetail("deserialize", data);
    }

    public static Anonymous() {
        return new UserDetail("anonymous");
    }

    private constructor(source: "anonymous" | "deserialize" | "nfdata", sourceObj?: any) {
        this._cohortGroups = new Map<number, string[]>();
        this.Init();
        if (source == "anonymous") {
            return;
        }
        
        if (source == "deserialize") {
            for (let key in sourceObj) {
                if (this.hasOwnProperty(key)) {
                    this[key] = sourceObj[key];
                }
            }
        }

        if(source == "nfdata") {
            this.ID = sourceObj.Columns.cst_recno;
            this.Name.first = sourceObj.Columns.ind_first_name;
            this.Name.possessive = sourceObj.Columns.ind_last_name;
            sourceObj.Collections.client_pac_get_ec_subscriptions.forEach(sub => {
                if(sub.Columns.active == "1") {
                    this.HasActiveEC = true;
                    this._ecExpirationDate = Date.parse(sub.Columns.trm_end_date);
                    if(sub.Columns.org_id) {
                        if(this._cohortGroups.has(sub.Columns.org_id)) {
                            this._cohortGroups.get(sub.Columns.org_id).push(sub.Columns.org_group);
                        } else {
                            this._cohortGroups.set(sub.Columns.org_id, [sub.Columns.org_group]);
                        }
                    }
                }
            });
            sourceObj.Collections.InvoiceDetailTerm.forEach(term => {
                if(term.Columns.prd_code == 'A5mm') {
                    this.HasActive5MM = true;
                }
            });
        }
    }

    public get IsLoggedIn(): boolean {
        return this.ID != 0;
    }

    public get ECExpirationDate(): string {
        return new Date(this._ecExpirationDate).toLocaleDateString();
    }

    public get IsCohortStudent(): boolean {
        return this._cohortGroups.size > 0;
    }

    public get CohortGroups(): Map<number, string[]> {
        return this._cohortGroups;
    }
}
