/* src/app/quiz-questions/quiz-questions.component.scss */
ul {
  padding-left: 0;
  list-style-type: none;
}
li button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 0.75rem;
  margin: 0.75rem 0;
  background: #cce5e0;
  border: solid 2px;
  font-size: 1rem;
  border-top-color: #e1f7f2;
  border-right-color: #8accbf;
  border-bottom-color: #8accbf;
  border-left-color: #e1f7f2;
  cursor: pointer;
}
li button:enabled:hover {
  border-color: #8accbf;
}
li button:disabled {
  cursor: default;
  color: #000;
  background-color: #cad3d1;
  border-color: #cad3d1;
}
li button div {
  text-indent: -2rem;
  padding-left: 2rem;
}
.correct {
  color: #88BF10;
  font-weight: bold;
}
.incorrect {
  color: #777;
  font-weight: bold;
}
#next-button {
  display: block;
  padding: 0.75rem 1rem;
  margin: 0 auto;
  font-size: 1rem;
  background: #ff8b00;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
}
@media screen and (max-width: 780px) {
  fa-icon {
    font-size: 0.9rem;
  }
}
/*# sourceMappingURL=/quiz-questions.component.css.map */
