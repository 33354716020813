<ng-container *ngIf="(fssService.OrderedSubjects | async) as subjects; else loading">
  <ng-container *ngIf="(interstitialService.Pending$ | async) === false">
    <ng-container *ngIf="(Exam$ | async) as exam; else loading">
      <h3><strong>Score:</strong><span class="ms-2 {{exam.Score >= 70 ? 'green' : 'maroon'}}">{{exam.Score}}%</span>
      </h3>
      <p class="font-italic">Use the search feature to research topic areas for missed questions below.</p>
      <p>
        <a *ngIf="reviewView == 'subjects'" [routerLink]="null" (click)="reviewView='timeline'">Show exam timeline</a>
        <a *ngIf="reviewView == 'timeline'" [routerLink]="null" (click)="reviewView='subjects'">Show subject
          breakdown</a>
      </p>
      <div *ngIf="reviewView == 'subjects'" id="review-table">
        <section *ngFor="let subject of subjects" class="subject">
          <header class="d-flex justify-content-between">
            <h4>
              <fa-icon *ngIf="areaBreakdown.get(subject.Alias).Percent >= 70" icon="check-circle" class="green me-2">
              </fa-icon>
              <fa-icon *ngIf="areaBreakdown.get(subject.Alias).Percent < 70" icon="times-circle" class="maroon me-2">
              </fa-icon>
              {{subject.Name}}
            </h4>
            <h5 *ngIf="areaBreakdown.get(subject.Alias).QuestionCount > 0">
              {{areaBreakdown.get(subject.Alias).Percent}}%
            </h5>
          </header>
          <aside *ngIf="areaBreakdown.get(subject.Alias).QuestionCount == 0">No questions for this subject</aside>
          <aside *ngIf="areaBreakdown.get(subject.Alias).QuestionCount > 0">
            You answered {{areaBreakdown.get(subject.Alias).Correct}} out of
            {{areaBreakdown.get(subject.Alias).QuestionCount}} questions correctly.
            <a *ngIf="!visibleSubjects.includes(subject.Alias)" [routerLink]="null"
              (click)="visibleSubjects.push(subject.Alias)">Show topics</a>
            <a *ngIf="visibleSubjects.includes(subject.Alias)" [routerLink]="null"
              (click)="visibleSubjects.splice(visibleSubjects.indexOf(subject.Alias), 1)">Hide topics</a>
          </aside>

          <div [@slideInOut]="visibleSubjects.includes(subject.Alias) ? 'in' : 'out'" class="topicwrapper">
            <section *ngFor="let topic of subject.OrderedTopics" class="topic">
              <ng-container *ngIf="areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).QuestionCount > 0">
                <h5>
                  <fa-icon *ngIf="areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).Percent >= 70"
                    icon="check-circle" class="green me-2"></fa-icon>
                  <fa-icon *ngIf="areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).Percent < 70"
                    icon="times-circle" class="maroon me-2"></fa-icon>
                  <span class=" topic-question teal">{{topic.Name}}</span>
                  <ng-container *ngIf="areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).QuestionCount > 0">:
                    {{areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).Percent}}%</ng-container>
                </h5>
                <aside *ngIf="areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).QuestionCount == 0">No questions
                  for
                  this topic</aside>
                <aside *ngIf="areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).QuestionCount > 0">
                  You answered {{areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).Correct}} out of
                  {{areaBreakdown.get(subject.Alias).Topics.get(topic.Alias).QuestionCount}} questions correctly.
                  <a *ngIf="!visibleTopics.includes(topic.Alias)" [routerLink]="null"
                    (click)="visibleTopics.push(topic.Alias)">Show questions</a>
                  <a *ngIf="visibleTopics.includes(topic.Alias)" [routerLink]="null"
                    (click)="visibleTopics.splice(visibleTopics.indexOf(topic.Alias), 1)">Hide questions</a>
                </aside>
                <div [@slideInOut]="visibleTopics.includes(topic.Alias) ? 'in' : 'out'" class="questioncontainer">
                  <ng-container *ngFor="let question of exam.Questions ; let i = index">
                    <app-question-review *ngIf="question.Subject == subject.Alias && question.Topic == topic.Alias"
                      [question]="question" [questionNumber]="i" [showSource]="true" class="d-block ms-3">
                    </app-question-review>
                  </ng-container>
                </div>
              </ng-container>
            </section>
          </div>
        </section>
      </div>

      <app-exam-timeline *ngIf="reviewView == 'timeline'" [exam]="exam"></app-exam-timeline>

    </ng-container>
  </ng-container>
</ng-container>

<div *ngIf="activateInterstitial">
  <app-interstitial></app-interstitial>
</div>

<ng-template #loading>
  <app-loading description="load your exam details..."></app-loading>
</ng-template>