import { Injectable } from '@angular/core';
import { IAppSettings } from '../_models/app-settings';

declare global {
    interface Window {
        Settings: IAppSettings;
    }
}

@Injectable({providedIn: 'root' })
export class AppSettings {
    abmpBaseUrl: string;
    nfBaseUrl: string;
    apiBaseUrl: string;
    s3BaseUrl: string;
    Production: boolean = false;

    constructor() {
        let win = window as any;
        if ('Settings' in win && win.Settings) {
            Object.assign(this, win.Settings);
            this.Production = this.abmpBaseUrl == "https://www.abmp.com/";
        }
    }
}
