import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, first, filter } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  private _redirectUrl: string = null;

  constructor(private userService: UserService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.userService.User$.pipe(
      filter(ud => ud.ID != null),
      map(user => {
        if (user.IsLoggedIn) {
          if (state.url.startsWith('/admin')) {
            return this.router.createUrlTree(['/']);
          }
          if (!user.HasActiveEC) {
            return this.router.createUrlTree(['/purchase']);
          }
          return true;
        }
        this._redirectUrl = state.url;
        return this.router.createUrlTree(['/login']);
      })
    );
  }

  public get RedirectURL(): string {
    let url = this._redirectUrl;
    this._redirectUrl = null;
    return url;
  }
}
