<a *ngIf="!start && exam.State != ExamStates.Paused" [routerLink]="['/', 'exams', number]">
  <h3>Practice Exam {{number}}</h3>
  <h4>{{exam.FriendlyState}}</h4>
  <ng-container *ngIf="(exam.RemainingTime$ | async) as RemainingTime">
    <h5 id="remaining" *ngIf="exam.State != 'COMPLETE'">{{RemainingTime}} remaining<br />{{exam.Answered}} answered
    </h5>
  </ng-container>
  <div *ngIf="exam.State == 'COMPLETE'" class="d-flex justify-content-between">
    <h5 class="text-left text-1rem">{{exam.Answered}} answered<br />{{exam.Correct}} correct</h5>
    <h5 class="text-1rem {{exam.Score < 70 ? 'maroon' : 'green'}} score">{{exam.Score}}%</h5>
  </div>
  <span *ngIf="exam.State == 'COMPLETE'">See Detailed Results</span>
</a>

<a *ngIf="!start && exam.State == ExamStates.Paused" [routerLink]="null" (click)="ResumeExam()">
  <h3>Practice Exam {{number}}</h3>
  <h4>{{exam.FriendlyState}}</h4>
  <ng-container *ngIf="(exam.RemainingTime$ | async) as RemainingTime">
    <h5 *ngIf="exam.State != 'COMPLETE'">{{RemainingTime}} remaining<br />{{exam.Answered}} answered</h5>
  </ng-container>
  <span>Continue Exam</span>
</a>

<a *ngIf="start && !StartingExam" [routerLink]="null" (click)="StartExam()">
  <h3>Practice Exam {{number}}</h3>
  <h4>Start a new exam</h4>
  <h5 class="invisible">&nbsp;<br />&nbsp;</h5>
  <span class="invisible">&nbsp</span>
</a>

<a *ngIf="StartingExam" [routerLink]="null">
  <h3>Practice Exam {{number}}</h3>
  <h4>Starting exam...</h4>
  <h5 class="invisible">&nbsp;<br />&nbsp;</h5>
  <span class="invisible">&nbsp</span>
</a>
