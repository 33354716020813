/* src/app/homepage/homepage.component.scss */
@media (min-width: 768px) {
  :host {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-image: url("/media/home-upgrade.png");
    background-repeat: no-repeat;
    background-position: center top;
  }
  .mainlink {
    position: absolute;
    left: 50%;
    color: transparent;
  }
  .mainlink img {
    display: none;
  }
  #study-subjects {
    top: 338px;
    margin-left: -228px;
    width: 7rem;
    height: 7.5rem;
  }
  #my-progress {
    top: 408px;
    margin-left: -91px;
    width: 8rem;
    height: 7.5rem;
  }
  #practice-exams {
    top: 337px;
    margin-left: 126px;
    width: 7rem;
    height: 7.5rem;
  }
  #study-info {
    width: 5rem;
    height: 5rem;
    top: 454px;
    margin-left: -272px;
  }
  #get-started {
    width: 5rem;
    height: 6rem;
    top: 275px;
    margin-left: -309px;
  }
  #fivemm {
    width: 7rem;
    height: 7rem;
    top: 81px;
    margin-left: 185px;
  }
  #member-home {
    width: 11rem;
    height: 9rem;
    top: 41px;
    margin-left: -9rem;
  }
}
@media (max-width: 767px) {
  a.mainlink {
    display: block;
    font-size: 1.5rem;
    margin: 1rem 0;
    text-decoration: none;
  }
  a.mainlink img {
    margin-right: 1rem;
  }
}
/*# sourceMappingURL=/homepage.component.css.map */
