<app-subject-topic-header head="Practice Exam {{examNumber}}"></app-subject-topic-header>

<ng-container *ngIf="(Exam$ | async) as exam">
  <ng-container [ngSwitch]="exam.State">
    <app-exam-question *ngSwitchCase="ExamStates.Active"></app-exam-question>
    <div *ngSwitchCase="ExamStates.Paused">This exam is currently paused. <a [routerLink]="null"
        (click)="ResumeExam()" class="teal">Resume this exam now</a>.</div>
    <app-exam-review *ngSwitchCase="ExamStates.Complete">
    </app-exam-review>
  </ng-container>
</ng-container>
