import { Injectable } from '@angular/core';
import { ExamCoachSubject } from '../_classes/exam-coach-subject';
import { ExamQuestion } from '../_classes/exam-question';
import { ReplaySubject, Observable, combineLatest, of } from 'rxjs';
import { FlashCard } from '../_classes/flash-card';
import { TerminologyTerm } from '../_classes/terminology-term';
import { ExamCoachTopic } from '../_classes/exam-coach-topic';
import { map, switchMap, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FireStoreStaticService {
  private _SubjectMap: Map<string, ExamCoachSubject> = new Map<string, ExamCoachSubject>();
  private _sortedAliases: string[] = [];
  private _sortedSubjects: ExamCoachSubject[] = [];
  public SubjectMap$: ReplaySubject<Map<string, ExamCoachSubject>> = new ReplaySubject<Map<string, ExamCoachSubject>>();
  private _allFlashcards: FlashCard[] = [];
  private _allTerms: TerminologyTerm[] = [];
  public TimeDifference$: ReplaySubject<number> = new ReplaySubject(1);

  constructor() {
    /*this.firestore.doc<any>('contentcache/subjects').valueChanges().pipe(
      first(),
      map(subjectDoc => {
        if (!subjectDoc?.json) return;
        this._SubjectMap = new Map<string, ExamCoachSubject>();
        this._sortedAliases = [];
        let fsSubjects = JSON.parse(subjectDoc.json);
        fsSubjects.forEach(fsSubject => {
          let objSubject = new ExamCoachSubject(fsSubject);
          fsSubject.Topics.forEach(fsTopic => {
            fsTopic.Subject = objSubject.Alias;
            objSubject.AddTopic(new ExamCoachTopic(fsTopic));
          });
          this._SubjectMap.set(objSubject.Alias, objSubject);
        });
        [...this._SubjectMap.values()].sort((a, b) => {
          if (a.Order > b.Order) return 1;
          if (a.Order < b.Order) return -1;
          return 0;
        }).forEach(subject => {
          this._sortedAliases.push(subject.Alias);
        });
        this.SubjectMap$.next(this._SubjectMap);
      })
    ).subscribe();

    this.fbFunctions.httpsCallable('GetServerTime')({}).subscribe(time => {
      let serverTime = new Date(time.time);
      let clientTime = new Date();
      let sDiff = (serverTime.getTime() - clientTime.getTime()) / 1000;
      this.TimeDifference$.next(sDiff);
    });
    */
  }

  public get OrderedSubjects(): Observable<ExamCoachSubject[]> {
    if (this._sortedSubjects.length > 0 && this._sortedSubjects.length == this._SubjectMap.size) {
      return of(this._sortedSubjects);
    }
    return this.SubjectMap$.pipe(
      map(subjectMap => {
        this._sortedSubjects = [];
        this._sortedAliases.forEach(alias => {
          this._sortedSubjects.push(subjectMap.get(alias));
        })
        return this._sortedSubjects;
      })
    );
  }

  public GetSubject(subjectAlias: string): Observable<ExamCoachSubject> {
    return this.SubjectMap$.pipe(
      map(subjectMap => {
        return subjectMap.get(subjectAlias);
      })
    );
  }

  public GetTopic(subjectAlias: string, topicAlias: string): Observable<ExamCoachTopic> {
    return this.SubjectMap$.pipe(
      map(subjectMap => {
        return subjectMap.get(subjectAlias).Topics.get(topicAlias);
      })
    );
  }

  public GetContent<T>(subjectAlias: string, topicAlias: string, contentArea: 'flashcards' | 'questions' | 'terms'): Observable<T[]> {
    if (subjectAlias == null && topicAlias == null) {
      let result$: Observable<any>;
      let cachedResult = contentArea == "terms" ? this._allTerms : this._allFlashcards;
      result$ = cachedResult.length > 0 ? of(cachedResult) : this.GetFirestoreContent(subjectAlias, topicAlias, contentArea);
      return result$.pipe(
        map(result => {
          if (cachedResult.length == 0) {
            switch (contentArea) {
              case 'flashcards':
                this._allFlashcards = result as FlashCard[];
                break;
              case 'terms':
                this._allTerms = result as TerminologyTerm[];
                break;
            }

          }
          return result;
        })
      );
    }
    return this.SubjectMap$.pipe(
      switchMap(subjectMap => {
        let topic = subjectMap.get(subjectAlias).Topics.get(topicAlias);
        let result$: Observable<any>;
        switch (contentArea) {
          case 'flashcards':
            result$ = topic.FlashCards.length > 0 ? of(topic.FlashCards) : this.GetFirestoreContent(subjectAlias, topicAlias, contentArea);
            break;
          case 'questions':
            result$ = topic.Questions.length > 0 ? of(topic.Questions) : this.GetFirestoreContent(subjectAlias, topicAlias, contentArea);
            break;
          case 'terms':
            result$ = topic.Terms.length > 0 ? of(topic.Terms) : this.GetFirestoreContent(subjectAlias, topicAlias, contentArea);
            break;
        }
        return result$.pipe(
          map(result => {
            switch (contentArea) {
              case 'flashcards':
                if (topic.FlashCards.length == 0) topic.FlashCards = result as FlashCard[];
                break;
              case 'questions':
                if (topic.Questions.length == 0) topic.Questions = result as ExamQuestion[];
                break;
              case 'terms':
                if (topic.Terms.length == 0) topic.Terms = result as TerminologyTerm[];
                break;
            }
            return result;
          })
        );
      })
    );
  }

  public GetExamQuestions(subjectAlias: string, topicAlias: string, weight: number): Observable<{ weight: number, questions: ExamQuestion[] }> {
    return this.GetContent<ExamQuestion>(subjectAlias, topicAlias, "questions").pipe(
      map(allQuestions => {
        allQuestions = allQuestions.filter(q => q.Exclude === false);
        for (let i = allQuestions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * i)
          const temp = allQuestions[i]
          allQuestions[i] = allQuestions[j]
          allQuestions[j] = temp
        }
        return { weight: weight, questions: allQuestions.slice(0, Math.ceil(weight)) };
      })
    );
  }

  private GetFirestoreContent(subjectAlias: string, topicAlias: string, contentArea: 'flashcards' | 'questions' | 'terms'): Observable<FlashCard[] | ExamQuestion[] | TerminologyTerm[]> {
    throw new Error('Method not implemented.');
    /*
    let collectionPath = 'contentcache/' + subjectAlias + ':' + topicAlias + '/' + contentArea;
    if (subjectAlias == null && topicAlias == null) {
      switch (contentArea) {
        case 'flashcards':
          collectionPath = 'contentcache/allcards/cards';
          break;
        case 'terms':
          collectionPath = 'contentcache/allterms/terms';
          break;
      }
    }
    return this.firestore.collection<any>(collectionPath).valueChanges().pipe(
      switchMap(contentChunks => {
        if (contentChunks.length > 0 && contentChunks[0].content.length > 20) {
          return of(contentChunks);
        } else {
          return this.fbFunctions.httpsCallable('UpdateContentCacheFromClient')({ subject: subjectAlias, topic: topicAlias, contentarea: contentArea });
        }
      }),
      switchMap(contentChunks => {
        return combineLatest([[contentChunks], this.SubjectMap$]);
      }),
      map(([contentChunks, subjectMap]) => {
        let fullContentArray = [];
        for (let c = 0; c < contentChunks.length; c++) {
          if (!contentChunks[c]?.content) return;
          let fsContent = JSON.parse(contentChunks[c].content);
          fsContent.forEach(fsItem => {
            fsItem.SubjectTopicName = subjectMap.get(fsItem.Subject).Name + ' > ' + subjectMap.get(fsItem.Subject).Topics.get(fsItem.Topic).Name;
            switch (contentArea) {
              case 'flashcards':
                //fullContentArray.push(new FlashCard(fsItem));
                break;
              case 'questions':
                fullContentArray.push(new ExamQuestion(fsItem));
                break;
              case 'terms':
                if (fsItem.Term.length > 0) {
                  fullContentArray.push(new TerminologyTerm(fsItem));
                }
                break;
            }
          });
        }
        return fullContentArray;
      })
    )
      */
  }

  public TriggerCacheUpdate(subject: string, topic: string, areas: any) {
    throw new Error('Method not implemented.');
    /*
    this.firestore.doc("subjects/" + subject + "/topics/" + topic).set({ UpdateCache: areas }, { merge: true }).then(r => {
    });
    */
  }
}
