import { Component, OnDestroy, OnInit } from '@angular/core';
import { FireStoreInterstitialsService } from '../_services/fire-store-interstitials.service';
import { interval, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserDataService } from '../_services/user-data.service';

@Component({
  selector: 'app-interstitial',
  templateUrl: './interstitial.component.html',
  styleUrls: ['./interstitial.component.scss']
})
export class InterstitialComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public showInterstitial: boolean = true;
  public showClose: boolean = true;
  public Interstitial$ = this.interstitialService.GetRandomInterstitial();
  public timer = interval(10000);

  constructor(public interstitialService: FireStoreInterstitialsService, private userData: UserDataService) { }

  closeInterstitial() {
    this.interstitialService.Pending$.next(false);
    this.userData.UpdateInterstitialShown();
    this.showInterstitial = false;
  }

  ngOnInit() {
    this.showClose = false;
    this._subscriptions.add(this.timer.pipe(
      first(),
      map(t => {
        this.showClose = true;
      })
    ).subscribe());
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    document.getElementById('top-message')?.focus();
  }
}
