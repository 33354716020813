import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private _showSearch: boolean = false;
  private _searchString: string = '';
  
  constructor() { }

  public get ShowSearch(): boolean {
    return this._showSearch;
  }

  public ToggleSearchVisibility(set?: boolean) {
    this._showSearch = set == undefined ? !this._showSearch : set;
  }

  public SetSearchString(searchString: string) {
    this._searchString = searchString;
  }

  public get SearchString(): string {
    return this._searchString;
  }

}
