import { Component, OnInit, ViewChild, ViewChildren, ElementRef  } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-footer-prompt',
  templateUrl: './footer-prompt.component.html',
  styleUrls: ['./footer-prompt.component.scss']
})
export class FooterPromptComponent implements OnInit {

  deviceInfo = null;
  public ShowBar: string;
  public deviceOS: string;
  public isIOS: boolean;
  public IOSandMobi: boolean;
  public IosMobiSafari: boolean;
  public devicebrowser: string;
  public deviceMobi: boolean;

  public hideFooterBar: boolean;
  public showFooterBar: boolean;
  public appAlertNotSet: boolean;
  public ShowPopup: boolean;
  public todaysDate = new Date();

  @ViewChildren('footerPrompt') footerPrompt: ElementRef;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    this.epicDeviceFunction();
  }
  ngOnInit() {
    // could not localStorage.getItem here
    // cause it returned null to often
    // but it worked ok from inside this function //
    this.iosAlertCheck();
  }
  epicDeviceFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceMobi = this.deviceService.isMobile();
    this.deviceOS = this.deviceInfo.os;
    this.devicebrowser = this.deviceInfo.browser;
  }
  HideTheBoxVar() {
    this.showFooterBar = false;
    localStorage.setItem('ShowBar', 'HIDE');
    this.ShowBar = localStorage.getItem('ShowBar');
  }
  ShowTheBoxVar() {
    this.showFooterBar = true;
    localStorage.setItem('ShowBar', 'SHOW');
    this.ShowBar = localStorage.getItem('ShowBar');
  }
  clearTheBoxVar() {
    this.showFooterBar = null;
    localStorage.removeItem('ShowBar');
    localStorage.removeItem('appNotify');
    this.ShowBar = localStorage.getItem('ShowBar');
  }
  iosAlertCheck() {
    // check if ios

    this.ShowBar = localStorage.getItem('ShowBar');
    const appNotify = this.ShowBar;

    // First Check for ShowBar set to HIDE
    if (appNotify === 'HIDE') {
      this.showFooterBar = false;

    } else if ( (localStorage) ) {
      // if (typeof localStorage !== 'undefined') {
      // LocalStorage 'Should' Work
      if (appNotify === 'SHOW') {
        this.showFooterBar = true;
      } else if ( (appNotify === null) ) {
        // ShowBar not set but locaStor is avail
        if ( (this.deviceOS === 'iOS') ) {
          // case for iOS
          this.showFooterBar = true; // SHOW it!
          localStorage.setItem('ShowBar', 'SHOW');
        } else if ( !(this.deviceOS === 'iOS') ) {
          // case for Not iOS
          this.showFooterBar = false; // hide it!
          localStorage.setItem('ShowBar', 'HIDE');
          this.ShowBar = localStorage.getItem('ShowBar');
        } else {
          // Case for iOS but NotSetYet
        }
      }

    } else {
      // if can't detect previous showBar setting
      // and localStorage isn't available
      // then set else else conditon to false
      // that way the alert box doesn't keep popping up on every pageLoad
      this.showFooterBar = false;
    }

  }


}
