import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Exam } from '../_classes/exam';
import { ExamQuestion } from '../_classes/exam-question';
import { FireStoreInterstitialsService } from '../_services/fire-store-interstitials.service';
import { UserDataService } from '../_services/user-data.service';

@Component({
  selector: 'app-exam-question',
  templateUrl: './exam-question.component.html',
  styleUrls: ['./exam-question.component.scss']
})
export class ExamQuestionComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  public exam: Exam;
  public question: ExamQuestion;
  public answered: boolean = false;
  public letters = 'ABCDEF'.split('');

  constructor(private userData: UserDataService, private interstitialSvc: FireStoreInterstitialsService) {
    this._subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this._subscriptions.add(this.userData.Exams$.pipe(
      map(exams => exams.find(exam => exam.State == Exam.ExamState.Active)),
      map(exam => {
        if (!exam) return;
        this.UpdateQuestion(exam);
      })
    ).subscribe());
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private UpdateQuestion(exam: Exam) {
    this.answered = false;
    this.exam = exam;
    this.question = exam.NextQuestion();
  }

  AnswerQuestion(questionId: string, answer: string) {
    this.answered = true;
    this._subscriptions.add(this.userData.AnswerExam(this.exam.Answer(questionId, answer)).pipe(take(1)).subscribe(() => document.getElementById('question-number').focus()));
  }
}
