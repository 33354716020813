import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { AppSettings } from '../_classes/app-settings'
import { ExamCoachSubject } from '../_classes/exam-coach-subject'
import { ExamCoachTopic } from '../_classes/exam-coach-topic'
import { UserDataService } from '../_services/user-data.service'
import { DataService } from '../_services/data.service'
import { ECSubject } from '../_classes/ec-subject'
import { ECTopic } from '../_classes/ec-topic'

@Component({
  selector: 'app-topic-landing',
  templateUrl: './topic-detail.component.html',
  styleUrls: ['./topic-detail.component.scss']
})
export class TopicDetailComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  topicPath: string;
  activeQuiz: boolean = false;

  public subject$: Observable<ECSubject>
  public topic$: Observable<ECTopic>
  constructor(public appSettings: AppSettings, private dataService: DataService, private route: ActivatedRoute, private userData: UserDataService) { }

  ngOnInit() {
    this.subject$ = this.dataService.GetSubject$(this.route.snapshot.params.subject);
    this.topic$ = this.dataService.GetTopic$(this.route.snapshot.params.subject, this.route.snapshot.params.topic);
    this._subscriptions.add(this.userData.GetActiveQuiz(this.route.snapshot.params.subject, this.route.snapshot.params.topic).subscribe(quiz => {
      if(!quiz.NoQuizResultFound) this.activeQuiz = true;
    }));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
