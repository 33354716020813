<div id="footer-prompt" #footerPrompt [ngClass]="{'showFooterBar': ( (showFooterBar===true) ),
      'HideFooterPrompt': ( (showFooterBar===false) )  }">
  <p [ngClass]="{'showFooterBar': ( (showFooterBar===true) ),
            'HideFooterPrompt': ( (showFooterBar===false) )  }">
    <a [routerLink]="['/info/homescreen']" (click)="HideTheBoxVar()">
      <img src="https://www.abmp.com/sites/abmp.com/files/sharris/images/share-ios-shortcut-btn36px.png"
        class="icon_reference">
    </a>
    Install this app
    <!-- Add to home screen -->
    <a class="btn btn-info ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" type="button"
      role="button" (click)="HideTheBoxVar()" [routerLink]="['/info/homescreen']">Show Me How</a>
  </p>

  <span id="close" (click)="HideTheBoxVar()">

    <svg aria-hidden="true" class="svg-icon m0 iconClear" width="18" height="18" viewBox="0 0 18 18">
      <path d="M15 4.41L13.59 3 9 7.59 4.41 3 3 4.41 7.59 9 3 13.59 4.41 15 9 10.41 13.59 15 15 13.59 10.41 9 15 4.41z">
      </path>
    </svg>
  </span>

</div>
