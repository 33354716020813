import { Component, OnInit, Input } from '@angular/core';
import { AppSettings } from '../_classes/app-settings';
import { ExamCoachSubject } from '../_classes/exam-coach-subject';
import { ExamCoachTopic } from '../_classes/exam-coach-topic';
import { ECSubject } from '../_classes/ec-subject';
import { ECTopic } from '../_classes/ec-topic';
import { CultureString } from '../_classes/culture-string';

@Component({
  selector: 'app-subject-topic-card',
  templateUrl: './subject-topic-card.component.html',
  styleUrls: ['./subject-topic-card.component.scss']
})
export class SubjectTopicCardComponent implements OnInit {
  @Input() subject: ECSubject;
  @Input() topic: ECTopic;

  public aliasUrl: string;
  public imageUrl: string;
  public name: string;
  public nonmblex: boolean = false;

  constructor(private appSettings: AppSettings) { }

  ngOnInit() {
    if(this.subject) {
      this.aliasUrl = this.subject.Alias;
      this.imageUrl = 'assets/img/subjects/' + this.subject.Alias + '/subject.png';
    } else {
      this.aliasUrl = this.topic.Alias;
      this.imageUrl = 'assets/img/subjects/' + this.topic.Subject.Alias + '/' + this.topic.Alias + '.png';
      this.nonmblex = this.topic.NonMBLEX;
    }
  }

  public get Name(): CultureString {
    return this.subject ? this.subject.Name : this.topic.Name;
  }
}
