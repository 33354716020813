import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExamCoachSubject } from '../_classes/exam-coach-subject';
import { ExamCoachTopic } from '../_classes/exam-coach-topic';
import { FireStoreStaticService } from '../_services/fire-store-static.service';

@Component({
  selector: 'app-subject-topic-header',
  templateUrl: './subject-topic-header.component.html',
  styleUrls: ['./subject-topic-header.component.scss']
})
export class SubjectTopicHeaderComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  @Input() contentarea: string;
  @Input() head: string;
  @Input() subhead: string;
  @Input() text: string;
  public subject: ExamCoachSubject;
  public topic: ExamCoachTopic;

  constructor(private fssService: FireStoreStaticService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.route.snapshot.params.subject) {
      this._subscriptions.add(this.fssService.GetSubject(this.route.snapshot.params.subject).subscribe(subject => {
        this.subject = subject;
      }));
    }
    if (this.route.snapshot.params.topic) {
      this._subscriptions.add(this.fssService.GetTopic(this.route.snapshot.params.subject, this.route.snapshot.params.topic).subscribe(topic => {
        this.topic = topic;
      }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
