import { NgModule } from '@angular/core'
import { Routes, RouterModule, ExtraOptions } from '@angular/router'

//Content:
import { HomepageComponent } from './homepage/homepage.component'
import { SubjectListComponent } from './subject-list/subject-list.component'
import { MyProgressComponent } from './my-progress/my-progress.component'
import { PracticeExamsComponent } from './practice-exams/practice-exams.component'
import { InfoComponent } from './info/info.component'
import { ProgressSubjectsComponent } from './progress-subjects/progress-subjects.component'
import { ProgressExamsComponent } from './progress-exams/progress-exams.component'
import { TopicListComponent } from './topic-list/topic-list.component'
import { TopicDetailComponent } from './topic-detail/topic-detail.component'
import { TerminologyComponent } from './terminology/terminology.component'
import { FlashcardsComponent } from './flashcards/flashcards.component'
import { QuizLandingComponent } from './quiz-landing/quiz-landing.component'
import { QuizQuestionsComponent } from './quiz-questions/quiz-questions.component'
import { ExamCoachSubject } from './_classes/exam-coach-subject'
import { ExamCoachTopic } from './_classes/exam-coach-topic'
import { GetStartedComponent } from './get-started/get-started.component'
import { FaqComponent } from './faq/faq.component'
import { SourcesComponent } from './sources/sources.component'
import { ContactComponent } from './contact/contact.component'
import { AuthGuardService } from './_services/auth-guard.service'
import { LoginComponent } from './login/login.component'
import { QuizReviewComponent } from './quiz-review/quiz-review.component'
import { PurchaseComponent } from './purchase/purchase.component'
import { ExamDisplayComponent } from './exam-display/exam-display.component'
import { SearchResultsComponent } from './search-results/search-results.component'
import {AddToHomeComponent} from './add-to-home/add-to-home.component';
import { TimeCheckComponent } from './time-check/time-check.component'

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
}

const routes: Routes = [
  { path: '', component: HomepageComponent, canActivate: [AuthGuardService] },
  { path: 'login', data: { label: 'Login' }, component: LoginComponent },
  { path: 'purchase', data: { label: 'Purchase ExamCoach' }, component: PurchaseComponent },
  { path: 'timecheck', data: { label: 'Time Check' }, component: TimeCheckComponent },
  { path: 'search', data: { label: 'Search Content' }, component: SearchResultsComponent },
  {
    path: 'subjects',
    data: { label: 'Study Subjects' },
    canActivate: [AuthGuardService],
    children: [
      { path: '', component: SubjectListComponent },
      {
        path: ':subject',
        data: { label: 'dynamic' },
        children: [
          { path: '', component: TopicListComponent },
          {
            path: ':topic',
            data: { label: 'dynamic' },
            children: [
              { path: '', component: TopicDetailComponent },
              {
                path: 'terminology',
                component: TerminologyComponent,
                data: { label: 'Terminology' }
              },
              { path: 'flashcards', data: { label: 'Flashcards' }, component: FlashcardsComponent },
              {
                path: 'quiz',

                children: [
                  { path: '', data: { label: 'Quiz' }, component: QuizLandingComponent },
                  { path: 'questions', data: { label: 'Quiz' }, component: QuizQuestionsComponent },
                  { path: 'review', data: { label: 'Quiz Review' }, component: QuizReviewComponent }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'progress',
    component: MyProgressComponent,
    canActivate: [AuthGuardService],
    data: { label: 'My Progress' },
    children: [
      { path: '', component: ProgressSubjectsComponent },
      {
        path: 'exams',
        component: ProgressExamsComponent,
        data: { label: 'Exams' }
      }
    ]
  },
  {
    path: 'exams',
    data: { label: 'Practice Exams' },
    children: [
      { path: '', component: PracticeExamsComponent, canActivate: [AuthGuardService] },
      { path: ':examnumber', data: { label: 'dynamic' }, component: ExamDisplayComponent, canActivate: [AuthGuardService] }
    ]
  },
  {
    path: 'info',
    data: { label: 'Info' },
    children: [
      { path: '', component: InfoComponent },
      {
        path: 'homescreen',
        component: AddToHomeComponent,
        data: { label: 'Add to Homescreen' }
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { label: 'Frequently Asked Questions' }
      },
      {
        path: 'sources',
        component: SourcesComponent,
        data: { label: 'Sources' }
      },
      {
        path: 'contacts',
        component: ContactComponent,
        data: { label: 'Contact Us' }
      }
    ]
  },
  {
    path: 'getstarted',
    component: GetStartedComponent,
    data: { label: 'Getting Started' }
  },
  {
    path: 'terms/all',
    canActivate: [AuthGuardService],
    component: TerminologyComponent,
    data: { label: 'All Terms' }
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
