/* src/app/add-to-home/add-to-home.component.scss */
h3.pg_title {
  font-size: 1.5em;
  margin-top: 16px;
}
hr.title_sub.divider {
  margin: 12px 12px 16px 0;
  max-width: 48px;
  border-top: 4px solid #feb81a;
}
img.screen_reference {
  max-width: 300px;
}
img.icon_reference {
  width: 36px;
  height: 36px;
}
.icon_reference_small {
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 12px;
  margin-bottom: 12px;
}
.bottom_spacer {
  margin-bottom: 32px;
}
@media (min-width: 991px) {
  .chromeUsers .lastparagraph {
    padding-right: 12px;
    margin-right: 32px;
  }
}
@media (max-width: 480px) {
  hr.title_sub.divider {
    margin: 12px 12px 16px 0;
  }
}
/*# sourceMappingURL=/add-to-home.component.css.map */
