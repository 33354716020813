import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Exam } from '../_classes/exam';
import { UserDataService } from '../_services/user-data.service';


@Component({
  selector: 'app-exam-display',
  templateUrl: './exam-display.component.html',
  styleUrls: ['./exam-display.component.scss'],

})
export class ExamDisplayComponent implements OnInit, OnDestroy {
  public Exam$: Observable<Exam>;
  public examNumber: number;
  public ExamStates = Exam.ExamState;
  private _subscriptions: Subscription = new Subscription();

  constructor(private userData: UserDataService, private route: ActivatedRoute, private router: Router) { }


  ngOnInit(): void {
    this.examNumber = this.route.snapshot.params.examnumber;
    this.Exam$ = this.userData.Exams$.pipe(map(exams => exams[this.examNumber - 1]));
    this._subscriptions.add(this.Exam$.subscribe(exam => {
      if (exam == undefined) {
        this.router.navigate(['exams']);
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ResumeExam() {
    this.userData.ResumeExam();
  }
}
