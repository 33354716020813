import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs';
import { UserDetail } from '../_classes/user-detail';
import { FirebaseAuthService } from '../_services/firebase-auth.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public User$: Observable<UserDetail> = this.userService.User$;
  public buildtime: string = (window as any).buildtime || '';

  constructor (public router: Router, private userService: UserService) {}

  ngOnInit () {}
}
