<app-subject-topic-header head="References Used" subhead="for ABMP Exam Coach"></app-subject-topic-header>

<ul>
  <li>Abegg, J. The Business of Massage Therapy: Building a Successful Career. Upper Saddle River, New Jersey: Pearson
    Education, 2012.</li>
  <li>Allen, L. One Year to a Successful Massage Practice. Baltimore, Maryland: Lippincott Williams & Wilkins, 2012.
    Andrade, C. K. and P. Clifford. Outcome-Based Massage: From Evidence to Practice, 2nd ed. Baltimore, Maryland:
    Lippincott Williams & Wilkins, 2008.</li>
  <li>Aourell, M., M. Skoog, and J. Carleson. “Effects of Swedish Massage on Blood Pressure.” Complementary Therapies
    in Clinical Practice 11, no. 4 (November 2005): 242–246.</li>
  <li>Archer, P. and L. A. Nelson. Applied Anatomy & Physiology for Manual Therapists. Baltimore, Maryland: Lippincott
    Williams & Wilkins, 2012.</li>
  <li>Associated Bodywork & Massage Professionals. “ABMP Massage Client Survey 2013.” Accessed September 2014.
    www.massagetherapy.com/media/metrics_massage_clients.php.</li>
  <li>Barker, A. Improve Your Communication Skills. Philadelphia, Pennsylvania: Kogan Page, 2006.</li>
  <li>Barnard, S. et al. Writing, Speaking, & Communication Skills for Health Professionals. New Haven, Connecticut:
    Yale University Press, 2001.</li>
  <li>Bass, E. “Tendinopathy: Why the Difference Between Tendinitis and Tendinosis Matters.” International Journal of
    Therapeutic Massage & Bodywork 5, no. 1 (2012): 14–17.</li>
  <li>Beck, M. F. Theory & Practice of Therapeutic Massage, 5th ed. Clifton Park, New York: Milady Cengage Learning,
    2010.</li>
  <li>Bell, J. “Massage Therapy Helps to Increase Range of Motion, Decrease Pain and Assist in Healing a Client with
    Low-Back Pain and Sciatica Symptoms.” Journal of Bodywork and Movement Therapies 12, no. 3 (2008): 289–291.</li>
  <li>Benjamin, P. J. Pearson’s Massage Therapy: Blending Art with Science. Upper Saddle River, New Jersey: Pearson
    Education, 2011.</li>
  <li>Benjamin, P. J. Tappan’s Handbook of Healing Massage Techniques, 5th ed. Upper Saddle River, New Jersey: Pearson
    Education, 2010.</li>
  <li>Biel, A. Trail Guide to the Body, 4th ed. Boulder, Colorado: Books of Discovery, 2010.</li>
  <li>Bob, C. Fire Up Your Communication Skills. Get People to Listen, Understand and Give You What You Want.
    Pleasanton, California: Code 3 Publishing, 2004.</li>
  <li>Bolton, R. People Skills: How to Assert Yourself, Listen to Others and Resolve Conflicts. New York, New York:
    Simon and Schuster, 1986.</li>
  <li>Bucci, C. Condition-Specific Massage Therapy. Baltimore, Maryland: Lippincott Williams & Wilkins, 2012.</li>
  <li>Burman, I. and S. Friedland. Touch Abilities: Essential Connections. Clifton Park, New York: Thomson Delmar
    Learning, 2006.</li>
  <li>Cael, C. Functional Anatomy: Musculoskeletal Anatomy, Kinesiology, and Palpation for Manual Therapists.
    Baltimore, Maryland: Lippincott Williams & Wilkins, 2011.</li>
  <li>Capellini, S. Massage Therapy Career Guide for Hands-On Success, 2nd ed. Albany, New York: Milady Thomson
    Learning, 2006.</li>
  <li>Chaitow, L. and J. DeLany. Clinical Application of Neuromuscular Techniques, Vol. 1: The Upper Body, 2nd ed. New
    York, New York: Churchill Livingstone Elsevier, 2008.</li>
  <li>Crane, J. et al. “Massage Therapy Attenuates Inflammatory Signaling After Exercise-Induced Muscle Damage.”
    Science Translational Medicine 4, no.119 (2012): 1–8.</li>
  <li>Delgado, E., J. Romero, and C. Escoda. “Myofascial Pain Syndrome with Trigger Points: A Literature Review.
    Medicina Oral Patologia Oral y Cirugia Bucal 14, no. 10 (October 1, 2009): e494–8.</li>
  <li>Denegar, C., E. Saliba, and S. Saliba. Therapeutic Modalities for Musculoskeletal Injuries, 2nd ed. Champaign,
    Illinois: Human Kinetics, 2006.</li>
  <li>DiMatteo, M. R. et al. “Predicting Patient Satisfaction from Physicians’ Nonverbal Communication Skills.”
    Medical Care 18, no. 4 (April 1980): 376–87.</li>
  <li>Elliott, R. and B. Burkett. “Massage Therapy as an Effective Treatment for Carpal Tunnel Syndrome.” Journal of
    Bodywork and Movement Therapies 17, no. 3 (July 2013): 332–338.</li>
  <li>Ezzo, J., et al. “Is Massage Useful in the Management of Diabetes? A Systematic Review.” Diabetes Spectrum 14,
    no. 4 (2001): 218–224.</li>
  <li>Field, T., et al. “Carpal Tunnel Syndrome Symptoms are Lessened Following Massage Therapy.” Journal of Bodywork
    and Movement Therapies 8, no. 1 (2004): 9–14.</li>
  <li>Field, T., et al. “Rheumatoid Arthritis in Upper Limbs Benefits from Moderate Pressure Massage Therapy.”
    Complementary Therapies in Clinical Practice 19, no. 2 (May 2013): 101–3.</li>
  <li>Foster, M. A. Therapeutic Kinesiology: Musculoskeletal Systems, Palpation, and Body Mechanics. Upper Saddle
    River, New Jersey: Pearson Education, 2013.</li>
  <li>Fritz, S. Mosby’s Essential Sciences for Therapeutic Massage: Anatomy, Physiology, Biomechanics, and Pathology,
    4th ed. Maryland Heights, Missouri: Elsevier Mosby, 2013.</li>
  <li>Goodwin, J. Touch & Movement: Palpation and Kinesiology for Massage Therapists. Clifton Park, New York: Milady
    Cengage Learning, 2013.</li>
  <li>Gould, H. J. Understanding Pain: What It Is, Why It Happens, and How It’s Managed. New York, New York: AAN Press
    (American Academy of Neurology), 2007.</li>
  <li>Hendrickson, T. Massage for Orthopedic Conditions. Baltimore, Maryland: Lippincott Williams & Wilkins, 2003.
  </li>
  <li>Juhan, D. Job’s Body: A Handbook for Bodyworkers, 3rd ed. Barrytown, New York: Barrytown/Station Hill Press,
    2003.</li>
  <li>Knudson, D. Fundamentals of Biomechanics, 2nd ed. New York, New York: Springer Media, 2007.</li>
  <li>Leicester, M. Teaching Critical Thinking Skills. New York, New York: Continuum International Publishing Group,
    2010.</li>
  <li>LeMoon, K. “Clinical Reasoning in Massage Therapy.” International Journal of Therapeutic Massage and Bodywork 1,
    no. 1 (2008): 12–18.</li>
  <li>Levangie, P. K. and C. C. Norkin. Joint Structure and Function: A Comprehensive Analysis, 5th ed. Philadelphia,
    Pennsylvania: F. A. Davis Company, 2011.</li>
  <li>Lowe, W. Orthopedic Assessment in Massage Therapy. Sisters, Oregon: Daviau Scott, 2006.</li>
  <li>Lowe, W. Orthopedic Massage: Theory and Technique, 2nd ed. Maryland Heights, Missouri: Elsevier Mosby, 2009.
  </li>
  <li>Lowe, W. Functional Assessment in Massage Therapy, 3rd ed. Bend, Oregon: Orthopedic Massage Education & Research
    Institute, 1997.</li>
  <li>Luchau, T. Advanced Myofascial Techniques, Vol. 1. Pencaitland, Scotland: Handspring Publishing, 2015.</li>
  <li>Magee, D. J. Orthopedic Physical Assessment, 5th ed. St. Louis, Missouri: Saunders Elsevier, 2008.</li>
  <li>Marieb, E. N. Essentials of Human Anatomy and Physiology, 10th ed. San Francisco, California: Benjamin Cummings
    Pearson Education, 2012.</li>
  <li>Martin, S. T. and M. Kessler. Neurologic Interventions for Physical Therapy, 2nd ed. St. Louis, Missouri:
    Saunders, 2006.</li>
  <li>McIntosh, N. The Educated Heart: Professional Boundaries for Massage Therapists and Bodyworkers, 3rd ed.
    Baltimore, Maryland: Lippincott Williams & Wilkins, 2010.</li>
  <li>McKenna, C. Powerful Communication Skills: How to Communicate with Confidence. New Jersey: The Career Press,
    1998.</li>
  <li>Milady. Anatomy & Physiology: Reference for Massage Therapists. Clifton Park, New York: Milady Cengage Learning,
    2013.</li>
  <li>Mitchell, A. and M. Cormack. The Therapeutic Relationship in Complementary Health Care. New York, New York:
    Churchill Livingstone, 1998.</li>
  <li>Moraska, A. and C. Chandler. “Changes in Clinical Parameters in Patients with Tension-Type Headache Following
    Massage Therapy: A Pilot Study.” Journal of Manual and Manipulative Therapy 16, no 2 (2008): 106–112.</li>
  <li>Moraska, A., et al. “Physiological Adjustments to Stress Measures Following Massage Therapy: A Review of the
    Literature.” Evidence-Based Complementary and Alternative Medicine 7, no. 4 (2010): 409–418.</li>
  <li>Moraska, A. “Therapist Education Impacts the Massage Effect on Post-Race Muscle Recovery.” Medicine & Science in
    Sports Exercise 39, no. 1 (January 2007): 34–7.</li>
  <li>Moraska, A. and C. Chandler. “Changes in Psychological Parameters in Patients with Tension-Type Headache
    Following Massage Therapy: A Pilot Study.” Journal of Manual and Manipulative Therapy 17, no. 2 (2009): 86–94.
  </li>
  <li>Moyer, C. A., et al. “Does Massage Therapy Reduce Cortisol? A Comprehensive Quantitative Review.” Journal of
    Bodywork and Movement Therapies 15, no. 1 (2011): 3–14.</li>
  <li>Muscolino, J. E. Kinesiology: The Skeletal System and Muscle Function, 2nd ed. Maryland Heights, Missouri:
    Elsevier Mosby, 2011.</li>
  <li> Muscolino, J. E. Know the Body: Muscle, Bone, and Palpation Essentials. Maryland Heights, Missouri: Elsevier
    Mosby, 2012.</li>
  <li>Myers, T. W. Anatomy Trains: Myofascial Meridians for Manual and Movement Therapists, 2nd ed. New York, New
    York: Churchill Livingstone, 2009.</li>
  <li>Nathan, B. Touch and Emotion in Manual Therapy. New York, New York: Churchill Livingstone, 1999.</li>
  <li>Neighbors, M. and R. Tannehill-Jones. Human Diseases, 3rd ed. Clifton Park, New York: Delmar Cengage Learning,
    2010.</li>
  <li>Nelson, D. The Mystery of Pain. Philadelphia, Pennsylvania: Singing Dragon, 2013.</li>
  <li>Noudeh, Y., N. Vatankhah, and H. Baradaran. “Reduction of Current Migraine Headache Pain Following Neck Massage
    and Spinal Manipulation.” International Journal of Therapeutic Massage & Bodywork 5, no. 1 (2012).</li>
  <li>Orey, M. and J. Prisk. Communication Skills Training. Alexandria, Virginia: American Society for Training and
    Development, 2004.</li>
  <li>Pagliarulo, M. A. Introduction to Physical Therapy, 4th ed. Maryland Heights, Missouri: Elsevier Mosby, 2011.
  </li>
  <li>Platzer, W., H. Leonhardt, and M. Frotscher. Color Atlas of Human Anatomy, Vol. 1: Locomotion System. New York,
    New York: Thieme, Inc., 2004.</li>
  <li>Porter, R. S. The Merck Manual of Diagnosis and Therapy, 19th ed. Whitehouse Station, New Jersey: Merck Sharpe &
    Dohme, 2011.</li>
  <li>Quinn, C., C. Chandler, and A. Moraska. “Massage Therapy and Frequency of Chronic Tension Headaches.” American
    Journal of Public Health 92, no. 10 (2002): 1657–1661.</li>
  <li>Rapaport, M., P. Schettler, and C. Bresee. “A Preliminary Study of the Effects of a Single Session of Swedish
    Massage on Hypothalamic-Pituitary-Adrenal and Immune Function in Normal Individuals.” Journal of Alternative and
    Complementary Medicine 16, no. 10(October 2010).</li>
  <li>Rapaport, M., P. Schettler, and C. Bresee. “A Preliminary Study of the Effects of Repeated Massage on
    Hypothalamic-Pituitary-Adrenal and Immune Function in Healthy Individuals: A Study of Mechanisms of Action and
    Dosage.” Journal of Alternative and Complementary Medicine 18, no. 8 (2012): 789–797.</li>
  <li>Rose, M. K. Comfort Touch: Massage for the Elderly and the Ill. Baltimore, Maryland: Lippincott Williams &
    Wilkins, 2010.</li>
  <li>Salvo, S. G. Massage Therapy: Principles and Practice, 4th ed. St. Louis, Missouri: Elsevier Saunders, 2012.
  </li>
  <li>Salvo, S. G. Mosby’s Pathology for Massage Therapists, 2nded. Maryland Heights, Missouri: Elsevier Mosby, 2009.
  </li>
  <li>Scheumann, D. W. The Balanced Body: A Guide to Deep Tissue and Neuromuscular Therapy, 3rd ed. Baltimore,
    Maryland: Lippincott Williams & Wilkins, 2007.</li>
  <li>Sefton, J. M., et al. “Therapeutic Massage of the Neck and Shoulders Produces Changes in Peripheral Blood Flow
    When Assessed With Dynamic Infrared Thermography.” Journal of Alternative and Complementary Medicine 16, no. 7
    (2010): 723–732.</li>
  <li>Sefton, J. M., et al. “Physiological and Clinical Changes After Therapeutic Massage of the Neck and Shoulders.”
    Manual Therapy 16, no. 5 (2011): 487–494.</li>
  <li>Sefton, J. M., C. Yarar, and J. W. Berry. “Six Weeks of Massage Therapy Produces Changes in Balance,
    Neurological and Cardiovascular Measures in Older Persons.” International Journal of Therapeutic Massage &
    Bodywork 5, no. 3 (2012): 28–40.</li>
  <li>Sherman, K., et al. “Randomized Trial of Therapeutic Massage for Chronic Neck Pain.” Clinical Journal of Pain
    25, no. 3 (2009): 233–238</li>
  <li>Simons, D. G., J. G. Travell, and L. S. Simons. Travell & Simons’ Myofascial Pain and Dysfunction: The Trigger
    Point Manual, 2nd ed. Baltimore, Maryland: Lippincott Williams & Wilkins, 1998.</li>
  <li>Sinclair, M. Pediatric Massage Therapy, 2nd ed. Baltimore, Maryland: Lippincott Williams & Wilkins, 2005.</li>
  <li>Sohnen-Moe, C. Business Mastery, 4th ed. Tucson, Arizona: Sohnen-Moe Associates, 2008.</li>
  <li>Thibodeau, G. A. and K. T. Patton. Structure & Function of the Body, 14th ed. Maryland Heights, Missouri:
    Elsevier Mosby, 2012.</li>
  <li>Thibodeau, G. A. and K. T. Patton. The Human Body in Health & Disease, 5th ed. Maryland Heights, Missouri:
    Elsevier Mosby, 2010.</li>
  <li>Thompson, D. L. Hands Heal Essentials: Documentation for Massage Therapists. Baltimore, Maryland: Lippincott
    Williams & Wilkins, 2006.</li>
  <li>Tsao, J. “Effectiveness of Massage Therapy for Chronic, Non-Malignant Pain: A Review.” Evidence-Based
    Complementary and Alternative Medicine 4, no. 2 (2007): 165–179</li>
  <li>Venes, D. Taber’s Cyclopedic Medical Dictionary, 21st ed. Philadelphia, Pennsylvania: F. A. Davis, 2009.</li>
  <li>Werner, R. A Massage Therapist’s Guide to Pathology, 4th ed. Baltimore, Maryland: Lippincott Williams & Wilkins,
    2012.</li>
  <li>Williams, A. Massage Mastery: From Student to Professional. Baltimore, Maryland: Lippincott Williams & Wilkins,
    2013.</li>
  <li>Wilson, J. and T. Best. “Common Overuse Tendon Problems: A Review and Recommendations for Treatment.” American
    Family Physician 72, no. 5 (2005): 811–818.</li>
  <li>Zuluaga, M., et al. Sports Physiotherapy: Applied Science and Practice. South Melbourne, New Zealand: Churchill
    Livingstone, 2008.</li>
</ul>