import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private _token: string;
  public Token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }

  public get Token(): string {
    return this._token;
  }

  public set Token(token: string) {
    this._token = token;
    this.Token$.next(token);
  }

  public get HasToken(): boolean {  
    return this._token != null;
  }

}
