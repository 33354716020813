import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { combineLatest, Observable, Subscription } from 'rxjs'
import { ExamCoachSubject } from '../_classes/exam-coach-subject'
import { ExamCoachTopic } from '../_classes/exam-coach-topic'
import { UserDataService } from '../_services/user-data.service';
import { first, map, switchMap, take } from 'rxjs/operators';
import { FireStoreStaticService } from '../_services/fire-store-static.service';
import { ExamQuestion } from '../_classes/exam-question';

@Component({
  selector: 'app-quiz-landing',
  templateUrl: './quiz-landing.component.html',
  styleUrls: ['./quiz-landing.component.scss']
})
export class QuizLandingComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public topic$: Observable<ExamCoachTopic>;
  public ExamCount$ = this.userData.GetExamCount();
  public generatingQuiz: boolean = false;
  public allQuestionsExhausted: boolean = false;

  constructor(private fssService: FireStoreStaticService, private userData: UserDataService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.topic$ = this.fssService.GetTopic(this.route.snapshot.params.subject, this.route.snapshot.params.topic);
    this._subscriptions.add(
      this.userData.GetActiveQuiz(this.route.snapshot.params.subject, this.route.snapshot.params.topic).pipe(take(1)).subscribe(quiz => {
        if (!quiz.NoQuizResultFound) {
          this.router.navigate(['subjects', this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'quiz', 'questions']);
        }
      })
    );

    this._subscriptions.add(combineLatest([this.fssService.GetContent<ExamQuestion>(this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'questions'),
    this.userData.GetUserQuestionCount(this.route.snapshot.params.subject, this.route.snapshot.params.topic)]).pipe(
      first(),
      map(([allQuestions, questionCount]) => {
        let questions = allQuestions.filter(q => (questionCount[q.FirestoreID] || 0) < 3);
        this.allQuestionsExhausted = questions.length == 0;
      })
    ).subscribe()
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  StartQuiz() {
    this.generatingQuiz = true;
    this._subscriptions.add(
      this.userData.StartQuiz(this.route.snapshot.params.subject, this.route.snapshot.params.topic).pipe(take(1)).subscribe(questions => {
        this.generatingQuiz = false;
        this.router.navigate(['subjects', this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'quiz', 'questions']);
      })
    );
  }

  RestoreQuestions() {
    this.userData.RestoreQuestions(this.route.snapshot.params.subject, this.route.snapshot.params.topic);
    setTimeout(() => this.allQuestionsExhausted = false, 750);
  }
}
