<app-subject-topic-header *ngIf="header" head="Practice Exams"></app-subject-topic-header>

<ng-container *ngIf="(ExamCount$ | async) as examCount; else loadingExams">
  <p>You currently have <span class="bright">{{examCount.available}}</span> more exams available
    ({{examCount.total}} total). As you finish an exam, a new practice exam will load. If you don't have any exams in
    the queue, or just
    want more, you can add 4 more practice exams at any time by passing 10 practice quizzes in any topic!</p>
  <ng-container *ngIf="examCount.quizzesNeeded >= 0">
    <p>Return to <a [routerLink]="['/subjects']">Study Subjects</a> to start your quizzing now - pass
      {{examCount.quizzesNeeded}} more quiz{{examCount.quizzesNeeded == 1 ? '' : 'zes'}} to earn 4 new exams!</p>
  </ng-container>

  <div class="text-center" *ngIf="(Exams$ | async) as exams">
    <ng-container *ngFor="let exam of exams; index as i">
      <app-exam-button [exam]="exam" [number]="i + 1"></app-exam-button>
    </ng-container>
    <ng-container *ngIf="examCount.available > 0 && CheckNoActiveExams(exams)">
      <app-exam-button [start]="true" [number]="exams.length + 1"></app-exam-button>
    </ng-container>
  </div>
</ng-container>

<ng-template #loadingExams>
  <app-loading description="load your exam data..."></app-loading>
</ng-template>