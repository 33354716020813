import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Exam } from '../_classes/exam';
import { UserDataService } from '../_services/user-data.service';

@Component({
  selector: 'app-practice-exams',
  templateUrl: './practice-exams.component.html',
  styleUrls: ['./practice-exams.component.scss']
})
export class PracticeExamsComponent implements OnInit {
  public ExamCount$ = this.userData.GetExamCount();
  public Exams$ = this.userData.Exams$;
  public header: boolean;

  constructor(public userData: UserDataService, private router: Router) {
    this.header = !router.url.includes('progress');
  }

  ngOnInit() {
  }

  CheckNoActiveExams(exams: Exam[]): boolean {
    return exams.find(e => e.State == Exam.ExamState.Active || e.State == Exam.ExamState.Paused) == null;
  }
}
