/* src/app/exam-button/exam-button.component.scss */
a {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0 1rem 1rem 0;
  box-shadow: 1px 1px 0px 0px #b5b5b5;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #f9f9f9));
  border-radius: 0.5rem;
  border: 1px solid #dcdcdc;
  text-align: center;
  text-decoration: none;
  color: #000;
}
a:hover {
  background: #e6e6e6;
}
a h3 {
  margin-bottom: 0.25rem;
}
a h4 {
  color: #444;
}
a h5.score {
  font-size: 2rem;
  line-height: 3rem;
}
a span {
  text-decoration: underline;
}
@media screen and (max-width: 780px) {
  a h3,
  h5 {
    font-size: 1rem !important;
  }
  h4 {
    font-size: 1rem !important;
  }
}
/*# sourceMappingURL=/exam-button.component.css.map */
