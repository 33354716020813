import { Pipe, type PipeTransform } from '@angular/core';
import { CultureService } from '../_services/culture.service';
import { CultureString } from '../_classes/culture-string';

@Pipe({
  name: 'appCulture',
  standalone: true,
})
export class CulturePipe implements PipeTransform {

  public constructor(private cultureService: CultureService) {}

  transform(value: CultureString, ...args: unknown[]): string {
    return value.Value(this.cultureService.PreferredCulture);
  }

}
