/* src/app/culture-picker/culture-picker.component.css */
:host {
  display: block;
}
span {
  cursor: pointer;
}
ul {
  background-color: #8accbf;
  list-style-type: none;
  padding: 0 .5rem;
  border-left: solid 2px #00a6b7;
  border-right: solid 2px #00a6b7;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  text-align: right;
}
ul li {
  border-bottom: solid 2px #00a6b7;
}
ul li fa-icon {
  margin-right: 5px;
}
ul li a {
  color: white;
}
ul li a:hover {
  text-decoration: underline;
}
a.preferred::before {
  content: ">";
  margin-right: .5rem;
}
/*# sourceMappingURL=/culture-picker.component.css.map */
