import { Component, Input, OnInit } from '@angular/core';
import { Exam } from '../_classes/exam';

@Component({
  selector: 'app-exam-timeline',
  templateUrl: './exam-timeline.component.html',
  styleUrls: ['./exam-timeline.component.scss']
})
export class ExamTimelineComponent implements OnInit {
  @Input() exam: Exam;

  constructor() { }

  ngOnInit(): void {
  }

}
