<ng-container *transloco="let t">
  <app-subject-topic-header *ngIf="!allTermsDisplay" [contentarea]="t('Header.Terminology')"></app-subject-topic-header>
  <app-subject-topic-header *ngIf="allTermsDisplay" [head]="t('Header.AllSubjects')" [subhead]="t('Header.AllTopics')"
    [contentarea]="t('Header.Terminology')">
  </app-subject-topic-header>

  <ng-container *ngIf="allTermsDisplay">
    <header class="text-center">
      <nav>
        <a class="{{filterLetter == letter ? 'orange' : 'teal'}} me-1" [routerLink]="null"
          *ngFor="let letter of abcArray" (click)="alphaSort(letter)">
          {{letter}}
        </a>
      </nav>
    </header>
  </ng-container>

  <section class="container" aria-live="polite">
    <h3 class="sr-only">{{ t('Terminology.SR.List') }}</h3>
    <p class="text-center">{{ t('Terminology.Instructions') }}.</p>
    <ng-container *ngIf="(terms$ | async) as terms; else loadingMessage">
      <div id="terminology-row" class="row justify-content-center">
        <div class="col-12 col-md-4 left term-col">
          <ng-container *ngFor="let term of terms.left; let i = index">
            <ng-container [ngTemplateOutlet]="termdisplay" [ngTemplateOutletContext]="{term:term, i:i, side:'left'}">
            </ng-container>
          </ng-container>
        </div>
        <div class="col-12 col-md-4 right">
          <ng-container *ngFor="let term of terms.right; let i = index">
            <ng-container [ngTemplateOutlet]="termdisplay" [ngTemplateOutletContext]="{term:term, i:i, side:'right'}">
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </section>

  <div *ngIf="!allTermsDisplay" class="text-center">
    <hr>
    <a [routerLink]="['/terms/all']">{{ t('Terminology.FullListLink') }}</a>
  </div>
</ng-container>

<ng-template #loadingMessage>
  <ng-container *transloco="let t">
    <app-loading [description]="t('Loading.Terminology')"></app-loading>
  </ng-container>
</ng-template>

<ng-template #termdisplay let-term="term" let-i="i" let-side="side">
  <ng-container *transloco="let t">
    <article class="mb-2">
      <h5>
        <a class="{{this.activeDefinition?.side == side && this.activeDefinition?.i==i ? 'maroon' : 'teal'}}"
          [routerLink]="null" (click)="showDefinition(this, side, i)">
          <fa-icon *ngIf="side == 'left' && this.activeDefinition?.side == side && this.activeDefinition?.i==i"
            icon="caret-left" class="maroon me-2 d-none d-md-inline"></fa-icon>
          <fa-icon *ngIf="this.activeDefinition?.side == side && this.activeDefinition?.i==i" icon="caret-down"
            class="maroon me-2 d-md-none"></fa-icon>
          <app-culture-string [content]="term.Term"></app-culture-string>
          <fa-icon *ngIf="side == 'right' && this.activeDefinition?.side == side && this.activeDefinition?.i==i"
            icon="caret-right" class="maroon ms-4 d-none d-md-inline"></fa-icon>
        </a>
      </h5>

      <div class="definition" tabindex="-1"
        [@slideInOut]="this.activeDefinition?.side == side && this.activeDefinition?.i==i ? 'in': 'out'">
        <h5>{{ t('Terminology.Definition') }}</h5>
        <p><app-culture-string [content]="term.Definition"></app-culture-string></p>
        <button class="d-flex justify-content-between mx-auto"
          (click)="playAudio($event, term.Pronunciation, term.Term)" [attr.aria-label]="t('Terminology.HearTitle')">
          {{ t('Terminology.Pronunciation') }}<fa-icon icon="volume-up" class="ms-2"></fa-icon>
        </button>
        <span *ngIf="errorTerms.includes(term.Term)">{{ t('Terminology.PronunciationUnavailable') }}</span>
        <a *ngIf="allTermsDisplay" [routerLink]="['/subjects', term.Subject, term.Topic]"
          class="d-block text-center mb-2">
          {{term.SubjectTopicName}}
        </a>
      </div>
    </article>
  </ng-container>
</ng-template>