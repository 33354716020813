<div ngbDropdown class="dropdown d-block d-lg-none">
  <div id="dropdownSearch" ngbDropdownToggle>
    <fa-icon class="d-block d-lg-none" icon="search"></fa-icon>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownSearch">
    <form>
      <input type="text" name="coursefilter-search" placeholder="Search course titles, instructors, and descriptions" />
    </form>
  </div>
</div>
