<ng-container *transloco="let t">
  <div class="container" id="login-holder">
    <div class="row text-center">

      <div class="col grey-box">
        <h2 class="mb-3">{{ t('Login.Button') }}</h2>

        <form [formGroup]="loginForm" (ngSubmit)="submitLogin()">
          <div class="form-group">
            <label for="user_email">{{ t('Login.Email') }}</label>
            <input type="email" class="form-control" name="user_email" placeholder="me@mysite.com"
              formControlName="user_email" ngbAutoFocus>
          </div>
          <div class="form-group">
            <label for="user_password">{{ t('Login.Password') }}</label>
            <input type="password" class="form-control" name="user_password" required formControlName="user_password">
          </div>
          <div class="form-group d-flex justify-content-center">
            <button type="submit" class="btn btn-primary"
              [disabled]='!loginForm.valid || loginInProgress'>{{loginInProgress ? t('Login.ButtonActive') : t('Login.Button')}}</button>
          </div>
          <ngb-alert *ngIf="!loginInProgress && loginError" [type]="'warning'" [dismissible]="false">{{loginError}}
          </ngb-alert>
          <div class="form-group text-center">
            <a class="d-inline-block mb-3" href="https://www.abmp.com/signup/ec-plus">
              {{ t('Login.NewUser') }}</a>
            <br />
            <a class="text-secondary" href="https://www.abmp.com/user/password" target="_blank">{{ t('Login.ForgotPassword') }}</a>
          </div>
        </form>
      </div>
      <div class="col-0 col-md-4"></div>
    </div>
  </div>
</ng-container>