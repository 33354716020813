/* src/app/quiz-landing/quiz-landing.component.scss */
:host > div {
  display: block;
  width: 60%;
  text-align: center;
}
@media (max-width: 767px) {
  :host > div {
    width: 100%;
  }
}
/*# sourceMappingURL=/quiz-landing.component.css.map */
