/* src/app/video-player/video-player.component.scss */
div {
  min-height: 25rem;
  min-width: 50%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  div {
    height: 12rem;
  }
}
/*# sourceMappingURL=/video-player.component.css.map */
