import { Injectable } from '@angular/core';
import { CultureString } from '../_classes/culture-string';
import { BehaviorSubject } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CultureService {
  private cultureNames: Map<string, string> = new Map<string, string>([['en', 'English'], ['es', 'Español'], ['xx', 'Translate']]);
  private cultures: Map<string, number> = new Map<string, number>();
  private preferredCulture: string = 'en';

  public PreferredCulture$: BehaviorSubject<string> = new BehaviorSubject<string>('en');
  public PreferredCultureName$: BehaviorSubject<string> = new BehaviorSubject<string>('English');

  constructor(private translocoService: TranslocoService, private userService: UserService) { 
    this.userService.UserPreferences$.subscribe(preferences => {
      if(preferences.has('language')) {
        this.SetPreferredCulture(preferences.get('language'));
      }
    });
  }

  public GetCultureString(data: {culture, text}[]): CultureString {
    return new CultureString(data);
  }

  public get AvailableCultures(): Map<string, string> {
    return this.cultureNames;
  }

  public SetPreferredCulture(culture: string): void {
    this.preferredCulture = culture;
    this.translocoService.setActiveLang(culture);
    this.PreferredCulture$.next(culture);
    this.PreferredCultureName$.next(this.AvailableCultures.get(culture));
    this.userService.SetPreference('language', culture);
  }

  public get PreferredCulture(): string {
    return this.preferredCulture;
  }

  public get PreferredCultureName(): string {
    return this.cultureNames.get(this.preferredCulture) ?? 'English';
  }
}
