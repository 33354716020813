<ng-container *transloco="let t">
<app-subject-topic-header [head]="t('Header.StudySubjects')" [text]="t('Header.Text.PickSubject')">
</app-subject-topic-header>
</ng-container>

<div class="d-flex flex-wrap justify-content-center justify-content-sm-start">
  <ng-container *ngIf="(data.OrderedSubjects$ | async) as subjects; else loadingMessage">
    <div *ngFor="let subject of subjects">
      <app-subject-topic-card [subject]="subject"></app-subject-topic-card>
    </div>
  </ng-container>
</div>


<ng-template #loadingMessage>
  <ng-container *transloco="let t">
  <app-loading [description]="t('Loading.StudySubjects')"></app-loading>
</ng-container>
</ng-template>