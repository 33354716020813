<ng-container *transloco="let t">
  <div id="header">
    <div id="toprow" class="d-flex justify-content-between pe-4 {{router.url == '/' ? 'home' : 'inside'}}">
      <div id="links" class="d-flex">
        <a id="abmplink" title="Open the ABMP.com homepage in a new window" href="https://www.abmp.com/members/home"
          target="_blank">
          <img class="d-md-none w-100" src="assets/img/abmp-diffuse-circle-logo.png">
          ABMP Homepage
        </a>
        <a id="homelink" title="Return to the ExamCoach homepage" [routerLink]="['/']">ExamCoach</a>
      </div>
      <ng-container *ngIf="(userService.User$ | async) as user">
        <div class="d-flex">
          <app-culture-picker></app-culture-picker>

          <a *ngIf="user.IsLoggedIn" id="togglesearch" title="Show search field" [routerLink]="null"
            (click)="Search.ToggleSearchVisibility()">
            <fa-icon icon="search"></fa-icon>
          </a>
          <div *ngIf="Search.ShowSearch" class="search">
            <input #searchstring type="text" placeholder="Search..." [value]="Search.SearchString"
              (keyup)="searchKey($event)" />
            <a id="runsearch" [routerLink]="null" (click)="RunSearch()">
              <fa-icon icon="search"></fa-icon>
            </a>
          </div>
          <div class="d-none d-md-block">
            <a *ngIf="!user.IsLoggedIn" [routerLink]="['login']">Login</a>
            <a [routerLink]="null" *ngIf="user.IsLoggedIn" title="{{user.ID}}" (click)="Logout();">{{ t('Header.Logout') }}
              {{user.Name?.first}}</a>
          </div>

          <div ngbDropdown class=" d-flex d-md-none">
            <div id="dropdownMenuButton" ngbDropdownToggle>
              <fa-icon style="font-size: 1.6rem;" icon="bars"></fa-icon>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton" ngbDropdownMenuPlaceemnt="bottom-left">
              <a class="dropdown-item" [routerLink]="['/']">Home</a>
              <a class="dropdown-item" [routerLink]="['/subjects']">Study Subjects</a>
              <a class="dropdown-item" [routerLink]="['/progress']">My Progress</a>
              <a class="dropdown-item" [routerLink]="['/exams']">Practice Exams</a>
              <hr />
              <a class="dropdown-item" [routerLink]="['/getstarted']">Getting Started</a>
              <a class="dropdown-item" [routerLink]="['/info']">Info & Support</a>
              <hr />
              <a class="dropdown-item" [routerLink]="['/info']">Access ABMP Five-Minute Muscles</a>
              <a class="dropdown-item" href="https://www.abmp.com/members/home">abmp.com</a>
              <ng-container *ngIf="(userService.User$ | async) as user">
                <hr />
                <a *ngIf="!user.IsLoggedIn" [routerLink]="['login']">Login</a>
                <a *ngIf="user.IsLoggedIn" (click)="Logout();" class="dropdown-item" style="color:black;">{{ t('Header.Log-out') }}</a>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <app-breadcrumb></app-breadcrumb>
    <ng-container *ngIf="(userService.User$ | async) as user">
      <app-exam-alert *ngIf="user.IsLoggedIn"></app-exam-alert>
    </ng-container>
  </div>
</ng-container>