/* src/app/footer/footer.component.scss */
#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  padding: 0 0 0 1rem;
  text-align: left;
  background: #fff;
  z-index: 12;
  font-size: 80%;
}
#footer > div {
  padding: 0.5rem;
}
#upgrade-footer {
  text-align: center;
  background-color: #bfdcef;
  border-radius: 0.5rem;
  z-index: 750;
}
a {
  text-decoration: none;
  color: #00a6b7;
}
@media screen and (max-width: 767px) {
  #footer {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #footer {
    font-size: 75%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  #upgrade-footer br {
    display: none;
  }
}
/*# sourceMappingURL=/footer.component.css.map */
