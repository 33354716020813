<ng-container *transloco="let t">
  <app-subject-topic-header [contentarea]="t('Header.Flashcards')"></app-subject-topic-header>

<div id="card-wrapper">
  <ng-container *ngIf="(Flashcard$ | async) as flashcard; else loadingMessage">
    <div *ngIf="largeImage" id="full-image">
      <img [src]="flashcard.Image" />
      <a [routerLink]="null" (click)="hideFullImage()">Close</a>
    </div>

    <header>
      <em *ngIf="hiddenCardCount < totalCardCount">{{ t('Flashcards.MasteredHide') }}</em>
      <p tabindex="0">{{ t('Flashcards.HideCountPrefix') }} {{hiddenCardCount}} / {{totalCardCount}} {{ t('Flashcards.HideCountSuffix') }}. <a
          *ngIf="hiddenCardCount > 0" [routerLink]="null" (click)="UnhideCards()">{{hiddenCardCount == 1 ? t('Flashcards.BringBackSingle') : 'Flashcards.BringBackMulti'}} back!</a></p>
      <em>{{ t('Flashcards.ClickTapHelp') }}</em>
    </header>

    <ng-container *ngIf="hiddenCardCount < totalCardCount">
      <div id="flashcard" (click)="flipped = !flipped" class="{{flipped ? 'flipped' : ''}}" aria>
        <a [routerLink]="null" (click)="flipped = !flipped;hideCard(flashcard.FirestoreID)" id="remove"
          [attr.aria-label]="t('Flashcards.HideCardLabel')">
          <fa-icon icon="check"></fa-icon>
        </a>
        <div id="flashcard-inner" aria-live="polite">
          <div id="card-front" tabindex="0">
            <h2>{{ t('Flashcards.CardHeader.Question') }}</h2>
            <div *ngIf="!flipped" class="card-content px-2 {{flashcard.Image ? 'text-md-left' : ''}}">
              <div *ngIf="flashcard.Image" class="card-image card-image-lg" (click)="showFullImage()">
                <img [src]="flashcard.Thumbnail" />
                <a *ngIf="flashcard.Image" [routerLink]="null" [attr.aria-label]="t('Flashcards.ViewLargerImage')">{{ t('Flashcards.ViewLargerImage') }}</a>
                <br class="img-break" />
              </div>
              <app-culture-string [content]="flashcard.Front"></app-culture-string>
              <div *ngIf="flashcard.Image" class="card-image card-image-sm">
                <img [src]="flashcard.Thumbnail" />
                <br class="img-break" />
              </div>
            </div>
            <a id="answer" [routerLink]="null">{{ t('Flashcards.CardHeader.Answer') }}</a>
          </div>
          <div *ngIf="flipped" id="card-back" tabindex="0">
            <h2>{{ t('Flashcards.CardHeader.Answer') }}</h2>
            <div class="card-content px-2">
              <div *ngIf="flashcard.Image" class="card-image card-image-lg" (click)="showFullImage()">
                <img [src]="flashcard.Thumbnail" />
                <a *ngIf="flashcard.Image" [routerLink]="null" [attr.aria-label]="t('Flashcards.ViewLargerImage')">{{ t('Flashcards.ViewLargerImage') }}</a>
                <br class="img-break" />
              </div>
              <app-culture-string [content]="flashcard.Back"></app-culture-string>
              <div *ngIf="flashcard.Image" class="card-image card-image-sm">
                <img [src]="flashcard.Thumbnail" />
                <br class="img-break" />
              </div>
              <div id="card-reminder">
                <strong>{{ t('Flashcards.QuestionReminder') }}:</strong> <app-culture-string [content]="flashcard.Front"></app-culture-string>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button (click)="flipped = !flipped" class="sr-only btn btn-info my-2 me-3">
        {{flipped ? t('Flashcards.FlipLink.Question') : t('Flashcards.FlipLink.Answer')}}
      </button>

        <a *ngIf="flashcard.Image" class="d-block d-md-none teal" [routerLink]="null" [attr.aria-label]="t('Flashcards.ViewLargerImage')" (click)="showFullImage()">{{ t('Flashcards.ViewLargerImage') }}</a>

      <button (click)="nextCard();" class="btn btn-info mt-3" id="nextbtn" [attr.aria-label]="t('Flashcards.NextCardLabel')">{{ t('Flashcards.NextCardLabel') }}
        <fa-icon icon="chevron-right"></fa-icon>
      </button>
    </ng-container>
  </ng-container>
</div>
</ng-container>


<ng-template #loadingMessage>
  <ng-container *transloco="let t">
  <app-loading [description]="t('Loading.Flashcard')"></app-loading>
</ng-container>
</ng-template>