/* src/app/exam-alert/exam-alert.component.scss */
div {
  background: red;
  padding: 0.5rem 0;
  color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  width: 25rem;
  margin-left: -13.5rem;
  text-align: center;
}
a:any-link {
  color: #fff;
  text-decoration: underline;
}
@media (max-width: 767px) {
  div {
    left: 11rem;
    margin-left: 0;
    width: 7rem;
    font-size: 0.85rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 922px) {
  div {
    width: 15rem;
    margin-left: -9rem;
  }
}
/*# sourceMappingURL=/exam-alert.component.css.map */
