import { Injectable } from '@angular/core';
import { Observable, of, from, combineLatest, ReplaySubject } from 'rxjs';
import { map, first, switchMap, take } from 'rxjs/operators';
import { ExamQuestion } from '../_classes/exam-question';
import { UserDetail } from '../_classes/user-detail';
import { ShufflePipe } from '../_pipes/shuffle.pipe';
import { Quiz } from '../_classes/quiz';
import { Exam } from '../_classes/exam';
import { QuizStats } from '../_classes/quiz-stats';
import { ExamTimeEvent } from '../_classes/exam-time-event';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private _user: UserDetail;
  public UserResolved$: ReplaySubject<boolean>;
  private _userData: ReplaySubject<any>;
  public Exams$: ReplaySubject<Exam[]>;
  public QuizStats$: ReplaySubject<QuizStats>;
  private _lastExamStartTime: number;


  constructor(private userService: UserService) {
    this.Exams$ = new ReplaySubject<Exam[]>(1);
    this.QuizStats$ = new ReplaySubject<QuizStats>(1);
    this.UserResolved$ = new ReplaySubject<boolean>(1);
    this._userData = new ReplaySubject<any>(1);
    this.userService.User$.subscribe(user => {
      this._user = user;
      //this.CheckUserRecordExists();
      this.UserResolved$.next(true);
    });

    this.UserResolved$.subscribe(done => {
      throw new Error("Not yet implemented");
      /*if (!this.UserDoc()) return;

      combineLatest([
        this._userData,
        this.fssService.SubjectMap$
      ]).pipe(
        map(([userData, subjectMap]) => {
          this.QuizStats$.next(new QuizStats(userData, subjectMap));
        })
      ).subscribe();

      combineLatest([
        this.UserDoc().collection('exams').valueChanges({ idField: 'FirestoreID' }),
        fssService.SubjectMap$
      ]).pipe(
        map(([fsExams, subjectMap]) => {
          fsExams = fsExams.filter(fsExam => fsExam.CorruptImport == null || fsExam.CorruptImport == false);
          if (fsExams.filter(fsExam => fsExam.State == 'ACTIVE').length > 1) {
            fsExams = fsExams.sort((a, b) => {
              if (a.Times[0].Time.toDate().getTime() > b.Times[0].Time.toDate().getTime()) return 1;
              if (a.Times[0].Time.toDate().getTime() < b.Times[0].Time.toDate().getTime()) return -1;
              return 0;
            });

            fsExams.filter(fsExam => fsExam.State == 'ACTIVE' && fsExam.LastUpdate == null && fsExam.Questions.filter(q => q.SelectedAnswer != null).length == 0).forEach(e => {
              fsDB.doc("users/" + this._user.ID + "/exams/" + e.FirestoreID).delete();
            })
            location.reload();
            return;
          }
          let exams: Exam[] = [];
          fsExams.forEach(fsExam => {
            let exam = Exam.FromFirestore(fsExam, subjectMap);
            if (exam.State != fsExam.State) {
              this.fsDB.doc('users/' + this._user.ID.toString() + '/exams/' + exam.FirestoreID).update(FirestoreConverter.Convert(exam));
            }
            exams.push(exam);
          });
          exams = exams.sort((a, b) => {
            if (a.StartTime > b.StartTime) return 1;
            if (a.StartTime < b.StartTime) return -1;
            return 0;
          });
          this.Exams$.next(exams);
        })
      ).subscribe();

      this.RefreshUserData();
      */
    })
  }

  private UserDoc(): any {
    throw new Error("Not yet implemented");
    /*
    if (!this._user.IsLoggedIn) return null;
    return this.fsDB.doc('users/' + this._user.ID.toString());
    */
  }

  public RefreshUserData() {throw new Error("Not yet implemented");
    /*
    if (!this.UserDoc()) return;
    this.UserDoc().get({source:'server'}).pipe(
      switchMap(userDoc => {
        let data = userDoc.data(); 
        if(!data.termsAgreement) {
          return this.UserDoc().get({source:'server'});
        } else {
          return of(userDoc);
        }
      })
    ).subscribe((userDoc: DocumentSnapshot<any>) => {
      this._userData.next(userDoc.data() || {});
    })*/
  }

  public CheckUserRecordExists() {
    throw new Error("Not yet implemented");
    /*
    if (!this._user.IsLoggedIn || this._user.UserRecordExists) return;
    let updateData = {
      LastLogin: new Date(),
      Cohorts: {}
    };
    this._user.CohortGroups.forEach((groups, campusId) => {
      updateData.Cohorts[campusId] = groups;
    })
    this.UserDoc().set(updateData, { merge: true });
    combineLatest([this.UserDoc().get(), this.fsDB.doc('users-archived/' + this._user.ID.toString()).get()]).subscribe(([user, archiveUser]) => {
      if (archiveUser.exists) {
        this.fbFunctions.UnArchiveUser().subscribe(() => {
          location.reload();
        });
      }
      if (user.exists) {
        this._user.UserRecordExists = true;
      } else {
        this.UserDoc().set({
          lastaccess: new Date()
        })
          .then(u => this._user.UserRecordExists = true);
      }
    });

    //Make sure campus & cohort docs exist
    if (this._user.IsCohortStudent) {
      this._user.CohortGroups.forEach((groups, campusId) => {
        this.fsDB.doc("campuses/" + campusId).set({}, { merge: true });
        groups.forEach(group => {
          if (group == '') return;
          this.fsDB.doc("campuses/" + campusId + '/groups/' + group).set({}, { merge: true });
        });
      });
    }
    */
  }

  public GetUsage(): Observable<any> {
    throw new Error("Not yet implemented");
    /*
    return combineLatest([this.fssService.OrderedSubjects, this._userData]).pipe(
      map(([subjects, userData]) => {
        let usage = userData?.usage || {};
        usage["_missing"] = userData?.usage == undefined;
        subjects.forEach(subject => {
          usage[subject.Alias] = usage[subject.Alias] || {};
          subject.OrderedTopics.forEach(topic => {
            usage[subject.Alias][topic.Alias] = usage[subject.Alias][topic.Alias] || {};
            ['cards', 'terms', 'quiz'].forEach(area => {
              if (usage[subject.Alias][topic.Alias][area] == undefined) {
                usage[subject.Alias][topic.Alias][area] = null;
              } else if (usage[subject.Alias][topic.Alias][area]?.seconds) {
                usage[subject.Alias][topic.Alias][area] = usage[subject.Alias][topic.Alias][area].seconds * 1000;
              }
            });
            usage[subject.Alias][topic.Alias].max = Math.max(usage[subject.Alias][topic.Alias].cards, usage[subject.Alias][topic.Alias].terms, usage[subject.Alias][topic.Alias].quiz);
          });
        });
        return usage;
      })
    );
    */
  }

  public TrackUsage(subject: string, topic: string, contentArea: 'cards' | 'quiz' | 'terms') {
    console.warn("Not yet implemented: TrackUsage");
    /*
    if (!this._user.IsLoggedIn) {
      throw new Error();
    }
    return;
    //Have to pull the user data since usage is a map
    this.UserDoc().get().subscribe(
      (userDoc) => {
        let userData = userDoc.data() || {};
        if (!userData.usage) userData.usage = {};
        if (!userData.usage[subject]) userData.usage[subject] = {};
        if (!userData.usage[subject][topic]) userData.usage[subject][topic] = {};
        userData.lastaccess = new Date();
        userData.usage[subject][topic][contentArea] = new Date();

        this.UserDoc().update(userData)
          .then(() => {
            this.RefreshUserData();
          });

        if (this._user.IsCohortStudent) {
          this._user.CohortGroups.forEach((groups, campusId) => {
            groups.forEach(group => {
              this.fsDB.doc("campuses/" + campusId + '/groups/' + group).set({ StudentUsage: { [this._user.ID]: new Date() } }, { merge: true });
            });
          });
        }
      }
    );
    */
  }

  public HideFlashCard(subject: string, topic: string, cardId: string) {
    throw new Error("Not yet implemented");
    /*
    this.UserDoc().update({ ['excludecards.' + subject + '.' + topic]: firebase.firestore.FieldValue.arrayUnion(cardId) })
      .then(() => this.RefreshUserData());
      */
  }

  public UnhideFlashCards(subject: string, topic: string) {
    throw new Error("Not yet implemented");
    /*if (subject == 'all') {
      this.UserDoc().update({ excludecards: [] }).then(() => this.RefreshUserData());
    } else {
      this.UserDoc().update({ ['excludecards.' + subject + '.' + topic]: [] }).then(() => this.RefreshUserData());
    }
      */
  }

  public GetHiddenFlashCardIds(subject: string, topic?: string): Observable<any | string[]> {
    console.warn("Not yet implemented GetHiddenFlashCardIds");
    return of([]);
    /*
    return this._userData.pipe(
      map(userData => {
        if (subject == 'all') {
          return userData.excludecards || {};
        } else {
          return userData.excludecards?.[subject]?.[topic] || [];
        }
      })
    )
      */
  }

  public GetActiveQuiz(subject: string, topic: string): Observable<Quiz> {
    throw new Error("Not yet implemented");
    /*
    return this.UserDoc().collection('quizzes',
      qRef => qRef.where('Active', '==', true).where('Subject', '==', subject).where('Topic', '==', topic))
      .valueChanges({ idField: 'FirestoreID' }).pipe(
        map(fsQuiz => {
          if (fsQuiz.length == 0) {
            return Quiz.NoResult();
          } else if (fsQuiz[0].Questions?.length == 0) {
            this.UserDoc().collection('quizzes').doc(fsQuiz[0].FirestoreID).delete();
            return Quiz.NoResult();
          }
          let quiz = Quiz.FromFirestore(fsQuiz[0]);
          return quiz;
        }));
        */
  }

  public GetUserQuestionCount(subject: string, topic?: string): Observable<any> {
    throw new Error("Not yet implemented");
    /*
    return this._userData.pipe(
      map(userData => {
        if (subject == 'all') {
          return userData.questioncounts || {};
        } else {
          return userData.questioncounts?.[subject]?.[topic] || {};
        }
      })
    )
      */
  }

  public RestoreQuestions(subject: string, topic: string) {
    throw new Error("Not yet implemented");
    /*
    this.GetUserQuestionCount(subject, topic).subscribe(questionCounts => {
      let resetCounts = {};
      Object.keys(questionCounts).forEach(qId => {
        resetCounts[qId] = questionCounts[qId] >= 3 ? 0 : questionCounts[qId];
      });
      this.UserDoc()?.update({ ['questioncounts.' + subject + '.' + topic]: resetCounts })
        .then(() => this.RefreshUserData());
    });
    */
  }

  public StartQuiz(subject: string, topic: string): Observable<Quiz> {
    throw new Error("Not yet implemented");
    /*let userDoc = this.UserDoc();
    return combineLatest([this.fssService.GetContent<ExamQuestion>(subject, topic, 'questions'), this.GetUserQuestionCount(subject, topic)]).pipe(
      first(),
      switchMap(([allQuestions, questionCount]) => {
        let questions = allQuestions.filter(q => (questionCount[q.FirestoreID] || 0) < 3);
        if (questions.length < 0) { questions = allQuestions; }
        questions = new ShufflePipe().transform(questions).slice(0, 10);

        let quiz = Quiz.New(subject, topic, questions);
        return this.fsDB.collection('users').doc(this._user.ID.toString()).collection('quizzes').add(
          FirestoreConverter.Convert(quiz)
        ).then(() => {
          this.TrackUsage(subject, topic, 'quiz');
          return quiz;
        });
      })
    );
    */
  }

  public AnswerQuiz(quiz: Quiz): Observable<Quiz> {
    throw new Error("Not yet implemented");
    /*
    return of(quiz.Active).pipe(
      switchMap(activeQuiz => {
        if (activeQuiz) {
          return of(null);
        } else {
          return this.GetUserQuestionCount(quiz.Subject, quiz.Topic).pipe(
            map(questionCount => {
              quiz.Questions.forEach(q => {
                if (q.IsCorrect(q.SelectedAnswer)) {
                  questionCount[q.FirestoreID] = !questionCount[q.FirestoreID] ? 1 : questionCount[q.FirestoreID] + 1;
                }
              });
              this.UserDoc().update({ ['questioncounts.' + quiz.Subject + '.' + quiz.Topic]: questionCount });
            }),
            switchMap(() => { return this._userData; })
          )
        }
      }),
      switchMap(userData => {
        let batch = this.fsDB.firestore.batch();
        batch.update(this.fsDB.doc('users/' + this._user.ID.toString() + '/quizzes/' + quiz.FirestoreID).ref, FirestoreConverter.Convert(quiz));
        if (!quiz.Active) {
          let quizCounter: any = { ['quizzes.Taken']: this._Increment, ['quizzes.BySubject.' + quiz.Subject + '.' + quiz.Topic + '.Taken']: this._Increment };
          if (quiz.Score >= 70) {
            quizCounter['quizzes.Passed'] = this._Increment;
            quizCounter['quizzes.BySubject.' + quiz.Subject + '.' + quiz.Topic + '.Passed'] = this._Increment;
          }
          batch.update(this.UserDoc().ref, quizCounter);
          if (this._user.IsCohortStudent) {
            let quizTopics = [];
            if (userData.quizzes && userData.quizzes.BySubject) {
              Object.keys(userData.quizzes.BySubject).forEach(subject => {
                if (subject == "Passed" || subject == "Taken") return;
                Object.keys(userData.quizzes.BySubject[subject]).forEach(topic => {
                  if (topic == "Passed" || topic == "Taken") return;
                  if (quizTopics.includes(topic)) return;
                  quizTopics.push(topic);
                });
              });
            }
            quizCounter['quizzes.StudentCounts.' + this._user.ID + '.Taken'] = this._Increment;
            if (quiz.Score >= 70) {
              quizCounter['quizzes.StudentCounts.' + this._user.ID + '.Passed'] = this._Increment;
            }
            quizCounter['quizzes.StudentTopicCounts.' + this._user.ID] = quizTopics.includes(quiz.Topic) ? quizTopics.length : quizTopics.length + 1;
            this._user.CohortGroups.forEach((groups, campusId) => {
              batch.update(this.fsDB.doc("campuses/" + campusId).ref, quizCounter);
              groups.forEach(groupName => {
                batch.update(this.fsDB.doc("campuses/" + campusId + "/groups/" + groupName).ref, quizCounter);
              });
            })
          }
        }
        return from(batch.commit()).pipe(map(fs => {
          if (!quiz.Active) {
            this.RefreshUserData();
          }
          return quiz;
        }));
      })
    );
    */
  }

  public GetLatestQuiz(subject: string, topic: string): Observable<Quiz> {
    throw new Error("Not yet implemented");
    /*
    return this.UserDoc().collection('quizzes',
      qRef => qRef.where('Subject', '==', subject).where('Topic', '==', topic).orderBy("Completed", "desc").limit(1))
      .valueChanges({ idField: 'FirestoreID' }).pipe(
        map(fsQuiz => {
          if (fsQuiz.length == 0) {
            return Quiz.NoResult();
          }
          let quiz = Quiz.FromFirestore(fsQuiz[0]);
          return quiz;
        }));
        */
  }

  public GetPassedQuizCount(): Observable<number> {
    throw new Error("Not yet implemented");
    /*
    return this._userData.pipe(
      map(userData => {
        return userData.quizzes?.Passed || 0;
      })
    )
      */
  }

  public GetExamCount(): Observable<{ total: number, available: number, quizzesNeeded: number }> {
    throw new Error("Not yet implemented");
    /*
    return combineLatest([this.GetPassedQuizCount(), this.Exams$]).pipe(
      map(([passedCount, exams]) => {
        let existingExams = exams.length;
        let earnedExams = Math.floor(passedCount / 10) * 4; //4 exams for every 10 passed quizzes
        let allottedExams = 4 + earnedExams; //4 to start + earned
        while (allottedExams < existingExams) {
          allottedExams += 4; //Bump up to the next 4x level
        }
        let available = allottedExams - existingExams;
        let quizzesNeeded = 10 - passedCount % 10;
        return { total: allottedExams, available: available, quizzesNeeded: quizzesNeeded };
      })
    );
    */
  }

  public StartExam(): Observable<Exam> {
    throw new Error("Not yet implemented");
    /*
    //Don't start an exam if there's one within 60 seconds.
    if (this._lastExamStartTime && new Date().getTime() - this._lastExamStartTime < 60000) {
      return of(null);
    }
    this._lastExamStartTime = new Date().getTime();

    return this.fssService.OrderedSubjects.pipe(
      switchMap(async subjects => {
        let topicQuestions$ = [];
        await Promise.all(subjects.map(async subject => {
          await Promise.all(subject.OrderedTopics.map(topic => {
            if (topic.NonMBLEX || topic.ExamWeight == 0) return;
            topicQuestions$.push(this.fssService.GetExamQuestions(subject.Alias, topic.Alias, topic.ExamWeight));
          }))
        }));
        return topicQuestions$;
      }),
      switchMap(questions$ => {
        return combineLatest(questions$);
      }),
      map(questionsByTopic => {
        let examQuestions = [];
        let topicsByWeight = {};
        questionsByTopic.forEach((topicInfo: { weight: number, questions: ExamQuestion[] }) => {
          if (topicInfo.weight == Math.ceil(topicInfo.weight)) {
            examQuestions.push(...topicInfo.questions);
          } else {
            if (!topicsByWeight[topicInfo.weight]) topicsByWeight[topicInfo.weight] = [];
            topicsByWeight[topicInfo.weight].push(topicInfo);
          }
        });
        let topicInfo;
        Object.keys(topicsByWeight).forEach(weight => {
          let questionCount = topicsByWeight[weight].length / (1 / parseFloat(weight));
          for (let q = 0; q < questionCount; q++) {
            topicInfo = topicsByWeight[weight].splice(Math.floor(Math.random() * topicsByWeight[weight].length), 1);
            examQuestions.push(topicInfo[0].questions[0]);
          }
        });
        for (let i = examQuestions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * i)
          const temp = examQuestions[i]
          examQuestions[i] = examQuestions[j]
          examQuestions[j] = temp
        }
        return Exam.Start(examQuestions);
      }),
      switchMap(exam => {
        return this.UserDoc().collection('exams').add(FirestoreConverter.Convert(exam)).then(() => {
          this.RefreshUserData();
          return exam;
        });
      })
    );*/
  }

  public AnswerExam(exam: Exam): Observable<Exam> {
    throw new Error("Not yet implemented");
    /*
    return of(exam).pipe(
      switchMap(exam => {
        if (exam.ReadyToComplete) {
          return combineLatest([of(exam), this._userData]);
        } else {
          return combineLatest([of(exam), of(null)]);
        }
      }),
      map(([exam, userData]) => {
        let batch = this.fsDB.firestore.batch();
        if (exam.ReadyToComplete) {
          exam.Complete();
        }
        batch.update(this.fsDB.doc('users/' + this._user.ID.toString() + '/exams/' + exam.FirestoreID).ref, FirestoreConverter.Convert(exam));
        if (exam.ReadyToComplete) {
          let examScores = userData.exams?.Scores || [];
          examScores.push(exam.Score);
          let studentExamData: any = { 'exams.Taken': this._Increment, 'exams.Scores': examScores };
          if (exam.Score >= 70) {
            studentExamData['exams.Passed'] = this._Increment;
          }
          batch.update(this.UserDoc().ref, studentExamData);
          if (this._user.IsCohortStudent) {
            let cohortExamData = { 'exams.Taken': this._Increment, ['exams.StudentScores.' + this._user.ID.toString()]: { Date: new Date(), Score: exam.Score } };
            if (exam.Score >= 70) {
              cohortExamData['exams.Passed'] = this._Increment;
            }
            this._user.CohortGroups.forEach((groups, campusId) => {
              batch.update(this.fsDB.doc("campuses/" + campusId).ref, cohortExamData);
              groups.forEach(groupName => {
                batch.update(this.fsDB.doc("campuses/" + campusId + "/groups/" + groupName).ref, cohortExamData);
              });
            })
          }
        }
        return batch;
      }),
      switchMap((batch: firebase.firestore.WriteBatch) => {
        return from(batch.commit()).pipe(map(fs => {
          this.RefreshUserData();
          return exam;
        }))
      })
    );*/
  }

  public ExpireExam() {
    throw new Error("Not yet implemented");
    this.Exams$.pipe(
      take(1),
      map(exams => {
        let exam = exams.find(exam => exam.State == Exam.ExamState.Active && exam.SecondsRemaining <= 0);
        if (!exam) return;
        exam.Expire();
        //let batch = this.fsDB.firestore.batch();
        //batch.update(this.fsDB.doc('users/' + this._user.ID.toString() + '/exams/' + exam.FirestoreID).ref, FirestoreConverter.Convert(exam));
        if (this._user.IsCohortStudent) {
          //let examCounter: any = { 'exams': { Taken: this._Increment } };
          if (exam.Score >= 70) {
            //examCounter.Passed = this._Increment;
          }
          this._user.CohortGroups.forEach((groups, campusId) => {
            //batch.update(this.fsDB.doc("campuses/" + campusId).ref, examCounter);
            groups.forEach(groupName => {
              //batch.update(this.fsDB.doc("campuses/" + campusId + "/groups/" + groupName).ref, examCounter);
            });
          })
        }
        //batch.commit();
      })
    ).subscribe(() => {
      this.RefreshUserData();
    });
  }

  public AddExamViewEvent(event: ExamTimeEvent.EventType, destinationUrl?: string) {
    console.log("Not yet implemented: AddExamViewEvent");
    this.Exams$.pipe(
      take(1),
      map(exams => {
        let exam = exams.find(exam => exam.State == Exam.ExamState.Active);
        if (!exam) return;
        exam.AddViewEvent(event, destinationUrl);
        //this.fsDB.doc('users/' + this._user.ID.toString() + '/exams/' + exam.FirestoreID).update(FirestoreConverter.Convert(exam));
      })
    ).subscribe(() => {
    });
  }

  public PauseExam() {
    throw new Error("Not yet implemented");
    this.Exams$.pipe(
      take(1),
      map(exams => {
        let exam = exams.find(exam => exam.State == Exam.ExamState.Active);
        if (!exam) return;
        exam.Pause();
        //this.fsDB.doc('users/' + this._user.ID.toString() + '/exams/' + exam.FirestoreID).update(FirestoreConverter.Convert(exam));
      })
    ).subscribe(() => {
    });
  }

  public ResumeExam() {
    throw new Error("Not yet implemented");
    this.Exams$.pipe(
      take(1),
      map(
        exams => {
          let exam = exams.find(exam => exam.State == Exam.ExamState.Paused);
          if (!exam) return;
          exam.Resume();
          //this.fsDB.doc('users/' + this._user.ID.toString() + '/exams/' + exam.FirestoreID).update(FirestoreConverter.Convert(exam));
        })
    ).subscribe(() => {
    });
  }

  public LastInterstitialAge$(): Observable<number> {
    throw new Error("Not yet implemented");
    return this._userData.pipe(
      first(),
      map(userData => {
        return ((new Date().getTime() - (userData.LastInterstitial?.toDate() || new Date(1970, 1, 1)).getTime()) / 1000)
      })
    );
  }

  public UpdateInterstitialShown() {
    throw new Error("Not yet implemented");
    this.UserDoc().set({ LastInterstitial: new Date() }, { merge: true })
      .then(() => this.RefreshUserData());
  }
}
