<app-subject-topic-header head="My Activity"></app-subject-topic-header>
<div class="text-center">
  <ng-container *ngIf="(userService.User$ | async) as user">
    <h3>Logged in as {{user.Name.first}}</h3>
    <p *ngIf="user.ECExpirationDate != 'Invalid Date'">Your ABMP Exam Coach subscription is valid through
      {{user.ECExpirationDate}}</p>
      
    <div class="d-flex justify-content-around justify-content-md-center">
      <a class="btn btn-info me-md-4" [routerLink]="['/progress']">My Activity</a>
      <a class="btn btn-info" [routerLink]="['exams']">My Exams</a>
    </div>

    <div class="text-left">
      <router-outlet></router-outlet>
    </div>

    <p *ngIf="user.IsCohortStudent" class="font-italic text-light">Cohort reporting for {{user.ID}}<br />
      <span *ngFor="let cohort of (user.CohortGroups | keyvalue)">
        {{cohort.key}}: {{cohort.value}}<br />
      </span>
    </p>
  </ng-container>
</div>