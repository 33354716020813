<div *ngIf="router.url != '/'" id="breadcrumb-holder" class="row">
  <ol class="breadcrumb">
    <li *ngIf="breadcrumbs.length == 0">
      <span class="disable">Loading...<fa-icon icon="spinner" [animation]="'spin'" class="ms-2"></fa-icon></span>
    </li>
    <li *ngFor="let breadcrumb of breadcrumbs; index as i">
      <a [routerLink]="breadcrumb.url" routerLinkActive="router-link-active"
        *ngIf="breadcrumb.url">{{ breadcrumb.label }}</a>
      <span class="{{!breadcrumb.url ? 'disable':''}}" *ngIf="!breadcrumb.url">{{ breadcrumb.label }}</span>
    </li>
  </ol>
</div>