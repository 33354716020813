/* src/app/subject-topic-header/subject-topic-header.component.scss */
:host {
  display: block;
  margin-top: -6rem;
  margin-bottom: 1rem;
}
h1 {
  font-size: 2.5rem;
  font-family: "Arvo", cursive;
  color: #aa0c00;
  font-weight: bold;
}
h1 span {
  font-size: 1.5rem;
  font-family: "Arvo", serif;
  color: #000;
}
h2 {
  font-size: 2rem;
  font-family: "Arvo", serif;
  color: #00a6b7;
}
h2 span {
  font-size: 1rem;
  font-family: "Arvo", serif;
}
h3 {
  margin-top: 1.5rem;
  margin-left: 2rem;
  font-size: 3rem;
  color: #c8c8c8;
}
h4 {
  font-family: "Arvo", serif;
  color: #000;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  :host {
    margin-top: -3rem;
  }
}
@media (max-width: 767px) {
  :host {
    margin-top: -1rem;
  }
  :host > div {
    flex-direction: column;
  }
  h1 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1rem;
  }
  h3 {
    margin: 0;
    font-size: 1.2rem;
  }
}
/*# sourceMappingURL=/subject-topic-header.component.css.map */
