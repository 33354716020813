import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserDataService } from '../_services/user-data.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, of, Subscription } from 'rxjs';
import { ExamCoachTopic } from '../_classes/exam-coach-topic';
import { ExamQuestion } from '../_classes/exam-question';
import { first, last, take } from 'rxjs/operators';
import { Quiz } from '../_classes/quiz';
import { FireStoreInterstitialsService } from '../_services/fire-store-interstitials.service';

@Component({
  selector: 'app-quiz-questions',
  templateUrl: './quiz-questions.component.html',
  styleUrls: ['./quiz-questions.component.scss']
})
export class QuizQuestionsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public letters = 'ABCDEF'.split('');
  public ActiveQuestion$: ReplaySubject<ExamQuestion> = new ReplaySubject<ExamQuestion>(1);
  private ActiveQuiz: Quiz;
  public questionNumber: number;
  public clickedAnswers: string[] = [];

  message = "";
  showNextButton: boolean;


  constructor(private route: ActivatedRoute, private userData: UserDataService, private router: Router, private interstitialSvc: FireStoreInterstitialsService) { }

  ngOnInit() {
    this._subscriptions.add(this.userData.GetActiveQuiz(this.route.snapshot.params.subject, this.route.snapshot.params.topic).pipe(take(1)).subscribe(quiz => {
      if(quiz.Questions.length == 0) {
        this.router.navigate(['subjects', this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'quiz']);
      }
      if (!quiz.NoQuizResultFound && quiz.NextQuestion() != null) {
        this.ActiveQuiz = quiz;
        this.ShowNextQuestion();
      } else {
        this.router.navigate(['subjects', this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'quiz']);
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ShowNextQuestion() {
    if (this.ActiveQuiz.Active === false) {
      this.router.navigate(['subjects', this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'quiz', 'review']);
    }
    this.showNextButton = false;
    this.clickedAnswers = [];
    this.questionNumber = this.ActiveQuiz.QuestionNumber;
    this.ActiveQuestion$.next(this.ActiveQuiz.NextQuestion());
    document.getElementById('question-number')?.focus();
  }

  AnswerQuestion(questionId: string, answer: string) {
    if (this.showNextButton) return;
    this.clickedAnswers.push(answer);
    this.showNextButton = this.ActiveQuiz.Answer(questionId, answer);
    if (this.clickedAnswers.length == 1) {
      this._subscriptions.add(this.userData.AnswerQuiz(this.ActiveQuiz).pipe(take(1)).subscribe(quiz => {
        this.ActiveQuiz = quiz;
      }));
    }
  }
}
