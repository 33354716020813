import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../_classes/app-settings';
import { FlashCard } from '../_classes/flash-card';
import { TerminologyTerm } from '../_classes/terminology-term';
import { FireStoreStaticService } from '../_services/fire-store-static.service';
import { SearchService } from '../_services/search.service';
import { CultureService } from '../_services/culture.service';
import { CultureString } from '../_classes/culture-string';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  @ViewChild('searchstring') searchInput: ElementRef;
  public Flashcards$: Observable<FlashCard[]>;
  public Terms$: Observable<TerminologyTerm[]>;
  audio = new Audio;
  public searchActive: boolean;
  public cardsOnly:boolean;
  public termsOnly:boolean;
  public viewAll:boolean;

  constructor(private appSettings: AppSettings, public Search: SearchService, private fssService: FireStoreStaticService, private cultureService: CultureService) { }

  ngOnInit(): void {
    this.RunSearch();
    this.viewAll = true;
  }

//Toggler:
  seeCards(){

this.cardsOnly = true;
this.termsOnly = false;
this.viewAll = false;
  }
  seeTerms(){
    this.termsOnly = true;
    this.cardsOnly = false;
    this.viewAll = false;
  }
  seeAll(){
    this.viewAll = true;
    this.cardsOnly = false;
    this.termsOnly = false;
  }
  //end Toggler

  searchKey(event: any) {
    this.Search.SetSearchString(this.searchInput.nativeElement.value);
    switch (event.key) {
      case "Enter":
        this.RunSearch();
        this.searchActive = true;
        break;
      case "Escape":
        this.Search.SetSearchString("");
        break;
    }
  }
  playAudio(evt: Event, url: string) {
    if(!url.endsWith('.mp3')) url += '.mp3';
    this.audio.src = this.appSettings.s3BaseUrl + 'pronunciations/' + url;
    this.audio.load();
    this.audio.play();
  }
  
  RunSearch() {
    if(this.Search.SearchString.trim().length == 0) return;
    let searchString = this.Search.SearchString.toLowerCase();
    this.Terms$ = this.fssService.GetContent<TerminologyTerm>(null, null, "terms").pipe(
      map(allTerms => {
        return allTerms.filter(term => this.CultureStringAsString(term.Term).toLowerCase().includes(searchString) || this.CultureStringAsString(term.Definition).toLowerCase().includes(searchString))
      })
    );

    this.Flashcards$ = this.fssService.GetContent<FlashCard>(null, null, "flashcards").pipe(
      map(allCards => {
        return allCards.filter(card => this.CultureStringAsString(card.Front).toLowerCase().includes(searchString) || this.CultureStringAsString(card.Back).toLowerCase().includes(searchString));
      })
    );
  }

  private CultureStringAsString(obj: CultureString | string): string {
    if (obj instanceof CultureString) {
      return obj.Value(this.cultureService.PreferredCulture);
    } else {
      return obj;
    }
  }

}
