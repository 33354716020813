import { CultureString } from "./culture-string";
import { ECTopic } from "./ec-topic";

export class TerminologyTerm {
    private firstLetter: Map<string, string> = new Map<string, string>();
    public get ObjectName(): string { return 'TerminologyTerm'; }
    public ID: number;
    public Term: CultureString;
    public Definition: CultureString;
    public Pronunciation: string;
    public Subject: string;
    public Topic: string;
    public SubjectTopicName: string;

    public constructor(fsSource: any, topic: ECTopic) {
        this.ID = fsSource.id
        this.Term = new CultureString(fsSource.term);
        this.Definition = new CultureString(fsSource.definition);
        this.Pronunciation = fsSource.pronunciation;
        this.Term.Cultures.forEach(culture => {
            this.firstLetter.set(culture, this.Term.Value(culture).replace(/[\W_]+/g, "").toUpperCase().substring(0, 1));
        });

        this.Subject = topic.Subject.Alias;
        this.Topic = topic.Alias;

        if (fsSource.SubjectTopicName) {
            this.SubjectTopicName = fsSource.SubjectTopicName;
        }
    }

    public FirstLetter(culture: string): string { 
        return this.firstLetter.get(culture);
    }
    
}
