import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetail } from '../_classes/user-detail';
import { FirebaseAuthService } from '../_services/firebase-auth.service';
import { UserService } from '../_services/user.service';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  public User$: Observable<UserDetail> = this.userService.User$;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

}
