<ng-container *transloco="let t">
  <app-subject-topic-header [text]="t('TopicDetail.Header.PickMethod')"></app-subject-topic-header>
<div id="topic-row" class="d-flex flex-row">
  <ng-container *ngIf="(topic$ | async) as topic">
    <div class="d-flex flex-column">
      <img src="assets/img/subjects/{{topic.Subject.Alias}}/{{topic.Alias}}.png" />
    </div>
    <div class="d-flex flex-column ms-3">
      <a [routerLink]="'./terminology'">{{ t('TopicDetail.Button.Terminology') }}</a>
      <a [routerLink]="['./flashcards']">{{ t('TopicDetail.Button.FlashCards') }}</a>
      <a [routerLink]="activeQuiz ? ['quiz', 'questions'] : ['quiz']">{{ t('TopicDetail.Button.TakeQuiz') }}</a>
    </div>
  </ng-container>
</div>
</ng-container>
