import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Exam } from '../_classes/exam';
import { UserDataService } from '../_services/user-data.service';
import { SlideInOutAnimation } from '../animations'
import { FireStoreStaticService } from '../_services/fire-store-static.service';
import { ExamQuestion } from '../_classes/exam-question';
import { FireStoreInterstitialsService } from '../_services/fire-store-interstitials.service';

@Component({
  selector: 'app-exam-review',
  templateUrl: './exam-review.component.html',
  styleUrls: ['./exam-review.component.scss'],
  animations: [SlideInOutAnimation]
})
export class ExamReviewComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public Exam$: Observable<Exam>;
  public examNumber: number;
  public activateInterstitial: boolean;
  public areaBreakdown: Map<string, {
    Questions: ExamQuestion[], QuestionCount: number, Correct: number, Percent: number,
    Topics: Map<string, { Questions: ExamQuestion[], QuestionCount: number, Correct: number, Percent: number }>
  }>;
  public visibleSubjects: string[] = [];
  public visibleTopics: string[] = [];
  public reviewView: 'subjects' | 'timeline' = 'subjects';

  constructor(public fssService: FireStoreStaticService, private userData: UserDataService, private route: ActivatedRoute, private interstitialService: FireStoreInterstitialsService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.examNumber = this.route.snapshot.params.examnumber;

    this._subscriptions.add(this.interstitialService.ShouldShow$('examcomplete').pipe(take(1)).subscribe(shouldShow => {
      this.activateInterstitial = shouldShow;
    }));

    this.Exam$ = combineLatest([this.userData.Exams$, this.fssService.SubjectMap$]).pipe(
      map(([exams, subjectMap]) => {
        let reviewedExam = exams[this.examNumber - 1];

        this.areaBreakdown = new Map();
        subjectMap.forEach(subject => {
          this.areaBreakdown.set(subject.Alias, { Questions: [], QuestionCount: 0, Correct: 0, Percent: 0, Topics: new Map() });
          subject.Topics.forEach(topic => {
            this.areaBreakdown.get(subject.Alias).Topics.set(topic.Alias, { Questions: [], QuestionCount: 0, Correct: 0, Percent: 0 });
          });
        });
        reviewedExam.Questions.forEach(question => {
          this.areaBreakdown.get(question.Subject).Questions.push(question);
          this.areaBreakdown.get(question.Subject).QuestionCount += 1;
          this.areaBreakdown.get(question.Subject).Correct += question.IsCorrect() ? 1 : 0;
          this.areaBreakdown.get(question.Subject).Percent = Math.round(this.areaBreakdown.get(question.Subject).Correct / this.areaBreakdown.get(question.Subject).QuestionCount * 100);

          this.areaBreakdown.get(question.Subject).Topics.get(question.Topic).Questions.push(question);
          this.areaBreakdown.get(question.Subject).Topics.get(question.Topic).QuestionCount += 1;
          this.areaBreakdown.get(question.Subject).Topics.get(question.Topic).Correct += question.IsCorrect() ? 1 : 0;
          this.areaBreakdown.get(question.Subject).Topics.get(question.Topic).Percent = Math.round(this.areaBreakdown.get(question.Subject).Topics.get(question.Topic).Correct / this.areaBreakdown.get(question.Subject).Topics.get(question.Topic).QuestionCount * 100);
        });
        return reviewedExam;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
