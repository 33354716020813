/* src/app/topic-detail/topic-detail.component.scss */
a {
  font-weight: 500;
  box-shadow: 1px 1px 0px 0px #b5b5b5;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #f9f9f9));
  border-radius: 0.5rem;
  border: 1px solid #dcdcdc;
  display: inline-block;
  margin: 0.5rem 0.25rem;
  font-size: 110%;
  padding: 0.5rem;
  text-shadow: 1px 1px 0px #ffffff;
  width: 12rem;
  color: #000;
  text-align: center;
}
a:hover {
  color: orange;
}
#topic-row {
  width: 45%;
}
#topic-row a {
  text-decoration: none;
}
@media screen and (max-width: 787px) {
  #topic-row {
    width: 100%;
  }
  img {
    width: 100%;
    margin-top: 1rem;
  }
  a {
    font-size: 100%;
  }
}
/*# sourceMappingURL=/topic-detail.component.css.map */
