import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'random'
})
export class RandomArrayElement implements PipeTransform {

  transform(value: any): any {
    if (!Array.isArray(value)) return null;

    return value[Math.floor(Math.random() * value.length)];
  }
}
