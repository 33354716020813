import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpInterceptorFn, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { Injector, Injectable, inject } from '@angular/core';
import { AppSettings } from './app-settings';
import { UserService } from '../_services/user.service';
import { TokenService } from '../_services/token.service';

export const ErrorInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            let errorMsg = "";
            if (error.error instanceof ErrorEvent) {
                console.log("this is client side error");
                errorMsg = `Client Error: ${error.error.message}`;
            } else {
                console.log("this is server side error");
                errorMsg = `Server Error Code: ${error.status}, Message: ${error.message}`;
            }

            console.log(errorMsg);
            return throwError(() => errorMsg);
        }),
    );
};

export const AuthInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    const tokenService = inject(TokenService);
    const appSettings = inject(AppSettings);

    let headerBase = {};

    if (req.method != "GET" && req.headers.get("Content-Type") == null) {
        headerBase['Content-Type'] = 'application/json';
    }
    let customRequest;
    if (req.url.startsWith(appSettings.nfBaseUrl)) {
        customRequest = req.clone({ setHeaders: headerBase, withCredentials: true });
    }
    else if (req.url.startsWith(appSettings.apiBaseUrl)) {
        if (!tokenService.HasToken) { return EMPTY; }

        headerBase['Authorization'] = `Bearer ${tokenService.Token}`;
        customRequest = req.clone({ setHeaders: headerBase, withCredentials: true });
    } else {
        customRequest = req.clone({ setHeaders: headerBase });
    }

    return next(customRequest);
};