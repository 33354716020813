/* src/app/login/login.component.scss */
#login-holder {
  margin-top: 5rem;
  width: 50%;
}
.grey-box {
  border: 1px solid lightgrey;
  box-shadow: 10px 10px 10px 10px lightgrey;
}
@media screen and (max-width: 850px) {
  #login-holder {
    width: 84%;
  }
}
/*# sourceMappingURL=/login.component.css.map */
