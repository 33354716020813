
<ng-container *ngIf="question; else loading">
    <div tabindex="0" aria-live="polite">
        <p class="text-center" id="question-number" tabindex="-1">Question {{exam.Answered + 1}}</p>
        <strong>{{question.Question}}</strong>
    </div>
    <ul>
        <li *ngFor="let answer of question.Answers; let i=index">
            <button (click)="AnswerQuestion(question.FirestoreID, answer)" [disabled]="answered" class="d-flex">
                <span class="sr-only">Answer Option</span>
                <fa-icon icon="question-circle" size="lg"></fa-icon>
                <div><strong class="ms-2 me-2">{{letters[i]}}:</strong>{{answer}}</div>
            </button>
        </li>
    </ul>
</ng-container>
<ng-template #loading>
    <app-loading description="load your question"></app-loading>
</ng-template>