/* src/app/header/header.component.scss */
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
#toprow {
  height: 2.5rem;
  line-height: 2.5rem;
  color: #fff;
  background-color: #8accbf;
}
#toprow > div > div > a {
  color: #fff;
}
#toprow.inside::before {
  content: " ";
  position: absolute;
  width: 232px;
  height: 180px;
  z-index: 10;
  background-image: url("/media/homelink-crumb.png");
  background-position-y: -16px;
}
#toprow > div#links > a {
  position: fixed;
  display: block;
  z-index: 100;
  color: transparent;
}
#abmplink {
  top: 0;
  left: 20px;
  height: 80px;
  width: 90px;
}
#homelink {
  top: 50px;
  left: 116px;
  height: 80px;
  width: 105px;
}
#toprow.home > a {
  display: none;
}
#toprow app-culture-picker {
  margin-right: 2rem;
  z-index: 500;
}
hr {
  margin: 0.25rem 0;
}
a#togglesearch {
  margin-right: 2rem;
  color: #FFF;
}
div.search {
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  width: 15rem;
  text-align: center;
  z-index: 1000;
  background-color: #8accbf;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
div.search input {
  height: 1rem;
  line-height: initial;
  padding: 0.75rem 0.5rem;
  width: 80%;
  border-radius: 5px;
}
div.search a#runsearch {
  margin-left: 0.5rem;
}
@media (max-width: 767px) {
  #toprow::before {
    display: none;
  }
  #toprow div#links a#abmplink {
    position: initial;
    display: block;
    height: auto;
    width: 2.25rem;
    margin-top: -0.2rem;
    margin-left: 0.2rem;
    z-index: unset;
  }
  #toprow div#links a#homelink {
    position: initial;
    height: auto;
    width: auto;
    color: #fff;
    font-size: 1.2rem;
    padding-left: 0.5rem;
    text-decoration: underline;
    z-index: unset;
  }
}
@media screen and (min-width: 300px) and (max-width: 768px) {
  :host div.dropdown-menu.show {
    left: -16rem !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 767px) {
  :host div.dropdown-menu.show {
    left: -16rem !important;
    height: 22rem;
    overflow: scroll;
  }
}
/*# sourceMappingURL=/header.component.css.map */
