import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataService } from '../_services/user-data.service';
import { Observable, Subscription } from 'rxjs';
import { ExamCoachTopic } from '../_classes/exam-coach-topic';
import { SlideInOutAnimation } from '../animations'
import { Quiz } from '../_classes/quiz';
import { FireStoreStaticService } from '../_services/fire-store-static.service';
import { FireStoreInterstitialsService } from '../_services/fire-store-interstitials.service';


@Component({
  selector: 'app-quiz-review',
  templateUrl: './quiz-review.component.html',
  styleUrls: ['./quiz-review.component.scss'],
  animations: [SlideInOutAnimation]
})
export class QuizReviewComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private topic$: Observable<ExamCoachTopic>;
  public Quiz$: Observable<Quiz>;
  public ExamCount$ = this.userData.GetExamCount();
  public activateInterstitial: boolean;

  constructor(private fssService: FireStoreStaticService, private route: ActivatedRoute, private userData: UserDataService, private router: Router, public interstitialService: FireStoreInterstitialsService) { }

  ngOnInit(): void {
    this.topic$ = this.fssService.GetTopic(this.route.snapshot.params.subject, this.route.snapshot.params.topic);
    this._subscriptions.add(this.userData.GetActiveQuiz(this.route.snapshot.params.subject, this.route.snapshot.params.topic).subscribe(quiz => {
      if(!quiz.NoQuizResultFound) {
        this.router.navigate(['subjects', this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'quiz', 'questions']);
      } else {
        this.interstitialService.ShouldShow$('quizcomplete').subscribe(shouldShow => {
          this.activateInterstitial = shouldShow;
        });
      }
    }));
    this.Quiz$ = this.userData.GetLatestQuiz(this.route.snapshot.params.subject, this.route.snapshot.params.topic);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
