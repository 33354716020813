import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Exam } from '../_classes/exam';
import { ExamTimeEvent } from '../_classes/exam-time-event';
import { UserDataService } from '../_services/user-data.service';

@Component({
  selector: 'app-exam-alert',
  templateUrl: './exam-alert.component.html',
  styleUrls: ['./exam-alert.component.scss']
})
export class ExamAlertComponent implements OnInit, OnDestroy {
  private _examInProgress: boolean = false;
  private _documentHidden: boolean = false;
  public ActiveExam$: Observable<Exam>;
  public ActiveNumber: number;
  public RemainingTime: string;
  private _subscriptions: Subscription[] = [];

  constructor(private userData: UserDataService, private router: Router) {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.userData.AddExamViewEvent(ExamTimeEvent.EventType.Hidden);
      } else {
        this.userData.AddExamViewEvent(ExamTimeEvent.EventType.Shown);
      }
    }
    );

    this._subscriptions.push(this.router.events.pipe(
      filter(() => this._examInProgress),
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        if (event.url == ("/exams/" + this.ActiveNumber)) {
          this.userData.AddExamViewEvent(ExamTimeEvent.EventType.NavigateBack);
        } else {
          this.userData.AddExamViewEvent(ExamTimeEvent.EventType.NavigateAway, event.url);
        }
      })
    ).subscribe());
  }

  ngOnInit(): void {
    this.ActiveExam$ = this.userData.Exams$.pipe(
      map(exams => {
        this.ActiveNumber = exams.length;
        let active = exams.find(exam => exam.State == Exam.ExamState.Active);
        this._examInProgress = active != undefined;
        if (active) {
          this._subscriptions.push(active.RemainingTime$.subscribe(remaining => {
            this.RemainingTime = remaining;
            if (active.SecondsRemaining < 0) {
              this.userData.ExpireExam();
            }
          }
          ));
        }
        for (let e = 0; e < this._subscriptions.length - 1; e++) {
          this._subscriptions[e].unsubscribe();
        }
        return active;
      })
    );
  }

  ngOnDestroy() {
    for (let e = 0; e < this._subscriptions.length; e++) {
      this._subscriptions[e].unsubscribe();
    }
  }

  private AddViewEvent(event: ExamTimeEvent.EventType, destinationUrl?: string) {
    this.userData.AddExamViewEvent(event, destinationUrl);
  }

  PauseExam() {
    this.userData.PauseExam();
  }

}
