import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FireStoreStaticService } from '../_services/fire-store-static.service';
import { FirebaseAuthService } from '../_services/firebase-auth.service';
import { SearchService } from '../_services/search.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('searchstring') searchInput: ElementRef;

  constructor(public router: Router, public userService: UserService, public Search: SearchService, private fssService: FireStoreStaticService) { 
    this.fssService.TimeDifference$.subscribe(timeDiff => {
      if(Math.abs(timeDiff) > 600) {
        this.router.navigateByUrl("timecheck");
      }
    });
  }

  ngOnInit() {
  }

  searchKey(event: any) {
    this.Search.SetSearchString(this.searchInput.nativeElement.value);
    switch (event.key) {
      case "Enter":
        this.RunSearch();
        break;
      case "Escape":
        this.Search.ToggleSearchVisibility(false);
        break;
    }
  }

  Logout() {
    this.userService.Logout().pipe(take(1)).subscribe();
    this.router.navigateByUrl("login");
  }

  RunSearch() {
    this.router.navigateByUrl("search");
  }

}
