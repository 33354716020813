import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FirebaseAuthService } from '../_services/firebase-auth.service';
import { AuthGuardService } from '../_services/auth-guard.service';
import { first, catchError, map, delay, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { UserDataService } from '../_services/user-data.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public loginError: string;
  public loginInProgress: boolean = false;
  public loginForm: FormGroup = new FormGroup({
    user_email: new FormControl('', [Validators.required, Validators.email]),
    user_password: new FormControl('', [Validators.required]),
  });

  constructor(private router: Router, private userService: UserService, private authGuard: AuthGuardService, private userDataService: UserDataService) { }

  ngOnInit() {
    this._subscriptions.add(this.userService.User$.subscribe(user => {
      console.log('User', user.ObjectName);
      let redirect = this.authGuard.RedirectURL;
      if(user.IsLoggedIn) {
        if (redirect) {
          this.router.navigateByUrl(redirect);
        } else {
          this.router.navigateByUrl('/');
        }
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  submitLogin() {
    this.loginError = null;
    this.loginInProgress = true;
    this._subscriptions.add(this.userService.Login(this.loginForm.value.user_email, this.loginForm.value.user_password).pipe(
      catchError((error: Error) => {
        this.loginError = error.message;
        return of(error);
      }),
      map(
        val => {
          this.loginInProgress = false;
          if (val instanceof Error) {
            return;
          }
          let redirect = this.authGuard.RedirectURL;
          if ((val as any).user) {
            this.userDataService.CheckUserRecordExists();
            if (redirect) {
              this.router.navigateByUrl(redirect);
            } else {
              this.router.navigateByUrl('/');
            }
          }
        })
    ).subscribe());
  }
}
