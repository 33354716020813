import { Injectable } from '@angular/core';
import { TerminologyTerm } from '../_classes/terminology-term';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { ExamCoachSubject } from '../_classes/exam-coach-subject';
import { ExamCoachTopic } from '../_classes/exam-coach-topic';
import { ExamQuestion } from '../_classes/exam-question';
import { FlashCard } from '../_classes/flash-card';

@Injectable({
  providedIn: 'root'
})
export class FirebaseFunctionService {

  constructor() { }

  public GetServerTime(): Observable<any> {
    throw new Error('Method not implemented.');
//    return this.fbFunctions.httpsCallable('GetServerTime')({});
  }

  public TrackUserAgent(): Observable<any> {
    throw new Error('Method not implemented.');
//    return this.fbFunctions.httpsCallable('TrackUserAgent')({ userAgent: navigator.userAgent });
  }

  public UnArchiveUser(): Observable<any> {
    throw new Error('Method not implemented.');
//    return this.fbFunctions.httpsCallable('UnarchiveUser')({});
  }

  public GetAllTerms(): Observable<TerminologyTerm[]> {
    throw new Error('Method not implemented.');
    /*
    return this.fbFunctions.httpsCallable('GetAllTerms')({}).pipe(
      map(fsTerms => {
        let terms = [];
        fsTerms.forEach(fsTerm => {
          terms.push(new TerminologyTerm(fsTerm));
        });
        return terms;
      })
    )
      */
  }

  public GetSubjects(): Observable<ExamCoachSubject[]> {
    throw new Error('Method not implemented.');
    /*
    return this.fbFunctions.httpsCallable('GetSubjects')({}).pipe(
      map(fsSubjects => {
        let subjectArray: ExamCoachSubject[] = [];
        fsSubjects = fsSubjects.sort((a, b) => {
          if (a.Order > b.Order) return 1;
          if (a.Order < b.Order) return -1;
          return 0;
        });
        fsSubjects.forEach(fsSubject => {
          let subject = new ExamCoachSubject(fsSubject);
          fsSubject.Topics.forEach(fsTopic => {
            subject.AddTopic(new ExamCoachTopic(fsTopic));
          });
          subjectArray.push(subject);
        })
        return subjectArray;
      })
    )
      */
  }


  public GetContent<T>(subject: string, topic: string, area: 'flashcards' | 'questions' | 'terms'): Observable<Array<T>> {
    throw new Error('Method not implemented.');
    /*
    return this.fbFunctions.httpsCallable('GetContent')({ subject: subject, topic: topic, area: area }).pipe(
      map(fsItems => {
        let items = [];
        fsItems.forEach(fsItem => {
          switch (area) {
            case 'flashcards':
              //items.push(new FlashCard(fsItem));
              break;
            case 'questions':
              items.push(new ExamQuestion(fsItem));
              break;
            case 'terms':
              items.push(new TerminologyTerm(fsItem));
              break;
          }
        });
        return items;
      })
    )
      */
  }
}