import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';
import Player from "video.js/dist/types/player";

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: {
    fill: boolean,
    fluid: boolean,
    sources: {
      src: string,
      type: string,
    }[],
  };
  @Input() analyticsid: string;
  player: Player;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() { });
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

}
