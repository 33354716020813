<h3>Exam Timeline</h3>
<ng-container *ngIf="exam.State == 'COMPLETE'; else activeexam">
    <p>
        Started at {{exam.StartTime | date:'medium'}}<br />
        Completed at {{exam.EndTime | date:'medium'}}
        <ng-container *ngIf="exam.TimedOut">
            <br /><em>This exam exceeded the 100 minute time limit</em>
        </ng-container>
    </p>
    <div *ngFor="let time of exam.Times; let i=index;" class="d-flex flex-wrap p-2 timeentry rounded">
        <div [ngSwitch]="time.EventType" class="mt-2 me-4 ms-2 icon">
            <fa-icon *ngSwitchCase="'START'" icon="play" size="2x" class="green"></fa-icon>
            <fa-icon *ngSwitchCase="'PAUSE'" icon="pause" size="2x"></fa-icon>
            <fa-icon *ngSwitchCase="'RESUME'" icon="forward" size="2x"></fa-icon>
            <fa-icon *ngSwitchCase="'TIMEOUT'" icon="clock" size="2x"></fa-icon>
            <fa-icon *ngSwitchCase="'END'" icon="stop" size="2x"></fa-icon>
            <fa-icon *ngSwitchCase="'HIDDEN'" icon="eye-slash" size="2x" class="maroon"></fa-icon>
            <fa-icon *ngSwitchCase="'SHOWN'" icon="eye" size="2x"></fa-icon>
            <fa-icon *ngSwitchCase="'NAVIGATEAWAY'" icon="arrow-circle-up" size="2x" class="maroon"></fa-icon>
            <fa-icon *ngSwitchCase="'NAVIGATEBACK'" icon="arrow-circle-down" size="2x"></fa-icon>
            <fa-icon *ngSwitchCase="'ANSWERED'" icon="check" size="2x"></fa-icon>
        </div>

        <div class="me-4 time">
            <strong>{{time.EventTime | date:'short'}} </strong>
            <span *ngIf="i>0" class="d-block ps-3">
                {{exam.Times[i].Ticks - exam.Times[i-1].Ticks | number:'1.1-2'}} seconds
            </span>
        </div>

        <div [ngSwitch]="time.EventType">
            <span *ngSwitchCase="'START'">Exam started</span>
            <span *ngSwitchCase="'PAUSE'">Exam paused</span>
            <span *ngSwitchCase="'RESUME'">Exam resumed</span>
            <span *ngSwitchCase="'TIMEOUT'">Exam timed out</span>
            <span *ngSwitchCase="'END'">Exam ended</span>
            <span *ngSwitchCase="'HIDDEN'">Exam window hidden</span>
            <span *ngSwitchCase="'SHOWN'">Exam window shown</span>
            <span *ngSwitchCase="'NAVIGATEAWAY'">Navigated to {{time.ExtraData}}</span>
            <span *ngSwitchCase="'NAVIGATEBACK'">Navigated back to exam</span>
            <span *ngSwitchCase="'ANSWERED'">Answered question {{time.ExtraData}}</span>
        </div>
    </div>
</ng-container>

<ng-template #activeexam>
    <p>Exam timeline is only available for completed exams.</p>
</ng-template>