import { ReplaySubject } from 'rxjs';
import { ExamQuestion } from './exam-question';
import { ExamTimeEvent } from './exam-time-event';
import { ExamCoachSubject } from './exam-coach-subject';
import { Utility } from './utility';

export class Exam {
    public get ObjectName(): string { return 'Exam'; }
    private static _exams: Map<string, Exam> = new Map();
    public FirestoreID: string;
    private _questions: ExamQuestion[] = [];
    private _times: ExamTimeEvent[] = [];
    private _state: Exam.ExamState;
    private _lastUpdate: Date;
    private static _tickers: Map<string, any> = new Map();
    public RemainingTime$: ReplaySubject<string>;
    private _readyToComplete: boolean = false;

    private constructor() {
        this.RemainingTime$ = new ReplaySubject<string>();
    }

    public static Start(questions: ExamQuestion[]): Exam {
        let objExam = new Exam();
        objExam.FirestoreID = Utility.GenerateFirestoreID();
        objExam.StartTimer();
        questions.forEach(question => {
            question.ShuffleAnswers();
        });
        objExam._questions = questions;
        objExam._state = Exam.ExamState.Active;
        objExam._times.push(ExamTimeEvent.New(ExamTimeEvent.EventType.Start));
        if (Exam._exams.has(objExam.FirestoreID)) {
            Exam._exams.delete(objExam.FirestoreID);
        }
        Exam._exams.set(objExam.FirestoreID, objExam);
        return objExam;
    }

    public static FromFirestore(fsSource: any, subjectMap?: Map<string, ExamCoachSubject>): Exam {
        let objExam = Exam._exams.has(fsSource.FirestoreID) ? Exam._exams.get(fsSource.FirestoreID) : new Exam();
        objExam.FirestoreID = fsSource.FirestoreID;
        objExam._lastUpdate = fsSource.LastUpdate ? fsSource.LastUpdate.toDate() : new Date();
        objExam._state = fsSource.State;
        fsSource.Questions.forEach(fsQuestion => {
            if (subjectMap) {
                fsQuestion.SubjectTopicName = subjectMap.get(fsQuestion.Subject).Name + ' > ' + subjectMap.get(fsQuestion.Subject).Topics.get(fsQuestion.Topic).Name;
            }
            let qIndex = objExam._questions.findIndex(q => q.FirestoreID == fsQuestion.FirestoreID);
            if(~qIndex) {
                objExam._questions[qIndex].SelectedAnswer = fsQuestion.SelectedAnswer;
            } else {
                objExam._questions.push(new ExamQuestion(fsQuestion));
            }
        });
        objExam._times = [];
        fsSource.Times.forEach(fsTime => {
            objExam._times.push(ExamTimeEvent.FromFirestore(fsTime));
        });
        if (objExam.State == Exam.ExamState.Active) {
            if (objExam.SecondsRemaining <= 0) {
                objExam.Expire();
            } else {
                objExam.StartTimer();
            }
        }
        if (Exam._exams.has(objExam.FirestoreID)) {
            Exam._exams.delete(objExam.FirestoreID);
        }
        Exam._exams.set(objExam.FirestoreID, objExam);
        return objExam;
    }

    private StartTimer() {
        this.StopTimer();
        if (!Exam._tickers.has(this.FirestoreID)) {
            Exam._tickers.set(this.FirestoreID, window.setInterval(() => {
                if (this.State == Exam.ExamState.Active) {
                    this.RemainingTime$.next(this.RemainingTime);
                }
            }, 1000));
        }
    }

    private StopTimer() {
        clearInterval(Exam._tickers.get(this.FirestoreID));
        Exam._tickers.delete(this.FirestoreID);
    }

    public Answer(questionId: string, answer: string): Exam {
        this.Touch();
        let qObj = this.Questions.find(q => q.FirestoreID == questionId);
        if (!qObj.SelectedAnswer) {
            qObj.SelectedAnswer = answer;
        }
        if (this.Questions.filter(q => q.SelectedAnswer != null).length == this.Questions.length) {
            this._times.push(ExamTimeEvent.New(ExamTimeEvent.EventType.End));
            this._readyToComplete = true;
        }
        this.AddViewEvent(ExamTimeEvent.EventType.Answered, this.Questions.filter(q => q.SelectedAnswer != null).length.toString());
        return this;
    }

    public AddViewEvent(eventType: ExamTimeEvent.EventType, destinationUrl?: string) {
        //Don't add a NavigateBack immediately after start
        if(this._times.length == 1 && eventType == ExamTimeEvent.EventType.NavigateBack) return;
        this._times.push(ExamTimeEvent.New(eventType, destinationUrl));
    }

    public get Score(): number {
        return Math.floor(this.Questions.filter(q => q.SelectedAnswer == q.Answer).length / this.Questions.length * 100);
    }

    public get LastUpdate(): any {
        throw new Error('Method not implemented.');
    }

    public get TimedOut(): boolean {
        return this._times.filter(te => te.EventType == ExamTimeEvent.EventType.Timeout).length > 0;
    }

    private Touch() {
        this._lastUpdate = new Date();
    }

    public get Times(): ExamTimeEvent[] {
        let sorted = this._times.sort((a, b) => {
            if (a.Ticks > b.Ticks) return 1;
            if (a.Ticks < b.Ticks) return -1;
            return 0;
        });
        if(sorted[sorted.length - 1].EventType != ExamTimeEvent.EventType.End) {
            let endIndex = sorted.findIndex(e => e.EventType == ExamTimeEvent.EventType.End);
            sorted.push(sorted.splice(endIndex, 1)[0]);
        }
        return sorted;
    }

    public Pause() {
        this.Touch();
        this._times.push(ExamTimeEvent.New(ExamTimeEvent.EventType.Pause));
        this._state = Exam.ExamState.Paused;
        this.StopTimer();
    }

    public Resume() {
        this.Touch();
        this._times.push(ExamTimeEvent.New(ExamTimeEvent.EventType.Resume));
        this._state = Exam.ExamState.Active;
        this.StartTimer();
    }

    public Expire() {
        this.Touch();
        this._times.push(ExamTimeEvent.New(ExamTimeEvent.EventType.Timeout));
        this._state = Exam.ExamState.Complete;
        this.StopTimer();
    }

    public get StartTime(): Date {
        return this._times.find(e => e.EventType == ExamTimeEvent.EventType.Start).EventTime;
    }

    public get EndTime(): Date {
        return this._times.find(e => e.EventType == ExamTimeEvent.EventType.End)?.EventTime;
    }

    public get Questions(): ExamQuestion[] {
        return this._questions;
    }

    public NextQuestion(): ExamQuestion {
        return this.Questions.find(q => q.SelectedAnswer == null);
    }

    public get Answered(): number {
        return this._questions.filter(q => q.SelectedAnswer != null).length;
    }

    public get Correct(): number {
        return this._questions.filter(q => q.SelectedAnswer == q.Answer).length;
    }

    public get SecondsRemaining(): number {
        let totalTime = 60 * 110;
        let lastActiveTime = 0;
        for (let t = 0; t < this._times.length; t++) {
            let time = this._times[t];
            switch (time.EventType) {
                case ExamTimeEvent.EventType.Start:
                case ExamTimeEvent.EventType.Resume:
                    lastActiveTime = time.Ticks;
                    break;
                case ExamTimeEvent.EventType.Pause:
                    totalTime -= (time.Ticks - lastActiveTime);
                    break;
            }
        }
        if (this._state == Exam.ExamState.Active) {
            totalTime -= (new Date().getTime() / 1000 - lastActiveTime);
        }
        totalTime = Math.floor(totalTime);
        return totalTime;
    }

    private get RemainingTime(): string {
        let timeRemaining = this.SecondsRemaining;

        let fTime = "";
        if (timeRemaining >= 3600) {
            fTime += Math.floor(timeRemaining / 3600) + ":";
            timeRemaining -= 3600;
            if (timeRemaining < 60) {
                fTime += '00:';
            }
        }
        if (timeRemaining >= 60) {
            fTime += Math.floor(timeRemaining / 60).toString().padStart(2, "0") + ":";
            timeRemaining = timeRemaining % 60;
        } else {
            fTime += "00:";
        }
        fTime += timeRemaining.toString().padStart(2, "0");

        return fTime;
    }

    public get State(): Exam.ExamState {
        return this._state;
    }

    public get ReadyToComplete(): boolean {
        return this._readyToComplete;
    }

    public Complete() {
        if (this._readyToComplete) {
            this._state = Exam.ExamState.Complete;
            this.StopTimer();
        }
    }

    public get FriendlyState(): string {
        let state = "";
        switch (this._state) {
            case Exam.ExamState.Active:
                state = "Active";
                break;
            case Exam.ExamState.Paused:
                state = "Paused";
                break;
            case Exam.ExamState.Complete:
                state = "Complete";
                break;
        }
        return state;
    }
}

export namespace Exam {
    export enum ExamState {
        Active = "ACTIVE",
        Paused = "PAUSED",
        Complete = "COMPLETE"
    }
}
