import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, distinctUntilChanged, tap, first, map, take } from 'rxjs/operators';
import { FireStoreStaticService } from '../_services/fire-store-static.service';
import { DataService } from '../_services/data.service';
import { ECSubject } from '../_classes/ec-subject';
import { CultureString } from '../_classes/culture-string';
import { CultureService } from '../_services/culture.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: any[] = [];
  private NameMappings: ReplaySubject<{ Subjects: Map<string, CultureString>, Topics: Map<string, CultureString> }>;

  constructor(public router: Router, private dataService: DataService, private cultureService: CultureService, private translocoService: TranslocoService) {
    this.NameMappings = new ReplaySubject<{ Subjects: Map<string, CultureString>, Topics: Map<string, CultureString> }>();
    this.dataService.Subjects$.subscribe((subjects: Map<number, ECSubject>) => {
      let names = { Subjects: new Map<string, CultureString>(), Topics: new Map<string, CultureString>() };
      [...subjects.values()].forEach(subject => {
        names.Subjects.set(subject.Alias, subject.Name);
        subject.Topics.forEach(topic => {
          names.Topics.set(topic.Alias, topic.Name);
        });
      });
      if (names.Subjects.size > 0) {
        this.NameMappings.next(names);
      }
    });
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map(evt => {
        this.BuildBreadcrumb();
      })
    ).subscribe();

    this.cultureService.PreferredCulture$.subscribe(culture => {
      this.BuildBreadcrumb();
    });
  }

  ngOnInit() {
    this.BuildBreadcrumb();
  }

  BuildBreadcrumb() {
    this.NameMappings.pipe(
      map(nameMappings => {
        let routerState = this.router.routerState.snapshot.root;
        let workingRoute = routerState.firstChild;
        let workingCrumbs = [];
        let heirarchy = [];

        do {
          if (workingRoute?.data.label) {
            let label = workingRoute.data.label;
            let isDynamic: boolean = workingRoute.data.label == 'dynamic';
            if (workingRoute.data.label == 'dynamic') {
              switch (workingRoute.routeConfig.path) {
                case ':subject':
                  label = nameMappings.Subjects.get(workingRoute.url[0].path).Value(this.cultureService.PreferredCulture);
                  break;
                case ':topic':
                  label = nameMappings.Topics.get(workingRoute.url[0].path).Value(this.cultureService.PreferredCulture);
                  break;
                case ':examnumber':
                  label = this.translocoService.translate('Breadcrumb.PracticeExam') + ' ' + workingRoute.url[0].path;
                  break;
                case 'quiz':
                  workingRoute = workingRoute.firstChild;
                  continue;
                  break;
              }
            }
            if (workingRoute?.url[0]) {
              heirarchy.push(workingRoute.url[0].path);
            }
            let translateLabel = 'Breadcrumb.' + label.replace(/\s/g, '');
            let bcLabel = isDynamic ? label : this.translocoService.translate(translateLabel);
            if (workingRoute?.firstChild && workingRoute?.firstChild.routeConfig.path !== "") {
              workingCrumbs.push({ url: heirarchy.join('/'), label: bcLabel });
            } else if (label != "dynamic") {
              workingCrumbs.push({ label: bcLabel });
            }
          }
          workingRoute = workingRoute?.firstChild;
        } while (workingRoute != null)

        if (workingCrumbs.length > 1 && workingCrumbs[0].label == workingCrumbs[1].label) {
          workingCrumbs.shift();
        }

        this.breadcrumbs = workingCrumbs;
      })
    ).subscribe();
  }
}



