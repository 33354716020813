import { Component, OnInit } from '@angular/core'
import { Observable, combineLatest, ReplaySubject } from 'rxjs'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { FlashCard } from '../_classes/flash-card'
import { UserDataService } from '../_services/user-data.service'
import { first, map, take } from 'rxjs/operators'
import { RandomArrayElement } from '../_pipes/random.pipe'
import { FireStoreStaticService } from '../_services/fire-store-static.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../_services/data.service'


@Component({
  selector: 'app-flashcards',
  templateUrl: './flashcards.component.html',
  styleUrls: ['./flashcards.component.scss']
})
export class FlashcardsComponent implements OnInit {

  flipped: boolean;
  private _hiddenCardIds: string[] = [];
  hiddenCardCount: number = 0;
  totalCardCount: number = 0;

  private Flashcards$: ReplaySubject<FlashCard[]> = new ReplaySubject<FlashCard[]>(1);
  public Flashcard$: ReplaySubject<FlashCard> = new ReplaySubject<FlashCard>(1);
  public largeImage: boolean = false;

  constructor(private dataService: DataService, private route: ActivatedRoute, private userDataService: UserDataService, private modalService: NgbModal) {
    combineLatest([
      this.dataService.GetFlashcards$(),
      userDataService.GetHiddenFlashCardIds(this.route.snapshot.params.subject, this.route.snapshot.params.topic)]
    ).pipe(map(([cards, hiddenCards]) => {
      return [cards.filter(c => c.Subject == this.route.snapshot.params.subject && c.Topic == this.route.snapshot.params.topic), hiddenCards];
    }))
    .subscribe(([cards, hiddenCards]) => {
      if (this.totalCardCount == 0 || this._hiddenCardIds.sort().join('') != hiddenCards.sort().join('')) { //Avoid re-filtering if the hidden card list doesn't change
        this._hiddenCardIds = hiddenCards;
        let _allFlashcards = [];
        this.hiddenCardCount = 0;
        this.totalCardCount = 0;
        cards.forEach(card => {
          this.totalCardCount++;
          if (hiddenCards.includes(card.FirestoreID)) {
            this.hiddenCardCount++;
          } else {
            _allFlashcards.push(card);
          }
        });
        if(_allFlashcards.length == 0) {
          _allFlashcards = cards;
        }
        this.Flashcards$.next(_allFlashcards);
      }
    });

    this.Flashcards$.subscribe(cards => {
      this.Flashcard$.next(new RandomArrayElement().transform(cards));
      this.flipped = false;
    });
  }

  UnhideCards() {
    this.userDataService.UnhideFlashCards(this.route.snapshot.params.subject, this.route.snapshot.params.topic);
  }

  hideCard(cardId) {
    this.userDataService.HideFlashCard(this.route.snapshot.params.subject, this.route.snapshot.params.topic, cardId);
  }

  nextCard() {
    this.flipped = false;
    this.userDataService.TrackUsage(this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'cards');
    this.Flashcards$.subscribe(cards => {
      this.Flashcard$.next(new RandomArrayElement().transform(cards));
      document.getElementById('card-front')?.focus();
    });
  }

  ngOnInit() {
    this.userDataService.TrackUsage(this.route.snapshot.params.subject, this.route.snapshot.params.topic, 'cards');
  }

  showFullImage() {
    this.largeImage = true;
    this.flipped = !this.flipped;
  }

  hideFullImage() {
    this.largeImage = false;
  }
}
