<ng-container *transloco="let t">
  <div class="row" id="footer" role="contentinfo">
  <div class="col-6" id="footer-text">
    © 2020 Associated Bodywork & Massage Professionals
    <br />
    <a href="https://www.abmp.com/examcoach/agreement">{{ t('Footer.Links.Agreement') }}</a> |
    <a href="https://www.abmp.com/privacy-policy">{{ t('Footer.Links.PrivacyPolicy') }}</a> |
    <a href="https://www.abmp.com/copyright-policy">{{ t('Footer.Links.CopyrightPolicy') }}</a> |
    <a href="https://www.abmp.com/terms-use">{{ t('Footer.Links.Terms') }}</a>
    <span *ngIf="buildtime" class="text-light ms-2">Build {{buildtime}}</span>
  </div>
  <ng-container *ngIf="(User$ | async) as user">
    <div *ngIf="router.url === '/'" class="col-6" id="upgrade-footer">
      {{ t('Footer.Upsell.5MM') }}
      <br />
      <a href="{{user.HasActive5MM ? 'https://www.abmp.com/5mm' : 'https://www.abmp.com/members/students/upgrade-exam-coach-plus'}}">
        {{ t('Footer.Upsell.LearnMore') }}</a>
    </div>
  </ng-container>
</div>

</ng-container>