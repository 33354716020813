import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FireStoreStaticService } from '../_services/fire-store-static.service';

@Component({
  selector: 'app-time-check',
  templateUrl: './time-check.component.html',
  styleUrls: ['./time-check.component.scss']
})
export class TimeCheckComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public clientTime: string;
  public serverTime: string;

  constructor(public fssService: FireStoreStaticService, private router: Router) { }

  ngOnInit(): void {
    this._subscriptions.add(this.fssService.TimeDifference$.subscribe(timeDiff => {
      if (Math.abs(timeDiff) < 600) {
        this.router.navigateByUrl('/');
      } else {
        this.clientTime = new Date().toLocaleString();
        let sTime = new Date();
        sTime.setSeconds(sTime.getSeconds() + timeDiff);
        this.serverTime = sTime.toLocaleString();
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
