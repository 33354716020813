<app-subject-topic-header contentarea="practice quiz"></app-subject-topic-header>

<ng-container *ngIf="(ActiveQuestion$ | async) as question; else loadingMessage">
  <div tabindex="0" aria-live="polite">
    <p class="text-center" id="question-number" tabindex="-1">Question {{questionNumber}} of 10</p>
    <strong>{{question.Question}}</strong>
  </div>
  <ul>
    <li *ngFor="let answer of question.Answers; let i = index">
      <button (click)="AnswerQuestion(question.FirestoreID, answer)" [disabled]="showNextButton" class="d-flex">
        <ng-container *ngIf="!this.clickedAnswers.includes(answer)">
          <span class="sr-only">Answer Option</span>
          <fa-icon icon="question-circle"></fa-icon>
        </ng-container>
        <ng-container *ngIf="this.clickedAnswers.includes(answer)">
          <ng-container *ngIf="this.question.IsCorrect(answer)">
            <span class="sr-only">Correct Answer</span>
            <fa-icon icon="check-circle" class="green"></fa-icon>
          </ng-container>
          <ng-container *ngIf="!this.question.IsCorrect(answer)">
            <span class="sr-only">Incorrect Answer</span>
            <fa-icon icon="times-circle" class="maroon"></fa-icon>
          </ng-container>
        </ng-container>
        <div><strong class="ms-2 me-2">{{letters[i]}}:</strong>{{answer}}</div>
      </button>
    </li>
  </ul>

  <button *ngIf="showNextButton" id="next-button" (click)="ShowNextQuestion()"
    class="{{showNextButton ? 'show' : 'hide'}}">
    {{questionNumber==10 ? 'Review Quiz' : 'Next Question'}}
  </button>
</ng-container>
<ng-template #loadingMessage>
  <app-loading description="load your quiz questions..."></app-loading>
</ng-template>
