/* src/app/terminology/terminology.component.scss */
section.container div p.text-center {
  margin-left: -5rem;
}
#terminology-row {
  margin-bottom: 10rem;
}
@media (min-width: 768px) {
  section {
    margin: 0 auto;
  }
  div.definition {
    position: absolute;
    width: 20rem;
    margin-left: -22rem;
    margin-top: -5rem;
  }
  div.left article h5 a fa-icon {
    margin-left: -1.25rem;
  }
  div.right div.definition {
    width: 20rem;
    margin-left: 18rem;
  }
}
article {
  background-color: #fff;
  z-index: 500;
}
article > h5 {
  margin-left: 1rem;
  text-indent: -1rem;
}
div.definition {
  border-radius: 0.5rem;
  border: solid 1px lightgrey;
  box-shadow: 10px 10px 10px -5px lightgrey;
}
div.definition h5 {
  background: #8accbf;
  color: white;
}
div.definition h5 {
  padding: 0.25rem 0.5rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
div.definition p,
div.definition button,
div.definition span {
  margin: 0.5rem;
}
div.definition button {
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  border: none;
}
.orange {
  color: orange;
}
article a {
  text-decoration: none;
}
.term-col {
  margin-left: 4rem;
}
@media screen and (max-width: 780px) {
  .term-col {
    margin: auto;
  }
  #terminology-row {
    margin-bottom: 2rem !important;
  }
}
/*# sourceMappingURL=/terminology.component.css.map */
